(function() {
	'use strict';

	angular.module('ui.directives.appLink', []).directive('ilAppLink', AppLinkDirective);

	AppLinkDirective.$inject = ['$window'];
	function AppLinkDirective($window) {
		// Usage:
		//
		// Creates:
		//
		var directive = {
			link: link,
			restrict: 'A'
		};
		return directive;

		function link(scope, element, attrs) {
			var url = attrs.href || attrs.ilAppLink,
				target = attrs.target;

			element.attr('href', url);
			element.on('click', onClick);

			function onClick() {
				if (target) {
					$window.open(url, target);
				} else {
					$window.location.href = url;
				}
			}
		}
	}
})();
