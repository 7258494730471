angular.module('ui.svg', []).directive('ilGraphLegendLine', function() {
	return {
		restrict: 'A',
		template:
			'<svg><g class="data"><path class="aabb_fill" d="M0,0L20,20" /><path class="dataSet{{dataSetNumber}}" d="M5,5h10" /></g></svg>' +
			'<svg class="print_svg"><g class="data"><path class="aabb_fill" d="M0,0L90,20" /><path class="dataSet{{dataSetNumber}}" d="M5,5h24" /></g></svg>',
		scope: {
			dataSetNumber: '=ilGraphLegendLine'
		}
	};
});
