//impersonate/assist mode for the provider user
(function() {
	angular
		.module('ui.directives.assistModeToggle', [
			'api.authentication',
			'api.urls',
			'api.application.user',
			'ui.notifications',
			'assistModeErrorModal'
		])
		.directive('assistModeToggle', directive);

	directive.$inject = [
		'$location',
		'$log',
		'$state',
		'$timeout',
		'AssistModeErrorModal',
		'AuthenticationService',
		'urls',
		'ApplicationUserService',
		'UserRole'
	];

	function directive(
		$location,
		$log,
		$state,
		$timeout,
		AssistModeErrorModal,
		AuthenticationService,
		urls,
		ApplicationUserService,
		UserRole
	) {
		return {
			restrict: 'EA',
			scope: {
				user: '=assistModeToggle'
			},
			link: function(scope, element, attrs) {
				const toggleAssistMode = function() {
					const userTypeAssitMode = attrs.assistModeToggle;
					let promise;
					if (userTypeAssitMode === UserRole.student.toLowerCase()) {
						promise = AuthenticationService.impersonateStudent(scope.user ?? undefined, $location.absUrl());
					} else {
						promise = AuthenticationService.impersonate(scope.user ?? undefined, $location.absUrl());
					}
					promise
						.then(() => {
							const jwt = ApplicationUserService.getJwt();
							const authSource = jwt?.iss?.match?.(/edgenuity/gi) ? 'IMLP' : 'ILAuth';
							const url = urls.productSelection + (authSource === 'ILAuth' ? '?useIlAuth' : '');
							window.location.replace(url);
						})
						.catch(error => {
							$log.error(error);
							return AssistModeErrorModal.open(error, scope.username).then(() => {
								// We only want to abort impersonation if the impersonation call succeeded.
								// Otherwise, we'll end up logging the current user out.
								if (AuthenticationService.impersonating()) {
									AuthenticationService.abortImpersonation();
								}
							});
						})
						.finally(() => $timeout(() => $state.reload()));
				};

				element.bind('click', toggleAssistMode);

				element.on('$destroy', function() {
					element.off('click', toggleAssistMode);
				});
			}
		};
	}
})();
