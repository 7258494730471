// triggers a callback when the elements contents are scrolled up to allow retrieving more
(function() {
	angular.module('ui.directives.scrollMore', []).directive('scrollMore', scrollMore);

	function scrollMore() {
		return {
			restrict: 'A',
			link: linkFn
		};

		function linkFn(scope, element, attrs) {
			scope.onScroll = function(event, params) {
				if (element[0].clientHeight && element[0].scrollTop + element[0].clientHeight >= element[0].scrollHeight - 50) {
					scope.$apply(attrs.scrollMore);
				}
			};

			element.on('scroll', scope.onScroll);
			element.on('$destroy', function() {
				element.off('scroll', scope.onScroll);
			});
		}
	}
})();
