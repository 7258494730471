angular
	.module('ui.form.utils', [])
	.factory('FormManager', [
		'$rootScope',
		function formManagerFactory($rootScope) {
			var formManagerService = {
				setPristine: function(formName) {
					$rootScope.$broadcast('setFormPristine', formName);
				}
			};

			return formManagerService;
		}
	])
	.directive('ilSetFormPristine', [
		function() {
			return {
				restrict: 'A',
				require: '^form',
				link: function(scope, elem, attrs, formController) {
					var onSetFormPristine = function(event, args) {
						if (args === formController.$name) {
							formController.$setPristine();

							// $submitted property is created by the
							// ilValidFormSubmit directive. If it exists, we'll
							// reset it.
							if (_.has(formController, '$submitted')) {
								formController.$submitted = false;
							}
						}
					};

					scope.$on('setFormPristine', onSetFormPristine);
				}
			};
		}
	]);
