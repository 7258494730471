//end an impersonation session on click
angular.module('ui.directives.endImpersonation', ['api.authentication']).directive('endImpersonation', [
	'AuthenticationService',
	function(AuthenticationService) {
		return {
			restrict: 'EA',
			link: function(scope, element) {
				var endImpersonationSession = function() {
					AuthenticationService.impersonate(undefined);
				};

				element.bind('click', function() {
					endImpersonationSession();
				});

				element.on('$destroy', function() {
					element.off('click', endImpersonationSession);
				});
			}
		};
	}
]);
