/**
 * indeterminate_checkbox.js
 *
 * Copy of angular-indeterminate-checkbox (https://github.com/goodeggs/angular-indeterminate-checkbox)
 * without the crazy IIFE that seems to fail in some machines.
 */

(function(angular) {
	angular.module('ui.checkbox.indeterminate', []).directive('indeterminate', function() {
		return {
			restrict: 'A',
			link: function(scope, element, attributes) {
				return scope.$watch(attributes.indeterminate, function(val) {
					return element.prop('indeterminate', !!val);
				});
			}
		};
	});
})(window.angular);
