angular
	.module('ui.directives.matchWindowHeight', [])

	.directive('ilMatchWindowHeight', [
		'$window',
		'$document',
		function($window, $document) {
			return function(scope, elem, attr) {
				var win = angular.element($window),
					subtractElements = [];

				if (attr.subtractElements) {
					subtractElements = attr.subtractElements.split(',');
				}

				// Set up our resize function
				var fixHeight = function() {
					// Set element height to auto so we can get an accurate measurement
					elem.height('auto');

					// Get current heights
					var windowHeight = win.height(),
						docHeight = $document.height(),
						elemHeight = elem.height();

					if (docHeight > windowHeight) {
						windowHeight = docHeight;
					}

					// Subtract excluded elements from overall height
					angular.forEach(subtractElements, function(selector) {
						windowHeight -= angular.element(selector).height();
					});

					if (elemHeight < windowHeight) {
						elem.height(windowHeight);
					}

					if (mutationObserver) {
						mutationObserver.takeRecords();
					}
				};

				var mutationObserver = $window.MutationObserver ? new MutationObserver(fixHeight) : null;

				// Add event handler for window resize and start mutation observer
				win.on('resize', fixHeight);
				if (mutationObserver) {
					mutationObserver.observe($document[0], {
						childList: true,
						attributes: true,
						characterData: true,
						subtree: true
					});
				}

				// Remove resize handler when destroyed
				elem.on('$destroy', function() {
					win.off('resize', fixHeight);
					if (mutationObserver) {
						mutationObserver.disconnect();
					}
				});

				// Fix initial height
				fixHeight();
			};
		}
	]);
