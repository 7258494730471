angular.module('ui.notifications').config([
	'LocalizeProvider',
	function(LocalizeProvider) {
		LocalizeProvider.translations({
			en: {
				notifications: {
					endImpersonation: 'Exit Assist Mode',
					headings: {
						applicationError: 'Application Error',
						connectionError: 'Connection Error',
						developmentOnlyError: 'Development Only Message',
						inactivity: 'Logged Out Due To Inactivity',
						serverError: 'Server Error',
						updateAvailable: 'Update Available',
						upgradeBrowser: 'Please use one of these browsers for a better experience',
						impersonation: 'Assist Mode Active'
					},
					content: {
						applicationError: 'An error has occurred.',
						connectionError:
							'Unable to connect to your Imagine Learning Server. Please check your internet connection and try again.',
						developmentOnlyError: 'Something unusual has happened.',
						inactivity: 'You have been automatically logged out of your account due to inactivity. Please log in again.',
						//'inactivity': 'We logged you out of your account because we thought you left us. Please log in. And never, ever, ever leave us again.',
						serverError:
							'An error occurred on your Imagine Learning Server that prevented the application from displaying data.',
						// 'updateAvailable': 'An update to My Imagine Learning is available. Please <a href onclick="window.localStorage.setItem(\'updated\', true); setTimeout(function(){ window.location.reload(true); }, 100); return window.localStorage.getItem(\'updated\');">refresh the page</a> .',
						updateAvailable:
							'An update to My Imagine Learning is available. Please <a href onclick="window.Noty.closeAll();">click here to refresh the page</a> .',
						upgradeBrowser:
							'You are using an unsupported web browser. Some features may not work correctly. For the best experience, please upgrade to the latest version of these supported browsers. <div class="browsers"><ul><li class="chrome"><a href="http://www.google.com/chrome" target="_blank">Chrome</a></li><li class="firefox"><a href="http://www.mozilla.org/firefox" target="_blank">Firefox</a></li><li class="safari"><a href="http://www.apple.com/safari" target="_blank">Safari</a></li><li class="internet-explorer"><a href="http://www.microsoft.com/ie" target="_blank">Internet Explorer</a></li></ul></div>'
					},
					support: {
						default:
							'If you experience this problem again, please contact Imagine Learning Technical Support at 1-866-IL-SUPPORT.'
					},
					error: {
						default: 'Something unusual has happened.'
					},
					unsupportedBrowser: {
						summary: 'You are using an unsupported web browser. Some features may not work correctly.',
						details: {
							header: 'Please use one of these browsers for a better experience',
							content:
								'Your web browser does not support the features available in these tools. For the best experience, please upgrade to the latest version of these supported browsers.'
						}
					}
				}
			}
		});
	}
]);
