//shows a GO image on an overlay
angular.module('ui.linkable', []).directive('ilLinkable', [
	'$state',
	function() {
		var directive = {
			restrict: 'A',
			link: function(scope, elem, attrs) {
				scope.className = '';

				elem.css('position', 'relative');
				elem.addClass('linkable_directive');
			}
		};
		return directive;
	}
]);
