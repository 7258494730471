//nifty way to conditionally disable ui-srefs without needing to duplicate markup using ng-if or ng-switch
angular.module('ui.directives.disableClick', []).directive('ilDisableClick', [
	'$parse',
	'$rootScope',
	function($parse, $rootScope) {
		return {
			priority: 100,
			restrict: 'A',
			compile: function($element, attr) {
				var fn = $parse(attr.ilDisableClick);
				return {
					pre: function link(scope, element) {
						element.on('click', function(event) {
							var callback = function() {
								if (fn(scope, { $event: event })) {
									// prevents ng-click to be executed
									event.stopImmediatePropagation();
									// prevents href
									event.preventDefault();
									return false;
								}
							};

							if ($rootScope.$$phase) {
								scope.$evalAsync(callback);
							} else {
								scope.$apply(callback);
							}
						});
					},
					post: function() {}
				};
			}
		};
	}
]);
