(function() {
	angular.module('ui.manager.multiple.select').service('SelectTypes', service);

	service.$inject = ['$q', 'Organization', 'Class', 'User', 'Student'];

	function service($q, Organization, Class, User, Student) {
		var svc = this,
			types;
		types = {
			organizations: {
				translations: {
					search: 'Find an Organization',
					select: 'Select an Organization',
					selectPlural: 'Select Organizations'
				},
				queryByIds: function(ids) {
					return Organization.orgsById(ids);
				},
				query: function(params) {
					return Organization.paged(addParentInfoParam(params));
				},
				get: function(id) {
					return Organization.get(id, addParentInfoParam());
				},
				icon: '<span data-icon="org" class="icon"></span>',
				sortField: 'name',
				displayText: function(item) {
					return item.name;
				}
			},
			groups: {
				translations: {
					search: 'Find a Group',
					select: 'Select a Group',
					selectPlural: 'Select Groups'
				},
				queryByIds: function(ids) {
					return Class.classesById(ids);
				},
				query: function(params) {
					return Class.paged(addParentInfoParam(params));
				},
				get: function(id) {
					return Class.get(id, addParentInfoParam());
				},
				icon: '<span data-icon="class" class="icon"></span>',
				sortField: 'name',
				displayText: function(item) {
					return item.name;
				}
			},
			users: {
				translations: {
					search: 'Find Staff',
					select: 'Select Staff',
					selectPlural: 'Select Staff'
				},
				queryByIds: function(ids) {
					return User.usersById(ids);
				},
				query: function(params) {
					return User.paged(addParentInfoParam(params));
				},
				get: function(id) {
					return User.get(id, addParentInfoParam());
				},
				icon: '<span data-icon="user" class="icon"></span>',
				sortField: 'displayText',
				displayText: function(item) {
					return item.lastName + ', ' + item.firstName;
				}
			},
			students: {
				translations: {
					// enitityType: 'Students',
					// loading: 'Loading Students',
					search: 'Find a Student',
					select: 'Select a Student',
					selectPlural: 'Select Students'
				},
				queryByIds: function(ids) {
					return Student.studentsById(ids);
				},
				query: function(params) {
					return Student.paged(addParentInfoParam(params));
				},
				get: function(id) {
					return Student.get(id, addParentInfoParam());
				},
				icon: '<span data-icon="student" class="icon"></span>',
				sortField: 'displayText',
				displayText: function(item) {
					return item.lastName + ', ' + item.firstName;
				}
			},
			orgsFromOrgs: {
				aggregate: true,
				translations: {
					search: 'Find an Organization',
					select: 'Select an Organization',
					selectPlural: 'Select Organizations'
				},
				queryByIds: function(ids) {
					return Organization.orgsById(ids);
				},
				query: function(params) {
					var promises = [];
					if (params.sourceIds && params.sourceIds.length && params.sourceIds[0] && params.sourceIds[0].length) {
						angular.forEach(params.sourceIds, function(id) {
							promises.push(Organization.children(id, addParentInfoParam(params)));
						});
					}
					return promises.length > 0 ? $q.all(promises) : $q.when();
				},
				get: function(id) {
					return Organization.get(id, addParentInfoParam());
				},
				icon: '<span data-icon="org" class="icon"></span>',
				sortField: 'name',
				displayText: function(item) {
					return item.name;
				}
			},
			groupsFromOrgs: {
				aggregate: true,
				translations: {
					search: 'Find a Group',
					select: 'Select a Group',
					selectPlural: 'Select Groups'
				},
				queryByIds: function(ids, params) {
					const queryParams = {
						...(params || {}),
						additionalFields: ['parentName']
					};
					return Class.classesById(ids, queryParams);
				},
				query: function(params) {
					var promises = [];
					if (params.sourceIds && params.sourceIds.length && params.sourceIds[0] && params.sourceIds[0].length) {
						angular.forEach(params.sourceIds, function(id) {
							promises.push(Organization.classes(id, addParentInfoParam(params)));
						});
					}
					return promises.length > 0 ? $q.all(promises) : $q.when();
				},
				get: function(id) {
					return Class.get(id, addParentInfoParam());
				},
				icon: '<span data-icon="class" class="icon"></span>',
				sortField: 'name',
				displayText: function(item) {
					return `${item.name} (${item.parentName})`;
				}
			},
			usersFromOrgs: {
				aggregate: true,
				translations: {
					search: 'Find User',
					select: 'Select User',
					selectPlural: 'Select Users'
				},
				queryByIds: function(ids) {
					return User.usersById(ids);
				},
				query: function(params) {
					var promises = [];
					if (params.sourceIds && params.sourceIds.length && params.sourceIds[0] && params.sourceIds[0].length) {
						angular.forEach(params.sourceIds, function(id) {
							promises.push(Organization.users(id, addParentInfoParam(params)));
						});
					}
					return promises.length > 0 ? $q.all(promises) : $q.when();
				},
				get: function(id) {
					return User.get(id, addParentInfoParam());
				},
				icon: '<span data-icon="user" class="icon"></span>',
				sortField: 'displayText',
				displayText: function(item) {
					return item.lastName + ', ' + item.firstName;
				}
			},
			groupsFromUser: {
				aggregate: true,
				translations: {
					search: 'Find a Group',
					select: 'Select a Group',
					selectPlural: 'Select Groups'
				},
				queryByIds: function(ids) {
					return Class.classesById(ids);
				},
				query: function(params) {
					var promises = [];
					if (params.sourceIds && params.sourceIds.length && params.sourceIds[0] && params.sourceIds[0].length) {
						promises.push(User.classes(params.sourceIds[0], addParentInfoParam(params)));
					}
					return promises.length > 0 ? $q.all(promises) : $q.when();
				},
				get: function(id) {
					return Class.get(id, addParentInfoParam());
				},
				icon: '<span data-icon="class" class="icon"></span>',
				sortField: 'name',
				displayText: function(item) {
					return item.name;
				}
			},
			studentsFromUser: {
				translations: {
					search: 'Find a Student',
					select: 'Select a Student',
					selectPlural: 'Select Students'
				},
				queryByIds: function(ids) {
					return Student.studentsById(ids);
				},
				query: function(params) {
					if (params.sourceIds && params.sourceIds.length && params.sourceIds[0] && params.sourceIds[0].length) {
						return User.assignedStudents(params.sourceIds[0], addParentInfoParam(params));
					}
					return $q.when();
				},
				get: function(id) {
					return Student.get(id, addParentInfoParam());
				},
				icon: '<span data-icon="class" class="icon"></span>',
				sortField: 'displayText',
				displayText: function(item) {
					return item.lastName + ', ' + item.firstName;
				}
			}
		};

		svc.get = function(type) {
			return types[type] ? types[type] : undefined;
		};

		function addParentInfoParam(params) {
			params = params || {};
			params.additionalFields = params.additionalFields || [];
			params.additionalFields.push('parentName');
			params.additionalFields.push('parentId');
			return params;
		}
	}
})();
