(function() {
	angular.module('ui.blank.state').component('blankState', {
		bindings: {
			iconClass: '@?',
			titleText: '@?',
			description: '@?'
		},
		templateUrl: 'ui/blank_state/template.html'
	});
})();
