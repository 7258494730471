//matches sidebar height to that of its parent
angular.module('ui.directives.matchParentHeight', []).directive('matchParentHeight', [
	'$window',
	'$timeout',
	function($window, $timeout) {
		return {
			restrict: 'A',
			scope: {
				offset: '='
			},
			link: function($scope, element) {
				function resize() {
					var offset = $scope.offset ? $scope.offset : 45;
					var rightContentHeight = element.parent()[0].children[0].offsetHeight;
					var newHeight = Math.max(rightContentHeight - offset, element[0].offsetHeight);
					element.css('height', newHeight + 'px');
				}

				angular.element($window).bind('resize', resize);
				angular.element($window).bind('scroll', resize);

				element.on('$destroy', function() {
					angular.element($window).off('resize', resize);
					angular.element($window).off('scroll', resize);
				});

				$timeout(function() {
					resize();
				}, 100);
			}
		};
	}
]);
