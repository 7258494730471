angular.module('ui.navbar', ['angular.filter']).directive('ilNavbar', [
	'$timeout',
	function($timeout) {
		return {
			restrict: 'A',
			templateUrl: 'ui/navbar/navbar.tpl.html',
			replace: true,
			link: function(scope, element, attrs) {
				scope.ancestorsVisible = false;
				scope.detailsVisible = false;

				//scope.refreshItemsComplete = function () {
				//	var items = element.find('li:last-child');
				//	items.bind('click', function () {
				//		scope.$apply(function () {
				//			scope.ancestorsVisible = !scope.ancestorsVisible;
				//		});
				//	});
				//};

				//$timeout(function () {
				//	scope.refreshItemsComplete();
				//});
			}
		};
	}
]);
