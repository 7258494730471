//simple dialog to prompt user for a decision

(function() {
	angular.module('ui.modal.common', ['ui.modal']).factory('CommonModal', factory);

	factory.$inject = ['$compile', '$rootScope'];

	function factory($compile, $rootScope) {
		var element,
			scope = $rootScope.$new();

		return {
			//params {
			//  heading : string
			//  content : string
			//  buttons: array of button objects { name: string, text: string, callBack: function }
			//}
			show: function(params) {
				scope.close = function() {
					$rootScope.$broadcast('ilModalRemove');
				};

				var callbacks = [];
				scope.doCallback = function(buttonName) {
					if (callbacks[buttonName] && angular.isFunction(callbacks[buttonName])) {
						callbacks[buttonName]();
						//scope.$eval(callbacks[buttonName]);
					}
				};

				var buttons = [];
				angular.forEach(params.buttons, function(button) {
					callbacks[button.name] = button.callBack;
					buttons.push(
						'<input id="button-' +
							button.name +
							'" type="button" class="button ' +
							button.name +
							'-btn right" value="' +
							button.text +
							'" ng-click="doCallback(\'' +
							button.name +
							'\')" />'
					);
				});

				var templateString = [
					'<div il-modal-dyn' +
						(params.ignoreOverlayClick && params.ignoreOverlayClick === true ? ' class="no_hide_on_outside_click"' : '') +
						'><form>',
					'<header class="modal_header">',
					'<h3 id="deleteModal_headerText">' + params.heading + '</h3>',
					params.hideCloseButton && params.hideCloseButton === true
						? ''
						: '<a id="deleteModal_closeButton" class="modal_close" ng-click="close()"></a>',
					'</header>',
					'<div class="modal_body">',
					'<span class="dialog_content">' + params.content + '</span>',
					'</div>',
					'<footer class="modal_footer">',
					'__BUTTONS__',
					'</footer>',
					'</form></div>'
				].join('\n');

				templateString = templateString.replace('__BUTTONS__', buttons && buttons.length ? buttons.join('\n') : '');

				var template = angular.element(templateString);
				element = $compile(template)(scope);
			},

			close: function() {
				$rootScope.$broadcast('ilModalRemove');
			}
		};
	}
})();
