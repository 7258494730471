(function() {
	angular
		.module('ui.manager.dropdown', [
			'ui.manager.dropdown.constant',
			'ui.manager.dropdown.directive',
			'ui.manager.dropdown.controller',
			'utils.localization',
			'api.organization',
			'api.class'
		])
		.config(config);

	config.$inject = ['LocalizeProvider'];

	function config(LocalizeProvider) {
		LocalizeProvider.translations({
			en: {
				dropdown: {
					organization: {
						label: {
							multiselect: 'Parent Organization',
							singleselect: 'Parent Organization'
						},
						helpertext: ''
					},
					staff: {
						label: {
							multiselect: 'Staff',
							singleselect: 'Staff'
						},
						helpertext: 'Please select an organization'
					},
					group: {
						label: {
							multiselect: 'Groups',
							singleselect: 'Group'
						},
						helpertext: 'Please select an organization'
					},
					copy: {
						load: {
							loadmore: 'load more',
							loading: 'loading ...'
						}
					}
				}
			}
		});
	}
})();
