(function() {
	angular.module('ui.manager.dropdown.directive', []).directive('ilManagerDropdown', directive);

	directive.$inject = ['$timeout', 'Organization'];

	function directive($timeout, Organization) {
		return {
			restrict: 'A',
			scope: {
				type: '@',
				subType: '@',
				label: '@',
				mode: '@',
				includeParent: '@',
				default: '=',
				parent: '='
			},
			templateUrl: 'ui/manager.dropdown/manager.dropdown.tpl.html',
			controller: 'ilManagerDropdownController',
			controllerAs: 'ctrl',
			require: 'ngModel',
			link: function(scope, element, attrs, ngModel) {
				var ctrl = scope.ctrl,
					dataType = {
						undefined: undefined,
						unknown: 'unknown',
						organization: 'organization',
						staff: 'staff',
						group: 'group'
					},
					action = {
						add: 'add',
						remove: 'remove'
					};

				ngModel.$validators.required = function(modelValue, viewValue) {
					if (angular.isDefined(ngModel.$isEmpty) && ngModel.$isEmpty(modelValue)) {
						return true;
					}
					ngModel.$setDirty();
					return false;
				};

				ngModel.$render = function() {
					ctrl.chosenItems = ngModel.$viewValue;
				};

				scope.disabled = false;
				attrs.$observe(
					'disabled',
					function(value) {
						scope.disabled = value;
						ctrl.disabled = value;
					},
					true
				);

				scope.required = false;
				attrs.$observe(
					'required',
					function(value) {
						scope.required = value;
						ctrl.required = value;
					},
					true
				);

				scope.$watch(
					function() {
						return scope.default;
					},
					onDefaultsChange,
					true
				);

				scope.$watch(
					function() {
						return scope.parent;
					},
					onParentChange,
					true
				);

				scope.$watch(
					function() {
						return ctrl.chosenItems;
					},
					onValueChanged,
					true
				);

				setupParent(scope.parent);

				initialize();

				function onValueChanged(n, o) {
					if (n !== o) {
						renderModel();
					}
				}

				function renderModel() {
					$timeout(function() {
						ngModel.$setViewValue(ctrl.chosenItems);
						if (ctrl.chosenItems.length === 0) {
							ngModel.$setDirty();
						}
						ngModel.$setValidity('required', true);
					});
				}

				function onDefaultsChange(n, o) {
					if (n !== o) {
						setupDefaults();
					}
				}

				function setupDefaults() {
					if (angular.isDefined(scope.default) && angular.isDefined(scope.default.ids)) {
						var change = false;
						angular.forEach(scope.default.ids, function(id) {
							if (scope.type === dataType.organization) {
								ctrl.data.organizations.get(id).then(function(response) {
									ctrl.addItem(response);
									change = true;
								});
							} else if (scope.type === dataType.group) {
								ctrl.data.group.get(id).then(function(response) {
									ctrl.addItem(response);
									change = true;
								});
							} else if (scope.type === dataType.staff) {
								ctrl.data.staff.get(id).then(function(response) {
									response.name = response.firstName + ' ' + response.lastName;
									ctrl.addItem(response);
									change = true;
								});
							}
						});
						// ctrl.showChosenItems = change;
					}
					ctrl.showChosenItems = true;
					renderModel();
				}

				function setupParent(newParent) {
					ctrl.parent = !newParent ? undefined : newParent;
					if (ctrl.parent && ctrl.parent.ids && ctrl.parent.list) {
						if (ctrl.parent.ids.length > 0 && ctrl.parent.list.length === 0) {
							Organization.orgsById(ctrl.parent.ids).then(function(results) {
								ctrl.parent.list = results.items || [];
							});
						}
						if (ctrl.parent.ids.length === 0 && ctrl.parent.list.length > 0) {
							ctrl.parent.ids = _.map(ctrl.parent.list, 'id');
						}
					}
				}

				function initialize() {
					ngModel.$isEmpty = function(value) {
						return !(value !== undefined && value.length);
					};

					ngModel.$render = function() {
						ctrl.chosenItems = angular.isDefined(ngModel.$viewValue) ? ngModel.$viewValue : [];
					};

					ctrl.includeParent = angular.isDefined(scope.includeParent) && scope.includeParent === 'true' ? true : false;
					ctrl.label = scope.label;

					ctrl.mode = scope.mode;
					ctrl.element = element;
					ctrl.dataType =
						scope.type === dataType.undefined
							? dataType.unknown
							: scope.type === dataType.organization
							? dataType.organization
							: scope.type === dataType.staff
							? dataType.staff
							: scope.type === dataType.group
							? dataType.group
							: dataType.unknown;

					ctrl.subDataType = scope.subType;

					$timeout(function() {
						ngModel.$setPristine();
						element.find('.label .title .icon_plus').bind('click', plusClickHandler);
						setupDefaults();
					});
				}

				function plusClickHandler(event) {
					ctrl.dropCss = {
						position: 'fixed',
						top: parseInt(event.clientY) + 16 + 'px'
					};
				}

				function onParentChange(n, o) {
					if (o !== n) {
						var triggeredAction = n.list.length > o.list.length ? action.add : action.remove;
						if (scope.type === dataType.group) {
							if (triggeredAction === action.remove) {
								if (n.list.length > 0) {
									var removeQueue = [],
										addQueue = [];

									angular.forEach(n.list, function(parent) {
										angular.forEach(ctrl.chosenItems, function(chosenItem) {
											if (parent.id !== chosenItem.ildFoundById) {
												removeQueue.push(chosenItem);
											} else {
												addQueue.push(chosenItem);
											}
										});
									});

									angular.forEach(removeQueue, function(chosenItem) {
										ctrl.removeItem(chosenItem);
									});

									angular.forEach(addQueue, function(chosenItem) {
										ctrl.addItem(chosenItem);
									});
								} else {
									ctrl.chosenItems = [];
									ctrl.showChosenItems = false;
								}
								ctrl.parent = n;
								ngModel.$setViewValue(ctrl.chosenItems);
							}
						} else if (scope.type === dataType.staff) {
							if (n.ids.length !== o.ids.length) {
								setupParent(n);
							}
						}
						// trigger pre-populate
						if (triggeredAction === action.add && n.list.length > 0) {
							ctrl.triggerSearch();
						}
					}
				}
			}
		};
	}
})();
