(function() {
	angular.module('ui.table_lite.directive', []).directive('tableLite', tableLiteDirective);

	tableLiteDirective.$inject = ['$timeout', '$filter', 'tableLiteDataType'];
	function tableLiteDirective($timeout, $filter, tableLiteDataType) {
		return {
			restrict: 'A',
			replace: true,
			scope: {
				config: '=',
				showDetailsCallback: '&'
			},
			templateUrl: 'ui/table_lite/table_lite.tpl.html',
			controller: 'tableLiteDirectiveController',
			controllerAs: 'ctrl',
			link: function(scope, element, attrs) {
				var ctrl = scope.ctrl;

				ctrl.directiveInit(scope);

				scope.$watchCollection(
					function() {
						return scope.config.data;
					},
					onDataChange,
					true
				);

				function onDataChange() {
					ctrl.directiveInit(scope);
				}
			}
		};
	}
})();
