(function() {
	angular.module('ui.manager.select.directive', []).directive('ilManagerSelect', directive);

	directive.$inject = ['$timeout'];

	function directive($timeout) {
		return {
			restrict: 'EA',
			templateUrl: 'ui/manager.select/manager.select.tpl.html',
			replace: true,
			scope: {
				options: '=',
				meta: '='
			},
			controller: 'ilManagerSelectController',
			controllerAs: 'ctrl',
			require: 'ngModel',
			link: function(scope, element, attrs, ngModel) {
				var ctrl = scope.ctrl;
				ctrl.options = scope.meta.options;
				ctrl.label = scope.meta.label;
				element.addClass('il_manager_select');
				ngModel.$render = function() {
					ctrl.selected = ngModel.$viewValue;
				};

				attrs.$observe(
					'disabled',
					function(value) {
						ctrl.disabled = value;
					},
					true
				);

				scope.$watch(
					function() {
						return ctrl.selected;
					},
					onValueChanged,
					true
				);

				scope.$watch(
					function() {
						return scope.meta;
					},
					onMetaChanged,
					true
				);

				function onValueChanged(n, o) {
					if (n !== o) {
						renderModel();
					}
				}

				function onMetaChanged(n, o) {
					if (n !== o) {
						ctrl.options = scope.meta.options;
						ctrl.label = scope.meta.label;
					}
				}

				function renderModel() {
					$timeout(function() {
						ngModel.$setViewValue(ctrl.selected);
						if (ctrl.selected) {
							ngModel.$setDirty();
						}
					});
				}
			}
		};
	}
})();
