angular.module('ui.audioclip', []).directive('ilAudioClip', [
	function() {
		return {
			restrict: 'A',
			link: function(scope, element, attrs) {
				//add bad audio initially
				element.closest('.recording_group').addClass('bad_audio');

				//remove only if the clip meta loads and canplay is triggered
				element.bind('loadedmetadata', function() {
					element.closest('.recording_group').removeClass('bad_audio');
				});

				function isMobileSafari() {
					return navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/);
				}

				//this reloads the clip when play has ended for chrome
				//html5 audio has some hiccups
				//* An element must be reloaded in Chrome or it will only play once
				//* An element must not be reloaded in Firefox or there will be a delay
				element.bind('ended', function() {
					if (window.chrome && element[0]) {
						element[0].load();
					}
				});
			}
		};
	}
]);
