// allows for customization using a template
// basic structure of custom template using modal.less defined styles below.
// adding the the class 'modal_close_button' will auto-magically close the modal when the element is clicked

/*
 <!-- sample template -->
 <form ng-controller="controller as model">
    <header class="modal_header">
        <h3 id="automation_appreciated"> Modal Header Text </h3>
        <a id="automation_appreciated" class="modal_close_button" ng-click=""></a>
    </header>

	 <div class="modal_body">
	    <span class="dialog_content">This is a yes/no modal. Click yes if you agree.</span>
	 </div>

	 <footer class="modal_footer">
		 <input type="submit" id="deleteModal_deleteButton" class="button no-btn right" value="No" ng-disabled="" ng-click="model.clickedNo()" />
		 <input type="button" id="deleteModal_cancelButton" class="button yes-btn right" value="Yes" ng-disabled="" ng-click="model.clickedYes()" />
	 </footer>
 </form>

*/

(function() {
	angular.module('ui.modal.custom', ['ui.modal']).factory('CustomModal', factory);

	factory.$inject = ['$templateRequest', '$compile', '$log', '$rootScope'];

	function factory($templateRequest, $compile, $log, $rootScope) {
		var scope;
		return {
			show: function(templateUrl, params) {
				//params allows passing data to the modal scope
				if (!templateUrl || !templateUrl.length) {
					return;
				}

				scope = $rootScope.$new();
				scope.params = params = params || {};

				scope.close = function() {
					$rootScope.$broadcast('ilModalRemove');
				};

				$templateRequest(templateUrl)
					.then(function(html) {
						var modalString =
							'<div il-modal-dyn ' +
							(params.overlayClose === undefined ? '' : 'overlay-close="' + params.overlayClose + '"') +
							'>' +
							html +
							'</div>';
						var template = angular.element(modalString);
						$compile(template)(scope);
					})
					.catch(function(err) {
						$log.error(err);
					});
			},

			close: function() {
				$rootScope.$broadcast('ilModalRemove');
			}
		};
	}
})();
