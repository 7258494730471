angular.module('ui.directives.backButton', []).directive('backButton', [
	'$window',
	function($window) {
		return {
			restrict: 'A',
			link: function(scope, element, attrs) {
				function goBack() {
					$window.history.back();
				}

				element.bind('click', goBack);

				element.on('$destroy', function() {
					element.off('click', goBack);
				});
			}
		};
	}
]);
