angular.module('ui.directives.match', []).directive('match', [
	function() {
		return {
			require: 'ngModel',
			restrict: 'A',
			scope: {
				match: '='
			},
			link: function(scope, elem, attrs, ctrl) {
				var setValidity = function(value1, value2) {
					ctrl.$setValidity('match', (!value1 && !value2) || value1 === value2);
				};

				scope.$watch(
					function() {
						return scope.match;
					},
					function(newValue, oldValue) {
						if (newValue !== oldValue) {
							setValidity(newValue, ctrl.$modelValue || ctrl.$$invalidModelValue);
						}
					}
				);

				scope.$watch(
					function() {
						return ctrl.$modelValue || ctrl.$$invalidModelValue;
					},
					function(newValue, oldValue) {
						if (newValue !== oldValue) {
							setValidity(newValue, scope.match);
						}
					}
				);
			}
		};
	}
]);
