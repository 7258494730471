(function() {
	angular
		.module('ui.table_lite_filter.controller', [])
		.controller('tableLiteFilterDirectiveController', tableLiteFilterDirectiveController);

	tableLiteFilterDirectiveController.$inject = [];
	function tableLiteFilterDirectiveController() {
		var ctrl = this;

		ctrl.dateChange = dateChange;
		ctrl.init = init;

		function init() {
			ctrl.date = null;
		}

		init();

		function dateChange() {
			if (!ctrl.table.filter.hasOwnProperty('all')) {
				ctrl.table.filter.all = {};
			}
			ctrl.table.filter.all['dateStr'] = ctrl.date;
		}
	}
})();
