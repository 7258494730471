/*

use to hide content when there is no authenticated user - toggles class ng-hide

*/

(function() {
	angular.module('ui.directives.authenticated.content', ['api.authentication']).directive('ilAuthContent', directive);

	directive.$inject = ['AuthenticationService'];

	function directive(AuthenticationService) {
		return {
			restrict: 'A',
			scope: {},
			link: function(scope, element) {
				scope.$watch(
					function() {
						return AuthenticationService.isAuthenticated();
					},
					function(value) {
						element.toggleClass('ng-hide', !value);
					},
					true
				);
			}
		};
	}
})();
