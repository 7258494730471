/**
 * Created by dan.roach on 8/15/2014.
 */
// can't take credit for this one.  H/T to MANY people on Google who solved this.

angular.module('ui.directives.passwordcheck', []).directive('passwordCheck', [
	function() {
		return {
			require: 'ngModel',
			link: function(scope, elem, attrs, ctrl) {
				var firstPassword = '#' + attrs.passwordCheck;
				elem.add(firstPassword).on('keyup', function() {
					scope.$apply(function() {
						ctrl.$setValidity('passwordmatch', elem.val() === angular.element(firstPassword).val());
					});
				});

				elem.on('$destroy', function() {
					elem.add(firstPassword).off('keyup');
				});
			}
		};
	}
]);
