angular.module('ui.svg.svgutils', []).factory('SVGUtils', [
	'$templateRequest',
	function($templateRequest) {
		var lastFilterBaseUrl, stylesToFix;

		function normalizeCSSFilterURLs() {
			// if (!navigator.userAgent.match(/\bFirefox\b/)) { return; } // don't have to have this, what we do below works in other browsers

			var newFilterBaseUrl = document.location.pathname + document.location.search;
			if (newFilterBaseUrl === lastFilterBaseUrl) {
				return;
			}

			var canContainIdRef = ['filter', 'fill'];

			function fixStyle(style) {
				var hasMatch = false;
				angular.forEach(canContainIdRef, function(prop) {
					if (!style[prop] || style[prop] === 'none') {
						return true;
					}

					// Update the filter/fill URL when it _doesn't_ reference an external SVG file.
					// We'll detect that by excluding matches with ".svg" in them.
					var matches = style[prop].match(/^(["']?)url\((['"]?)(?:[\w\/\-?=&%~]+(?!\.svg))?#([^)"']+)\2\)\1/);

					if (matches) {
						style[prop] = 'url("' + newFilterBaseUrl + '#' + matches[3] + '")';
						hasMatch = true;
					}
				});

				return hasMatch;
			}

			if (stylesToFix) {
				angular.forEach(stylesToFix, function(style) {
					fixStyle(style);
				});
			} else {
				stylesToFix = [];

				angular.forEach(document.styleSheets, function(sheet) {
					try {
						angular.forEach(sheet.cssRules, function(rule) {
							if (!rule.style) {
								return true;
							}

							if (fixStyle(rule.style)) {
								// Cache our list of styles to fix so we don't have
								// to go through the whole stylesheet every time.
								stylesToFix.push(rule.style);
							}
						});
					} catch {
						return true;
					}
				});
			}

			lastFilterBaseUrl = newFilterBaseUrl;
		}

		//always do this
		normalizeCSSFilterURLs();

		var filtersLoaded = false;
		/** Adds an svg document with commonly used SVG filters we use in the app. ("Filter CSS" if you will.) */
		function loadFilters() {
			if (filtersLoaded) {
				return;
			}
			filtersLoaded = true; //(or loading presently, but don't need to run again)

			$templateRequest('ui/svg/common_filters.tpl.html').then(function(tplData) {
				$('<div></div>')
					.html(tplData)
					.appendTo(document.body);
			});
		}

		/**
		 * Helper class for working with SVG elements.
		 * @param SVGElement el If given, we will draw into the given element. If not included, we'll make a new SVG.
		 * Use canvas.svgEl to get the element.
		 */
		var SVGCanvas = function(el) {
			loadFilters();
			this.svgEl = el || document.createElementNS('http://www.w3.org/2000/svg', 'svg');
		};
		SVGCanvas.prototype = {
			/** Removes all elemnts but the defs block form the SVG. */
			clear: function() {
				var el = this.svgEl.firstChild;
				while (el) {
					var toDelete = el;
					el = el.nextSibling;
					if (!toDelete.tagName || toDelete.tagName.toLowerCase() !== 'defs') {
						this.svgEl.removeChild(toDelete);
					}
				}
			},

			/**
			 * Creates an SVG element of the given type, adds it the the canvas, sets the given attributes, and returns it.
			 * The special attribute $text will fill the element's text content.
			 */
			add: function(type, attributes) {
				var el = document.createElementNS('http://www.w3.org/2000/svg', type);
				for (var k in attributes) {
					if (k === '$text') {
						el.appendChild(document.createTextNode(attributes[k]));
					} else if (attributes[k]) {
						el.setAttribute(k, attributes[k]);
					}
				}

				this.svgEl.appendChild(el);

				return el;
			},

			/** Measures the size of the given text (optionally with the given CSS class applied). At present this only returns { width: nnn } */
			measureText: function(text, className) {
				var el = this.add('text', {
					$text: text,
					class: className || ''
				});

				var ret = el.getComputedTextLength();

				this.svgEl.removeChild(el);

				return {
					width: ret
				};
			},

			/** Adds a new <g> with the given class(es) and returns a SVGCanvas for working with it. */
			group: function(className) {
				var g = this.add('g', {
					class: className || ''
				});

				return new SVGCanvas(g);
			},

			/** Adds a new path with the given path and classes. */
			path: function(d, className) {
				return this.add('path', {
					d: d,
					class: className || ''
				});
			},

			pathWithTooltip: function(d, className, tooltipContent) {
				var tooltip = document.createElementNS('http://www.w3.org/2000/svg', 'title'),
					pathElement = document.createElementNS('http://www.w3.org/2000/svg', 'path');

				tooltip.innerHTML = tooltipContent;
				pathElement.setAttribute('d', d);
				pathElement.setAttribute('class', className);
				pathElement.appendChild(tooltip);
				this.svgEl.appendChild(pathElement);
			},

			/** Adds a new polygon using the given points and adds the specified class. */
			polygon: function(points, className) {
				if (angular.isArray(points)) {
					points = points.join(' ');
				}
				return this.add('polygon', {
					points: points,
					class: className || ''
				});
			},

			/** Adds a rectangle. */
			rect: function(startX, startY, width, height, className) {
				return this.add('rect', {
					x: startX,
					y: startY,
					width: width,
					height: height,
					class: className || ''
				});
			},

			/** Adds the given text. */
			text: function(x, y, text, className) {
				return this.add('text', {
					$text: text,
					x: x,
					y: y,
					class: className || ''
				});
			},

			circle: function(x, y, radius, className) {
				return this.add('circle', {
					cx: x,
					cy: y,
					r: radius,
					class: className || ''
				});
			}
		};

		return {
			normalizeCSSFilterURLs: normalizeCSSFilterURLs,
			loadFilters: loadFilters,
			SVGCanvas: SVGCanvas
		};
	}
]);
