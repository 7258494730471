import * as Noty from 'noty';
window.Noty = Noty;

(function() {
	angular
		.module('ui.noty', [])
		.constant('NotyThemes', {
			Information: 'information',
			NotificationFactory: 'notification_factory',
			Relax: 'relax',
			SemanticUI: 'semanticui',
			Mint: 'mint',
			Sunset: 'sunset',
			MetroUI: 'metroui',
			BootstrapV3: 'bootstrap-v3',
			BootstrapV4: 'bootstrap-v4',
			Nest: 'nest'
		})
		.factory('Noty', factory);

	factory.$inject = ['$window', '$log'];

	function factory($window, $log) {
		if (!$window.Noty) {
			$log.error('Noty not found in $window scope');
			$window.Noty = {};
		}

		return $window.Noty;
	}
})();
