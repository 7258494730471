/*

used on an html select: when its ngModel value is changed a blur is invoked which removes focus

*/

(function() {
	angular.module('ui.directives.selectBlur', []).directive('selectBlur', directive);

	function directive() {
		return {
			restrict: 'A',
			require: 'ngModel',
			link: function(scope, element, attrs, ngModel) {
				scope.$watch(
					function() {
						return ngModel.$modelValue;
					},
					function(value, lastValue) {
						if (value !== lastValue) {
							element[0].blur();
						}
					},
					true
				);
			}
		};
	}
})();
