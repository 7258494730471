angular.module('ui.directives.featureFlag', ['api.featureFlags']).directive('featureFlag', [
	'FeatureFlagsService',
	'featureFlagStates',
	'$compile',
	function(FeatureFlagsService, featureFlagStates, $compile) {
		return {
			restrict: 'A',
			link: function($scope, $element, $attr) {
				let cachedElement = $element.clone();
				let afterElement = $element.next();
				let parentElement = $element.parent();

				$element.remove();
				cachedElement.removeAttr('feature-flag');
				cachedElement.removeAttr('feature-flag-context');

				FeatureFlagsService.evaluate($attr.featureFlag, $scope.$eval($attr.featureFlagContext))
					.then(result => {
						if (result === featureFlagStates.show) {
							addElement($compile(cachedElement)($scope), afterElement, parentElement);
						}
					})
					.catch(function(error) {
						console.log(error);
					});

				function addElement(element, after, parent) {
					if (after.length > 0) {
						after.after(element);
					} else {
						parent.append(element);
					}
				}
			}
		};
	}
]);
