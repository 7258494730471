angular
	.module('ui.form.validSubmit', [])

	/**
	 * Directive preventing a form from calling 'submit' unless validation
	 * passes.
	 */
	.directive('ilValidFormSubmit', [
		'$parse',
		function($parse) {
			return {
				// we need a form controller to be on the same element as this directive
				// in other words: this directive can only be used on a <form>
				require: 'form',

				// one time action per form
				link: function(scope, element, iAttrs, form) {
					form.$submitted = false;

					// get a hold of the function that handles submission when form is valid
					var fn = $parse(iAttrs.ilValidFormSubmit);

					// register DOM event handler and wire into Angular's lifecycle with scope.$apply
					element.on('submit', function(event) {
						scope.$apply(function() {
							// on submit event, set submitted to true (like the previous trick)
							form.$submitted = true;

							// if form is valid, execute the submission handler function and reset form submission state
							if (form.$valid) {
								fn(scope, { $event: event });
								form.$submitted = false;
							}
						});
					});
				}
			};
		}
	])

	/**
	 * Directive for linking nested forms (created with ngForm) to the parent
	 * form's $submitted property.
	 */
	.directive('ilNestedValidFormSubmit', [
		function() {
			return {
				require: '^form',
				link: function(scope, elem, attrs, formCtrl) {
					// Initialize $submitted property on nested form
					formCtrl.$submitted = false;

					// Function for walking up the parent scopes to find
					// a parent form with a $submitted property
					var findParentForm = function(currentScope) {
						if (currentScope == null) {
							return null;
						}

						var form;
						_.forIn(currentScope.$parent, function(value) {
							if (_.has(value, '$submitted')) {
								form = value;
								return false;
							}
						});

						if (form == null) {
							// Make recursive call to another level up
							return findParentForm(currentScope.$parent);
						}

						return form;
					};

					// Get a reference to parent form
					scope.parentForm = findParentForm(scope);

					// Link $submitted property on parent form to same property
					// on this form
					scope.$watch('parentForm.$submitted', function(newValue) {
						formCtrl.$submitted = newValue;
					});
				}
			};
		}
	]);
