(function() {
	'use strict';

	angular.module('ui.directives.clickOutside', []).directive('clickOutside', clickOutside);

	clickOutside.$inject = ['$document'];
	function clickOutside($document) {
		// Usage:
		// Add to elements that require an action when clicked outside (to hide etc).
		// Pass action with attrs ex:
		//	click-outside="doSomething()"  <-- this will be executed if the below (optional) condition is true
		//	click-outside-condition="boolSomethingIsVisible" <-- optional but comes in handy
		var directive = {
			link: link,
			restrict: 'A',
			scope: {
				clickOutside: '&',
				clickOutsideCondition: '='
			}
		};
		return directive;

		function link($scope, elem, attr) {
			var conditionExists = attr.clickOutsideCondition !== undefined && attr.clickOutsideCondition !== null;
			$document.on('click touchstart', onClick);

			elem.on('$destroy', function() {
				$document.off('click touchstart', onClick);
			});

			function onClick(event) {
				var node = elem[0]; // Get DOM node so we can check its children

				// If the `click-outside-condition` isn't met, or the clicked element is a child of the
				// directive element, do nothing.
				if ((conditionExists && $scope.clickOutsideCondition === false) || node.contains(event.target)) {
					return;
				}

				$scope.$apply($scope.clickOutside);
			}
		}
	}
})();
