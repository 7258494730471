(function() {
	angular.module('ui.data_filter.directive', []).directive('ilDataFilter', directive);

	directive.$inject = ['$timeout', 'ilDataFilterModes'];

	function directive($timeout, ilDataFilterModes) {
		return {
			restrict: 'EA',
			templateUrl: 'ui/data_filter/data_filter.tpl.html',
			replace: true,
			scope: {
				options: '=',
				meta: '=',
				change: '&'
			},
			controller: 'ilDataFilterController',
			controllerAs: 'ctrl',
			require: 'ngModel',
			link: function(scope, element, attrs, ngModel) {
				var ctrl = scope.ctrl;
				ctrl.options = scope.meta.options;
				ctrl.autoHeight = scope.meta.autoHeight;
				ctrl.label = scope.meta.label;
				ctrl.mode = scope.meta.mode;

				if (ilDataFilterModes.single) {
					ctrl.defaultOptionIndex = scope.meta.defaultOptionIndex;
				}

				ctrl.prepareOptions(scope.meta.options);

				ctrl.init(scope, element, attrs, ngModel);

				element.addClass('il_data_filter');
				ngModel.$render = function() {
					ctrl.selected = ngModel.$viewValue;
				};

				attrs.$observe(
					'disabled',
					function(value) {
						ctrl.disabled = value;
					},
					true
				);

				scope.$watch(
					function() {
						return ctrl.selected;
					},
					ctrl.onSelectedValueChange,
					true
				);
				scope.$watchCollection(
					function() {
						return scope.meta.options;
					},
					ctrl.onOptionsChange,
					true
				);
				scope.$watch(
					function() {
						return scope.meta.label;
					},
					ctrl.onLabelChange,
					true
				);
				scope.$watch(
					function() {
						return scope.meta.btnText;
					},
					ctrl.onButtonTextChange,
					true
				);
				scope.$watch(
					function() {
						return scope.meta.default;
					},
					ctrl.onDefaultChange,
					true
				);
			}
		};
	}
})();
