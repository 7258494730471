(function() {
	angular.module('ui.show.hide', []).directive('ilShowHide', directive);

	directive.$inject = ['$timeout'];

	function directive($timeout) {
		return {
			restrict: 'A',
			template: '<div class="show_hide" ng-transclude></div>',
			replace: true,
			transclude: true,
			link: function(scope, element, attrs) {
				var show = attrs.labelShow && attrs.labelShow.length ? attrs.labelShow : 'Show';
				var hide = attrs.labelHide && attrs.labelHide.length ? attrs.labelHide : 'Hide';
				var open = attrs.open in attrs ? false : true;
				var content = element.find('.show_hide_content');
				var trigger = element.find('.show_hide_trigger');

				function update() {
					$timeout(function() {
						open = !open;
						content.toggleClass('open', open);
						scope.triggerLabel = open ? hide : show;
					}, 0);
				}

				trigger.bind('click', update);
				element.on('$destroy', function() {
					trigger.off('click', update);
				});

				update();
			}
		};
	}
})();
