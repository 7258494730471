(function() {
	angular.module('ui.manager.multiple.select').controller('ManagerMultipleSelectCtrl', controller);

	controller.$inject = ['$scope', '$rootScope', '$filter', '$timeout', '$sce', 'NotificationFactory', 'SelectTypes'];

	function controller($scope, $rootScope, $filter, $timeout, $sce, NotificationFactory, SelectTypes) {
		var $ctrl = this,
			searchHandle,
			init = false,
			sourceIdsInit = false,
			initSourceIds,
			sourceIdsChanged = false,
			index = 0;

		$ctrl.$onInit = onInit;

		$ctrl.busy = false;
		$ctrl.items = [];
		$ctrl.debouncedSearch = _.debounce(getSearchItems, 300);
		$ctrl.typeInstance = SelectTypes.get($scope.type);
		// filter synced items by default
		$ctrl.filterSyncedItems =
			$scope.filterSyncedItems === undefined || $scope.filterSyncedItems === null ? true : !!$scope.filterSyncedItems;

		$ctrl.selectItem = selectItem;
		$ctrl.unSelectItem = unSelectItem;
		$ctrl.isSelected = isSelected;
		$ctrl.showNoResults = showNoResults;
		$ctrl.getMoreItems = getMoreItems;
		$ctrl.addDisplayText = addDisplayText;
		$ctrl.filterItems = filterItems;

		// LIFE CYCLE START ------------

		function onInit() {
			$ctrl.items = [];
			$ctrl.typeInstance = SelectTypes.get($scope.type);
			$ctrl.addDisplayText();
			$ctrl.filterItems();

			init = true;
		}

		// LIFE CYCLE END ------------

		// WATCHERS START ------------

		$scope.$watch('open', _onOpenChange, true);

		$scope.$watch('disabled', _onDisabledChange, true);

		$scope.$watch('sourceIds', _onSourceIdsChange, true);

		$scope.$watch('selectedIds', _onSelectedIdsChange, true);

		function _onOpenChange(value) {
			if (value) {
				$ctrl.getMoreItems();
			}
		}

		function _onDisabledChange(value) {
			$scope.disabled = !!value;
			$ctrl.getMoreItems();
		}

		function _onSourceIdsChange(value) {
			// set source ids
			if (value && value.length && !sourceIdsInit) {
				sourceIdsInit = true;
				initSourceIds = value;
			}

			// remove irrelevant items
			if (
				value &&
				value.length &&
				init &&
				sourceIdsInit &&
				!!!$scope.singleSelect &&
				initSourceIds &&
				initSourceIds.length &&
				!angular.equals(initSourceIds, value)
			) {
				initSourceIds = value;
				var itemsCopy = [];
				angular.copy($ctrl.unSyncedItems, itemsCopy);
				if (itemsCopy.length) {
					_.forEach(itemsCopy, function(item) {
						if (item.hasOwnProperty('organizationId') && initSourceIds.indexOf(item.organizationId) === -1) {
							$ctrl.unSelectItem(item, true);
						}
					});
				}
				sourceIdsChanged = true;
				$ctrl.getMoreItems(true);
			}
		}

		function _onSelectedIdsChange(value) {
			if (value && !_.isEmpty(value)) {
				if (!$scope.selectedItems || !$scope.selectedItems.length || !angular.equals(value, _.map($scope.selectedItems, 'id'))) {
					if (!$ctrl.selectedIdsPromise) {
						$ctrl.selectedIdsPromise = $ctrl.typeInstance.queryByIds(value);

						if ($ctrl.selectedIdsPromise !== null) {
							$ctrl.selectedIdsPromise
								.then(function(results) {
									if (results && results.items && results.items.length) {
										$scope.selectedItems = [];
										$ctrl.syncedItems = [];
										$ctrl.unSyncedItems = [];

										angular.forEach(results.items, function(item) {
											item.displayText = $ctrl.typeInstance.displayText(item);
											$scope.selectedItems.push(item);
										});
										$scope.selectedItems = _naturalSort($scope.selectedItems, $ctrl.typeInstance.sortField);
										$ctrl.filterItems();
									}
								})
								.finally(function() {
									$ctrl.selectedIdsPromise = null;
								});
						}
					}
				} else {
					angular.forEach($scope.selectedItems, function(item) {
						if (!item.displayText) {
							item.displayText = $ctrl.typeInstance.displayText(item);
						}
					});
					$scope.selectedItems = _naturalSort($scope.selectedItems, $ctrl.typeInstance.sortField);
				}
			}

			$ctrl.filterItems();
		}

		// WATCHERS END ------------

		// ACTIONS START ------------

		function addDisplayText() {
			// make sure each item has displayText property
			if ($ctrl.typeInstance) {
				angular.forEach($scope.selectedItems, function(item) {
					if (!item.displayText) {
						item.displayText = $ctrl.typeInstance.displayText(item);
					}
				});
			}
		}

		function filterItems() {
			if ($ctrl.filterSyncedItems) {
				$ctrl.syncedItems = _.filter($scope.selectedItems, function(item) {
					return item && item.syncId && item.syncId.length;
				});
				$ctrl.unSyncedItems = _.filter($scope.selectedItems, function(item) {
					return !item.syncId;
				});
			} else {
				$ctrl.syncedItems = [];
				$ctrl.unSyncedItems = $scope.selectedItems;
			}
		}

		function getSearchItems() {
			if (searchHandle) {
				$timeout.cancel(searchHandle);
			}
			searchHandle = $timeout(function() {
				$ctrl.getMoreItems(true);
			}, 0);
		}

		function unSelectItem(item, skipUiEffect) {
			if ($scope.disabled === true || !item) {
				return;
			}
			$ctrl.busy = true;
			var selectedItemsIndex;
			if ($scope.singleSelect !== undefined && $scope.singleSelect === true) {
				selectedItemsIndex = _.findIndex($scope.selectedItems, { id: item.id });
				if (selectedItemsIndex !== -1) {
					$scope.selectedItems.splice(selectedItemsIndex, 1);
				}
				if (!skipUiEffect) {
					$scope.blur();
				}
			} else {
				selectedItemsIndex = _.findIndex($scope.selectedItems, { id: item.id });

				if (selectedItemsIndex !== -1) {
					$scope.selectedItems.splice(selectedItemsIndex, 1);
				}
				if (!skipUiEffect) {
					$scope.focus();
				}
			}
			$ctrl.filterItems();
			if (!skipUiEffect) {
				$ctrl.getMoreItems(true);
			}
			$ctrl.busy = false;
		}

		function selectItem(item) {
			if ($scope.disabled === true || !item || item.disabled) {
				return;
			}
			$ctrl.busy = true;
			if ($scope.singleSelect !== undefined && $scope.singleSelect === true) {
				$scope.selectedItems = [item];
				$scope.blur();
			} else {
				if ($ctrl.typeInstance) {
					$scope.selectedItems = $scope.selectedItems ? $scope.selectedItems : [];
					$scope.selectedItems.push(item);
					$scope.selectedItems = _naturalSort($scope.selectedItems, $ctrl.typeInstance.sortField);
					$scope.focus();
				}
			}
			$ctrl.filterItems();
			$timeout(function() {
				$ctrl.busy = false;
			}, 0);
		}

		function showNoResults() {
			return !$ctrl.items.length || _allItemsSelected();
		}

		// ACTIONS START ------------

		// KEY MAPS START ------------
		// KEY MAPS END ------------

		// OTHER START ------------

		function isSelected(item) {
			return (
				_.find($ctrl.unSyncedItems, function(i) {
					return i.id === item.id;
				}) !== undefined
			);
		}

		function _allItemsSelected() {
			if (!$ctrl.items || !$ctrl.items.length) {
				return false;
			}
			var selArray = _.reject($ctrl.items, function(item) {
				return !isSelected(item);
			});
			return selArray && selArray.length === $ctrl.items.length;
		}

		function getMoreItems(reset) {
			if (!$scope.type || !$ctrl.typeInstance || $ctrl.busy) {
				return;
			}

			$timeout(function() {
				$ctrl.busy = true;

				if (reset) {
					index = $ctrl.totalCount = 0;
					$ctrl.items = [];
				}

				_updateTypeDetails();

				var queryParams = {
					limit: 100,
					offset: index,
					search: $ctrl.search
				};
				queryParams.sourceIds = $scope.sourceIds && $scope.sourceIds.length ? $scope.sourceIds : undefined;

				$ctrl.typeInstance
					.query(queryParams)
					.then(function(results) {
						if ($ctrl.typeInstance.aggregate && $ctrl.typeInstance.aggregate === true) {
							if (results && results.length > 0) {
								angular.forEach(results, function(result) {
									if (result && result.items && result.items.length > 0) {
										index += 100;
										angular.forEach(result.items, function(item) {
											item.displayText = $ctrl.typeInstance.displayText(item);
											$ctrl.items.push(item);
										});
									}
								});

								if ($ctrl.filterSyncedItems) {
									$ctrl.items = _.filter($ctrl.items, function(item) {
										return !item.syncId;
									});
								}

								$ctrl.items = _naturalSort(
									_.uniqWith($ctrl.items, function(arrVal, othVal) {
										return arrVal.id === othVal.id;
									}),
									$ctrl.typeInstance.sortField
								);

								if ($scope.callback && typeof $scope.callback === 'function') {
									$ctrl.items = $scope.callback({ data: $ctrl.items });
								}

								$ctrl.totalCount = $ctrl.items.length;
							}
						} else {
							if (results && results.items && results.items.length > 0) {
								index += 100;

								angular.forEach(results.items, function(item) {
									item.displayText = $ctrl.typeInstance.displayText(item);
									$ctrl.items.push(item);
								});

								if ($ctrl.filterSyncedItems) {
									$ctrl.items = _.filter($ctrl.items, function(item) {
										return !item.syncId;
									});
								}

								$ctrl.items = _naturalSort(
									_.uniqWith($ctrl.items, function(arrVal, othVal) {
										return arrVal.id === othVal.id;
									}),
									$ctrl.typeInstance.sortField
								);

								if ($scope.callback && typeof $scope.callback === 'function') {
									$ctrl.items = $scope.callback({ data: $ctrl.items });
								}

								$ctrl.totalCount = $ctrl.items.length;
							}
						}
					})
					.catch(function(error) {
						NotificationFactory.error(error);
					})
					.finally(function() {
						$ctrl.busy = false;
					});
			}, 0);
		}

		function _updateTypeDetails() {
			if (!$ctrl.typeInstance) {
				return;
			}

			$ctrl.translations = $ctrl.typeInstance
				? $ctrl.typeInstance.translations
				: {
						selectAll: 'Select all',
						selectNone: 'Clear Selected',
						reset: 'Undo all',
						search: 'Search',
						nothingSelected: 'None Selected'
				  };

			$ctrl.sortField = $ctrl.typeInstance.sortField;
			if ($ctrl.typeInstance && $ctrl.typeInstance.icon) {
				$ctrl.entityTypeIcon = $sce.trustAsHtml($ctrl.typeInstance.icon);
			}
		}

		function _naturalSort(array, orderBy) {
			return $filter('orderBy')(array, $rootScope.natural(orderBy));
		}

		// OTHER END ------------
	}
})();
