angular.module('ui.directives.orientationchange', []).directive('orientationChangeHandler', [
	'$window',
	'$timeout',
	function($window, $timeout) {
		return {
			restrict: 'A',
			link: function($scope, elem, attr) {
				//force hardware acceleration
				angular.element('body').css('-webkit-transform', 'translate3d(0, 0, 0)');

				//add these if the above does not smooth everything out
				//-webkit-backface-visibility: hidden;
				//-webkit-perspective: 1000;

				function onOrientationChange() {
					angular.element('body').css('-webkit-transform', 'scale(0.9999)');
					$timeout(function() {
						angular.element('body').css('-webkit-transform', 'scale(1.0)');
					}, 0);
				}
				angular.element($window).on('orientationchange', onOrientationChange);

				element.on('$destroy', function() {
					angular.element($window).off('orientationchange', onOrientationChange);
				});
			}
		};
	}
]);
