angular.module('ui.assist.mode.bar').config([
	'LocalizeProvider',
	function(LocalizeProvider) {
		LocalizeProvider.translations({
			en: {
				endImpersonation: 'Exit Assist Mode',
				impersonationHeading: 'Assist Mode Active',
				impersonationMessage: '%1$s is using the application as %2$s.'
			}
		});
	}
]);
