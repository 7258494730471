(function(angular) {
	angular.module('ui.tri.state.checkbox', []).directive('triStateCheckbox', [
		function() {
			return {
				restrict: 'A',
				require: '?ngModel',
				link: function(scope, element, attrs, ctrl) {
					var truthy = true;
					var falsy = false;
					var nully = null;
					ctrl.$formatters = [];
					ctrl.$parsers = [];

					ctrl.$render = function() {
						var d = ctrl.$viewValue;
						element.data('checked', d);
						switch (d) {
							case truthy:
								element.prop('indeterminate', false);
								element.prop('checked', true);
								break;
							case falsy:
								element.prop('indeterminate', false);
								element.prop('checked', false);
								break;
							default:
								element.prop('indeterminate', true);
						}
					};

					function onClick() {
						var d;
						switch (element.data('checked')) {
							case falsy:
								d = truthy;
								break;
							case truthy:
								d = nully;
								break;
							default:
								d = falsy;
						}
						ctrl.$setViewValue(d);
						scope.$apply(ctrl.$render);
					}

					element.bind('click', onClick);

					element.on('$destroy', function() {
						element.off('click', onClick);
					});
				}
			};
		}
	]);
})(window.angular);
