(function() {
	angular.module('ui.sticky.headers').controller('StickyHeaderCtrl', controller);

	controller.$inject = ['$scope'];

	function controller($scope) {
		// properties
		var ctrl = this;
		ctrl.id = $scope.$id;
		ctrl.content = undefined;
		ctrl.header = undefined;
		ctrl.clonedHeader = undefined;
		ctrl.scrollableContainer = undefined;
		ctrl.contentOffset = 0;
		ctrl.scrollStop = 0;
		ctrl.headerZIndex = 9999999;

		// methods
		ctrl.init = init;
		ctrl.getScrollTop = getScrollTop;
		ctrl.getScrollLeft = getScrollLeft;
		ctrl.getContentTop = getContentTop;
		ctrl.getContentBottom = getContentBottom;
		ctrl.refresh = refresh;
		ctrl.resize = resize;
		ctrl.cloneHeader = cloneHeader;
		ctrl.unCloneHeader = unCloneHeader;

		function init() {
			ctrl.scrollableContainer.on('scroll.stickyHeader' + ctrl.id, refresh).trigger('scroll');
			ctrl.scrollableContainer.on('resize.stickyHeader' + ctrl.id, refresh);

			$scope.$on('$destroy', function() {
				ctrl.scrollableContainer.off('.stickyHeader' + ctrl.id);
			});
		}

		function getScrollTop() {
			return ctrl.scrollableContainer.scrollTop() + ctrl.scrollStop;
		}

		function getScrollLeft() {
			return -ctrl.scrollableContainer.scrollLeft() + ctrl.content.offset().left;
		}

		function getContentTop() {
			return ctrl.content.offset().top + ctrl.contentOffset;
		}

		function getContentBottom(contentTop) {
			return contentTop + ctrl.content.outerHeight(false);
		}

		function refresh() {
			var scrollTop = getScrollTop(),
				scrollLeft = getScrollLeft(),
				contentTop = getContentTop(),
				contentBottom = getContentBottom(contentTop);

			if (scrollTop > contentTop && scrollTop < contentBottom) {
				if (!ctrl.clonedHeader) {
					cloneHeader();
				}

				if (scrollTop < contentBottom && scrollTop > contentBottom - ctrl.clonedHeader.outerHeight(false)) {
					var top = contentBottom - scrollTop + ctrl.scrollStop - ctrl.clonedHeader.outerHeight(false);
					ctrl.clonedHeader.css('top', top + 'px');
				} else {
					resize();
				}
				ctrl.clonedHeader.css('left', scrollLeft + 'px');
			} else {
				if (ctrl.clonedHeader) {
					unCloneHeader();
				}
			}
		}

		function resize() {
			ctrl.clonedHeader.css({
				top: ctrl.scrollStop,
				width: ctrl.header[0].offsetWidth,
				left: ctrl.header.offset().left
			});
			if (ctrl.clonedHeader.is('tr') || ctrl.clonedHeader.is('thead')) {
				var clonedColumns = ctrl.clonedHeader.find('th');
				ctrl.header.find('th').each(function(index, column) {
					var clonedColumn = angular.element(clonedColumns[index]);
					clonedColumn.css('width', (column.offsetWidth || 0) + 'px');
				});
			}
		}

		function cloneHeader() {
			ctrl.clonedHeader = ctrl.header;
			ctrl.header = ctrl.clonedHeader.clone();
			ctrl.clonedHeader.after(ctrl.header);
			ctrl.clonedHeader.addClass('sticky-header');
			if (ctrl.ieFix) {
				ctrl.clonedHeader.addClass('ie');
			}
			ctrl.clonedHeader.css({
				position: 'fixed',
				'z-index': ctrl.headerZIndex,
				visibility: 'hidden'
			});
			resize();
			ctrl.header.css({
				visibility: 'hidden'
			});
			ctrl.clonedHeader.css({ visibility: 'visible' });
		}

		function unCloneHeader() {
			ctrl.header.remove();
			ctrl.header = ctrl.clonedHeader;
			ctrl.clonedHeader = null;
			ctrl.header.removeClass('sticky-header');
			if (ctrl.ieFix) {
				ctrl.header.removeClass('ie');
			}
			ctrl.header.css({
				position: 'inherit',
				left: 0,
				top: 0,
				width: 'auto',
				'z-index': 0,
				visibility: 'visible'
			});
		}
	}
})();
