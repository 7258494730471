(function(angular) {
	angular.module('ui.phoneticCharacter', []).directive('ilPhoneticCharacter', phoneticCharacter);

	function phoneticCharacter() {
		var directive = {
			restrict: 'A',
			scope: {
				value: '@ilPhoneticCharacter'
			},
			link: linkFunction
		};

		function linkFunction($scope, element, attributes) {
			// if (!attributes.ilPhoneticCharacter) {
			// 	return;
			// }

			$scope.$watch('value', onChanged);

			// onChanged(attributes.ilPhoneticCharacter);

			function onChanged(newValue, oldValue) {
				// if (newValue === oldValue) {
				// 	return;
				// }

				var replaced = newValue.replace(/\{([^\}]+)\}/g, '<span class="phonetic_character">$1</span>');
				element.html(replaced);
			}
		}

		return directive;
	}
})(window.angular);
