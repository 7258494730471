(function() {
	angular.module('ui.timeinput').config(config);

	config.$inject = ['LocalizeProvider'];

	function config(LocalizeProvider) {
		LocalizeProvider.translations({
			en: {
				timeinput: {}
			}
		});
	}
})();
