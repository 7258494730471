//matches element width to that of its parent

angular.module('ui.directives.matchWidth', []).directive('matchWidth', [
	'$timeout',
	'$window',
	function($timeout, $window) {
		return {
			restrict: 'A',
			link: function($scope, element, attrs) {
				var resize = function() {
					$(element).width(
						$(element)
							.parent()
							.width()
					);

					// var offset = attrs.offset ? attrs.offset : 0;
					// var parentWidth = $window.innerWidth - element.parent()[0].offsetWidth - offset;
					// var elementWidth = $window.innerWidth - element[0].offsetWidth - offset;
					//
					// if (parentWidth > elementWidth) {
					// 	$(element).animate({ 'width': parentWidth + 'px' }, 100);
					// }
				};

				angular.element($window).bind('resize', resize);

				element.on('$destroy', function() {
					angular.element($window).off('resize', resize);
				});

				$timeout(function() {
					resize();
				}, 0);
			}
		};
	}
]);
