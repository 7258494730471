(function() {
	angular
		.module('ui.tutorials', ['ui.popup', 'ui.router'])
		//shared collection/triggers for tutorial events
		.factory('Tutorials', factory)
		.directive('ilTutorialToggle', tutorialToggleDirective)
		.directive('ilTutorialStep', tutorialStepDirective);

	factory.$inject = ['$rootScope'];
	function factory($rootScope) {
		var tutorialFactory = {};
		tutorialFactory.collection = {};
		tutorialFactory.exists = function(tutorialName) {
			return tutorialFactory.collection[tutorialName] !== undefined;
		};

		tutorialFactory.start = function(tutorialName) {
			if (!tutorialFactory.exists(tutorialName)) {
				tutorialFactory.collection[tutorialName] = { name: tutorialName, step: 1 };
				$rootScope.$broadcast('ui.tutorials.start', { name: tutorialName });
			}
		};

		tutorialFactory.step = function(tutorialName, stepNumber) {
			if (tutorialFactory.exists(tutorialName)) {
				tutorialFactory.collection[tutorialName].step = stepNumber;
				$rootScope.$broadcast('ui.tutorials.step', tutorialFactory.collection[tutorialName]);
			}
		};

		tutorialFactory.stop = function(tutorialName) {
			if (tutorialFactory.exists(tutorialName)) {
				delete tutorialFactory.collection[tutorialName];
				$rootScope.$broadcast('ui.tutorials.stop', { name: tutorialName });
			}
		};

		$rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
			angular.forEach(tutorialFactory.collection, function(collection) {
				tutorialFactory.stop(collection.name);
			});
		});

		return tutorialFactory;
	}

	tutorialToggleDirective.$inject = ['$rootScope', 'Tutorials', '$state'];

	//simple show/hide toggle for a given tutorial, tutorial steps handle stepping themselves
	//if no tutorial name is specified the current state.data must contain tutorialName
	function tutorialToggleDirective($rootScope, Tutorials, $state) {
		return {
			restrict: 'A',
			link: function(scope, element, attrs) {
				var tutorialName = null;

				$rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
					element.removeClass('active');
				});

				element.bind('click', function(event) {
					tutorialName =
						attrs.ilTutorialToggle !== undefined && attrs.ilTutorialToggle !== ''
							? attrs.ilTutorialToggle
							: $state.current.data.tutorialName;
					if (Tutorials.exists(tutorialName)) {
						element.removeClass('active');
						Tutorials.stop(tutorialName);
					} else {
						element.addClass('active');
						Tutorials.start(tutorialName);
					}
				});

				element.on('$destroy', function() {
					element.off('click');
				});
			}
		};
	}

	tutorialStepDirective.$inject = ['$rootScope', '$compile', 'Tutorials'];

	function tutorialStepDirective($rootScope, $compile, Tutorials) {
		return {
			restrict: 'A',
			scope: {
				ilTutorialName: '@',
				ilTutorialStepContent: '@',
				ilTutorialPosition: '='
			},
			link: function(scope, element, attrs) {
				//if this element does not have a position set it needs to be....
				var pos = element.css('position');
				if (!pos || pos === 'static') {
					element.css('position', 'relative');
				}

				//create ui elements
				scope.stepNumber =
					attrs.ilTutorialStep !== undefined && attrs.ilTutorialStep !== '' ? parseInt(attrs.ilTutorialStep, 10) : null;
				var popUpId = scope.ilTutorialName + '-Step-' + scope.stepNumber;

				var tutorialStepElement = $compile(
					'<div class="tutorial_step"><span il-mini-popup id="' +
						popUpId +
						'" il-mini-popup-position-my="center top" il-mini-popup-position-at="center bottom+20"></span></div>'
				)(scope);

				//apply positioning overrides
				if (scope.ilTutorialPosition) {
					if (scope.ilTutorialPosition.left) {
						tutorialStepElement.css('left', scope.ilTutorialPosition.left);
					}
					if (scope.ilTutorialPosition.right) {
						tutorialStepElement.css('right', scope.ilTutorialPosition.right);
					}
					if (scope.ilTutorialPosition.top) {
						tutorialStepElement.css('top', scope.ilTutorialPosition.top);
					}
					if (scope.ilTutorialPosition.bottom) {
						tutorialStepElement.css('bottom', scope.ilTutorialPosition.bottom);
					}
				}

				element.append(tutorialStepElement);

				//show/hide trigger element
				scope.show = function() {
					tutorialStepElement.addClass('enabled');
				};

				scope.hide = function() {
					tutorialStepElement.removeClass('enabled');
				};

				//show pop up
				scope.noCallback = false;
				scope.showPopUpCallback = function() {
					if (!scope.noCallback) {
						Tutorials.step(scope.ilTutorialName, scope.stepNumber + 1);
					}
					scope.noCallback = false;
				};

				var showPopUp = function() {
					if (tutorialStepElement.hasClass('enabled')) {
						Tutorials.collection[scope.ilTutorialName].step = scope.stepNumber;
						$rootScope.$broadcast('toggleMiniPopup', {
							element: tutorialStepElement[0],
							popupId: popUpId,
							content: scope.ilTutorialStepContent,
							onClose: undefined //scope.showPopUpCallback
						});
					}
				};

				//use with 'Show Me' type links. the specified tutorial name/step will show throbber, closing pop up will hide throbber
				scope.$on('ui.tutorials.showme', function(event, args) {
					if (args.name && args.name === scope.ilTutorialName && scope.stepNumber && scope.stepNumber === args.step) {
						tutorialStepElement.addClass('enabled');
						$rootScope.$broadcast('toggleMiniPopup', {
							element: tutorialStepElement[0],
							popupId: popUpId,
							content: scope.ilTutorialStepContent,
							onClose: scope.hide
						});
					}
				});

				scope.$on('ui.tutorials.step', function(event, args) {
					if (args.name === scope.ilTutorialName && scope.stepNumber === args.step) {
						showPopUp();
					}
				});

				scope.$on('ui.tutorials.start', function(event, args) {
					if (args.name === scope.ilTutorialName) {
						scope.show();
						//if(scope.stepNumber === 1) {
						//	showPopUp();
						//}
					}
				});

				scope.$on('ui.tutorials.stop', function(event, args) {
					if (args.name === scope.ilTutorialName) {
						scope.hide();
					}
				});

				//click/touch handler
				tutorialStepElement.on('click touchstart', function(clickEvent) {
					clickEvent.preventDefault();
					clickEvent.stopPropagation();
					scope.noCallback = true;
					showPopUp();
				});

				element.on('$destroy', function() {
					tutorialStepElement.off('click touchstart');
				});

				//on addition to the dom, if the tutorial the step belongs to
				//is active then we show the step trigger
				if (Tutorials.exists(scope.ilTutorialName)) {
					scope.show();
				} else {
					scope.hide();
				}
			}
		};
	}
})();
//
//// TODO: Can we get rid of this dependency on ui.router?
//angular.module('ui.tutorials', ['ui.popup', 'ui.router'])
//	//shared collection/triggers for tutorial events
//	.factory("Tutorials", ['$rootScope',  function ($rootScope) {
//
//		var tutorialFactory = {};
//		tutorialFactory.collection = {};
//		tutorialFactory.exists = function(tutorialName){
//			return tutorialFactory.collection[tutorialName] !== undefined;
//		};
//
//		tutorialFactory.start = function(tutorialName){
//			if(!tutorialFactory.exists(tutorialName)){
//				tutorialFactory.collection[tutorialName] = { name: tutorialName, step: 1 };
//				$rootScope.$broadcast('ui.tutorials.start', { name: tutorialName });
//			}
//		};
//
//		tutorialFactory.step = function(tutorialName, stepNumber){
//			if(tutorialFactory.exists(tutorialName)){
//				tutorialFactory.collection[tutorialName].step = stepNumber;
//				$rootScope.$broadcast('ui.tutorials.step', tutorialFactory.collection[tutorialName]);
//			}
//		};
//
//		tutorialFactory.stop = function(tutorialName){
//			if (tutorialFactory.exists(tutorialName)) {
//				delete tutorialFactory.collection[tutorialName];
//				$rootScope.$broadcast('ui.tutorials.stop', { name: tutorialName });
//			}
//		};
//
//		$rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
//			angular.forEach(tutorialFactory.collection, function(collection){
//				tutorialFactory.stop(collection.name);
//			});
//		});
//
//		return tutorialFactory;
//	}])
//	//simple show/hide toggle for a given tutorial, tutorial steps handle stepping themselves
//	//if no tutorial name is specified the current state.data must contain tutorialName
//	.directive('ilTutorialToggle', ['$rootScope', 'Tutorials', '$state', function ($rootScope, Tutorials, $state) {
//		return {
//			restrict: "A",
//			link: function (scope, element, attrs) {
//				var tutorialName = null;
//
//				$rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
//					element.removeClass('active');
//				});
//
//				element.bind('click', function (event) {
//					tutorialName = (attrs.ilTutorialToggle !== undefined && attrs.ilTutorialToggle !== '') ? attrs.ilTutorialToggle : $state.current.data.tutorialName;
//					if(Tutorials.exists(tutorialName)){
//						element.removeClass('active');
//						Tutorials.stop(tutorialName);
//					}
//					else {
//						element.addClass('active');
//						Tutorials.start(tutorialName);
//					}
//				});
//
//				element.on('$destroy', function () {
//					element.off('click');
//				});
//			}
//		};
//	}])
//	//step number is the primary attribute. ex: il-tutorial-step='1'
//	//if no step number is present it will function alone as an informative pop up
//	.directive('ilTutorialStep', ['$rootScope', '$compile', 'Tutorials', function ($rootScope, $compile, Tutorials) {
//		return {
//			restrict: "A",
//			scope: {
//				ilTutorialName: "@",
//				ilTutorialStepContent: "@"
//			},
//			link: function (scope, element, attrs) {
//				//if this element does not have a position set it needs to be....
//				var pos = element.css('position');
//				if(!pos || pos === 'static') {
//					element.css('position', 'relative');
//				}
//
//				//create ui elements
//				scope.stepNumber = (attrs.ilTutorialStep !== undefined && attrs.ilTutorialStep !== '') ? parseInt(attrs.ilTutorialStep, 10) : null;
//				var popUpId = scope.ilTutorialName + '-Step-' +scope.stepNumber;
//
//				var tutorialStepElement = $compile('<div class="tutorial_step"><span il-mini-popup id="' + popUpId + '" il-mini-popup-position-my="center top" il-mini-popup-position-at="center bottom+20"></span></div>')(scope);
//				element.append(tutorialStepElement);
//
//				//show/hide trigger element
//				scope.show = function () {
//					tutorialStepElement.addClass('enabled');
//				};
//
//				scope.hide = function () {
//					tutorialStepElement.removeClass('enabled');
//				};
//
//				//show pop up
//				scope.noCallback = false;
//				scope.showPopUpCallback = function () {
//					if (!scope.noCallback) {
//						Tutorials.step(scope.ilTutorialName, scope.stepNumber + 1);
//					}
//					scope.noCallback = false;
//				};
//
//				var showPopUp = function() {
//					if (tutorialStepElement.hasClass('enabled')) {
//						Tutorials.collection[scope.ilTutorialName].step = scope.stepNumber;
//						$rootScope.$broadcast('toggleMiniPopup', {
//							element: tutorialStepElement[0],
//							popupId: popUpId,
//							content: scope.ilTutorialStepContent,
//							onClose: undefined//scope.showPopUpCallback
//						});
//					}
//				};
//
//				scope.$on('ui.tutorials.step', function (event, args) {
//					if(args.name === scope.ilTutorialName && scope.stepNumber === args.step){
//						showPopUp();
//					}
//				});
//
//				scope.$on('ui.tutorials.start', function (event, args) {
//					if(args.name === scope.ilTutorialName){
//						scope.show();
//						//if(scope.stepNumber === 1) {
//						//	showPopUp();
//						//}
//					}
//				});
//
//				scope.$on('ui.tutorials.stop', function (event, args) {
//					if(args.name === scope.ilTutorialName) {
//						scope.hide();
//					}
//				});
//
//				//click/touch handler
//				tutorialStepElement.on('click touchstart', function (clickEvent) {
//					clickEvent.preventDefault();
//					clickEvent.stopPropagation();
//					scope.noCallback = true;
//					showPopUp();
//				});
//
//				element.on('$destroy', function () {
//					tutorialStepElement.off('click touchstart');
//				});
//
//				//on addition to the dom, if the tutorial the step belongs to
//				//is active then we show the step trigger
//				if(Tutorials.exists(scope.ilTutorialName)) {
//					scope.show();
//				}
//				else {
//					scope.hide();
//				}
//
//
//			}
//		};
//	}]);
