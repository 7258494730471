(function() {
	angular
		.module('ui.notifications.helpers', [])

		.constant('NotificationTypes', {
			alert: 'alert',
			error: 'error',
			success: 'success',
			information: 'information',
			warning: 'warning',
			confirm: 'confirm',
			inactivity: 'inactivity',
			impersonation: 'impersonation'
		})

		.constant('HTMLStatusCodes', [
			{ code: 100, value: 'Continue' },
			{ code: 101, value: 'Switching Protocols' },
			{ code: 102, value: 'Processing' },
			{ code: 200, value: 'OK' },
			{ code: 201, value: 'Created' },
			{ code: 202, value: 'Accepted' },
			{ code: 203, value: 'Non-Authoritative Information' },
			{ code: 204, value: 'No Content' },
			{ code: 205, value: 'Reset Content' },
			{ code: 206, value: 'Partial Content' },
			{ code: 207, value: 'Multi-Status' },
			{ code: 208, value: 'Already Reported' },
			{ code: 226, value: 'IM Used' },
			{ code: 300, value: 'Multiple Choices' },
			{ code: 301, value: 'Moved Permanently' },
			{ code: 302, value: 'Found' },
			{ code: 303, value: 'See Other' },
			{ code: 304, value: 'Not Modified' },
			{ code: 305, value: 'Use Proxy' },
			{ code: 306, value: '(Unused)' },
			{ code: 307, value: 'Temporary Redirect' },
			{ code: 308, value: 'Permanent Redirect' },
			{ code: 400, value: 'Bad Request' },
			{ code: 401, value: 'Unauthorized' },
			{ code: 402, value: 'Payment Required' },
			{ code: 403, value: 'Forbidden' },
			{ code: 404, value: 'Not Found' },
			{ code: 405, value: 'Method Not Allowed' },
			{ code: 406, value: 'Not Acceptable' },
			{ code: 407, value: 'Proxy Authentication Required' },
			{ code: 408, value: 'Request Timeout' },
			{ code: 409, value: 'Conflict' },
			{ code: 410, value: 'Gone' },
			{ code: 411, value: 'Length Required' },
			{ code: 412, value: 'Precondition Failed' },
			{ code: 413, value: 'Payload Too Large' },
			{ code: 414, value: 'URI Too Long' },
			{ code: 415, value: 'Unsupported Media Type' },
			{ code: 416, value: 'Range Not Satisfiable' },
			{ code: 417, value: 'Expectation Failed' },
			{ code: 421, value: 'Misdirected Request' },
			{ code: 422, value: 'Unprocessable Entity' },
			{ code: 423, value: 'Locked' },
			{ code: 424, value: 'Failed Dependency' },
			{ code: 425, value: 'Unassigned' },
			{ code: 426, value: 'Upgrade Required' },
			{ code: 427, value: 'Unassigned' },
			{ code: 428, value: 'Precondition Required' },
			{ code: 429, value: 'Too Many Requests' },
			{ code: 430, value: 'Unassigned' },
			{ code: 431, value: 'Request Header Fields Too Large' },
			{ code: 500, value: 'Internal Server Error' },
			{ code: 501, value: 'Not Implemented' },
			{ code: 502, value: 'Bad Gateway' },
			{ code: 503, value: 'Service Unavailable' },
			{ code: 504, value: 'Gateway Timeout' },
			{ code: 505, value: 'HTTP Version Not Supported' },
			{ code: 506, value: 'Variant Also Negotiates' },
			{ code: 507, value: 'Insufficient Storage' },
			{ code: 508, value: 'Loop Detected' },
			{ code: 509, value: 'Unassigned' },
			{ code: 510, value: 'Not Extended' },
			{ code: 511, value: 'Network Authentication Required' }
		]);
})();
