import '../../_shared/src/vendor';

var modules = require.context('./src', true, /module\.js$/);
modules.keys().forEach(modules);

var js = require.context('./src', true, /^(?!.*\.(test|spec|mock|module)\.js$).*\.js$/);
js.keys().forEach(js);

var assets = require.context('./src', true, /\.(png|jpg|jpeg|gif|svg|woff|woff2|ttf|eot)$/);
assets.keys().forEach(assets);

angular.module('ui.templates', []);
var templates = require.context('./src', true, /^(?!.*index\.html$).*\.html$/);
templates.keys().forEach(templates);
