(function() {
	angular
		.module('ui.notifications', [
			'ngStorage',
			'ui.noty',
			'ui.notifications.helpers',
			'ngAnimate',
			'api.analytics',
			'api.application.user',
			'utils.cookies',
			'utils.localization'
		])
		.factory('Notification', factory)
		.provider('NotificationFactory', notificationFactoryProvider)
		.directive('ilNotificationOverlay', notificationOverlayDirective)
		.directive('ilNotificationClose', notificationCloseDirective);

	factory.$inject = ['$sce', 'NotificationTypes'];

	function factory($sce, NotificationTypes) {
		function getID() {
			//GUID-esque ids
			function s4() {
				return Math.floor((1 + Math.random()) * 0x10000)
					.toString(16)
					.substring(1);
			}

			return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
		}

		//Notification(notificationType, heading, content, timeout, persist)
		function Notification(notificationType, heading, content, timeout, persist) {
			this.id = getID();
			this.notificationType = notificationType || NotificationTypes.information;
			this.heading = heading || '';
			this.content = $sce.trustAsHtml(content || '');
			this.timeout = timeout || 0;
			this.persist = persist || false;
			this.visible = false;
		}

		return Notification;
	}

	function notificationFactoryProvider() {
		var provider = this;
		provider.environment = 'production'; // Default to production

		provider.$get = [
			'$window',
			'$localStorage',
			'Analytics',
			'HTMLStatusCodes',
			'Localize',
			'NotificationTypes',
			'Notification',
			'$timeout',
			'$rootScope',
			'$log',
			'Noty',
			function(
				$window,
				$localStorage,
				Analytics,
				HTMLStatusCodes,
				Localize,
				NotificationTypes,
				Notification,
				$timeout,
				$rootScope,
				$log,
				Noty
			) {
				// properties
				var notificationTheme = 'notification_factory',
					notificationsQueue = 'notifications';

				Noty.setMaxVisible(1, notificationsQueue);

				var notificationFactory = {};
				notificationFactory.notifications = [];

				// methods
				notificationFactory.show = show;
				notificationFactory.get = get;
				notificationFactory.closeType = closeType;
				notificationFactory.close = close;
				notificationFactory.clear = clear;
				notificationFactory.inactivity = inactivity;
				notificationFactory.connectionError = connectionError;
				notificationFactory.serverError = serverError;
				notificationFactory.alert = alert;
				notificationFactory.error = error;
				notificationFactory.notify = notify;
				notificationFactory.success = success;
				notificationFactory.warning = warning;
				notificationFactory.updateAvailable = updateAvailable;
				notificationFactory.unsupportedBrowser = unsupportedBrowser;

				function getContentTemplate(heading, content) {
					return ['<span class="title"><strong>', heading || '', '</strong></span><br/><span>', content || '', '</span>'].join(
						''
					);
				}

				function getNotificationTemplate(content) {
					return [
						'<div class="noty_body"><div class="symbol"></div><div class="noty_message"><span class="noty_text">',
						content || '',
						'</span>',
						// '<div class="noty_progressbar"></div>',
						'</div></div>'
					].join('');
				}

				function show(id) {
					var notification = notificationFactory.get(id);
					if (!notification.noty) {
						notification.noty = new Noty({
							theme: notificationTheme,
							queue: notificationsQueue,
							layout: 'topCenter',
							closeWith: ['button'],
							animation: {
								open: 'animated fadeInDown',
								close: 'animated fadeOutUp'
							},
							timeout: notification.timeout ? notification.timeout * 1000 : 0,
							text: getContentTemplate(notification.heading, notification.content),
							type: notification.notificationType || NotificationTypes.information
							//progressBar: false // notification.timeout !== undefined && notification.timeout > 0 ? true : false
						})
							.on('onTemplate', function() {
								this.barDom.innerHTML = getNotificationTemplate(this.options.text);
							})
							.on('onShow', function() {
								notification.visible = true;
							})
							.on('onClose', function() {
								notification.visible = false;
							});
					}

					if (!notification.visible && notification.noty) {
						notification.noty.show();
					}
				}

				function get(notificationId) {
					var n = _.find(notificationFactory.notifications, { id: notificationId }) || undefined;
					return n;
				}

				function closeType(notificationType) {
					notificationFactory.notifications = _.reject(notificationFactory.notifications, function(notification) {
						var reject = notification.notificationType === notificationType;
						if (reject && notification.noty) {
							notification.noty.close();
						}
						return reject;
					});
				}

				function close(id) {
					var index = _.findIndex(notificationFactory.notifications, { id: id });
					if (index !== -1) {
						if (notificationFactory.notifications[index].visible && notificationFactory.notifications[index].noty) {
							notificationFactory.notifications[index].noty.close();
						}

						if (notificationFactory.notifications.length === 1) {
							notificationFactory.notifications = [];
						} else {
							notificationFactory.notifications.splice(index, 1);
						}
					}
				}

				function clear(purge) {
					if (purge) {
						// _.forEach(notificationFactory.notifications, function (notification) {
						// 	if (notification.visible) {
						// 		notification.noty.close();
						// 	}
						// });

						Noty.closeAll(notificationsQueue);
						notificationFactory.notifications = [];
					}

					if (notificationFactory.notifications && notificationFactory.notifications.length > 0) {
						var tmp = [];
						_.forEach(notificationFactory.notifications, function(notification) {
							if (!notification.persist) {
								if (notification.visible) {
									notification.noty.close();
								}
							} else {
								tmp.push(notification);
							}
						});
						notificationFactory.notifications = tmp;
					}
				}

				//default set of notifications
				//Notification(notificationType, heading, content, support, closable, persist)
				function inactivity() {
					var heading = Localize.translateInstant('notifications.headings.inactivity');
					var content = Localize.translateInstant('notifications.content.inactivity');

					var notification = new Notification(NotificationTypes.warning, heading, content);
					notification.modal = false;
					notificationFactory.clear(true);

					notificationFactory.notifications.push(notification);
					$timeout(function() {
						notificationFactory.show(notification.id);
					}, 500);
					return notification.id;
				}

				function connectionError(error) {
					var heading = Localize.translateInstant('notifications.headings.connectionError');
					var content = Localize.translateInstant('notifications.content.connectionError');
					var support = Localize.translateInstant('notifications.support.default');
					content += '<br/>' + support;
					var notification = new Notification(NotificationTypes.error, heading, content);

					notification.modal = error && error.modal && error.modal === true ? true : false;
					if (error) {
						$log.error(error);
					}

					var exists = _.find(notificationFactory.notifications, function(item) {
						return item.heading === notification.heading;
					});

					if (exists) {
						notificationFactory.show(exists.id);
						return exists.id;
					} else {
						notificationFactory.notifications.push(notification);
						notificationFactory.show(notification.id);
						return notification.id;
					}
				}

				function serverError(error) {
					var heading = Localize.translateInstant('notifications.headings.serverError');
					var content = Localize.translateInstant('notifications.content.serverError');
					var support = Localize.translateInstant('notifications.support.default');
					content += '<br/>' + support;
					var notification = new Notification(NotificationTypes.error, heading, content);
					notification.modal = error && error.modal && error.modal === true ? true : false;
					notification.persist = false;
					if (error) {
						$log.error(error);
					}

					var exists = _.find(notificationFactory.notifications, function(item) {
						return item.heading === notification.heading;
					});

					if (exists) {
						notificationFactory.show(exists.id);
						return exists.id;
					} else {
						notificationFactory.notifications.push(notification);
						notificationFactory.show(notification.id);
						return notification.id;
					}
				}

				//customizable notifications
				function alert(notificationParams) {
					var notification = new Notification(
						NotificationTypes.alert,
						notificationParams.heading,
						notificationParams.content,
						0,
						notificationParams.persist
					);

					notificationFactory.notifications.push(notification);
					notificationFactory.show(notification.id);
					$log.warn(notification);
					return notification.id;
				}

				//updated to soak up expected connection and server error statuses to keep our error handling code cleaner/simpler
				function error(error, environmentName) {
					// cancelled requests throw which our resources will catch (error.status === -1), no notification needed
					if (!error || (error && error.status && error.status === -1)) {
						return -1;
					}
					environmentName = environmentName || provider.environment;

					if (error.status === 0 || error.status === 408) {
						if (Analytics) {
							Analytics.trackEvent('Error', 'Connection Error', 'Status: ' + error.status, angular.toJson(error, true));
						}
						return notificationFactory.connectionError(error);
					} else if (error.status === 500) {
						if (Analytics) {
							Analytics.trackEvent('Error', 'Server Error', 'Status: ' + error.status, angular.toJson(error, true));
						}
						return notificationFactory.serverError(error);
					}
					$log.error(error);
					if (Analytics) {
						Analytics.trackEvent('Error', 'Internal', 'Status: ' + error.status, angular.toJson(error, true));
					}

					if (environmentName !== 'development' && !error.force) {
						return -1;
					}

					var heading, content, notification, exists;
					if (error.status) {
						var htmlError = _.find(HTMLStatusCodes, { code: error.status });
						if (htmlError && htmlError.value) {
							heading = 'Error: ' + htmlError.value;
						}
					} else {
						heading = Localize.translateInstant('notifications.headings.applicationError');
					}

					heading = error.force ? error.heading || heading : 'Development Only ' + heading;
					content = error.force ? error.content || error.message : Localize.translateInstant('notifications.error.default');
					notification = new Notification(NotificationTypes.error, heading, content);
					exists = _.find(notificationFactory.notifications, function(item) {
						return item.heading === notification.heading;
					});

					if (exists) {
						notificationFactory.show(exists.id);
						return exists.id;
					} else {
						notificationFactory.notifications.push(notification);
						notificationFactory.show(notification.id);
						return notification.id;
					}

					// var notification = new Notification(NotificationTypes.error, heading, content);
					// var exists = _.find(notificationFactory.notifications, function (item) {
					// 	return item.heading === notification.heading;
					// });
					//
					// if (!error.force && exists) {
					// 	//notificationFactory.show(exists.id);
					// 	return exists.id;
					// }
					// else {
					// 	if(exists && exists.id && (exists.id !== notification.id)) {
					// 		notificationFactory.close(exists.id);
					// 	}
					// 	notificationFactory.notifications.push(notification);
					// 	notificationFactory.show(notification.id);
					// 	return notification.id;
					// }
				}

				function notify(notificationParams) {
					var notification = new Notification(
						notificationParams.notificationType || NotificationTypes.information,
						notificationParams.heading,
						notificationParams.content,
						notificationParams.closeAfter || 0,
						notificationParams.persist
					);
					// notification.timeout = notificationParams.closeAfter || 0;

					notificationFactory.notifications.push(notification);
					notificationFactory.show(notification.id);
					return notification.id;
				}

				function success(notificationParams) {
					var notification = new Notification(
						NotificationTypes.success,
						notificationParams.heading,
						notificationParams.content,
						notificationParams.closeAfter || 0,
						notificationParams.persist
					);
					// notification.timeout = notificationParams.closeAfter || 0;

					notificationFactory.notifications.push(notification);
					notificationFactory.show(notification.id);
					return notification.id;
				}

				function warning(notificationParams) {
					var notification = new Notification(
						NotificationTypes.warning,
						notificationParams.heading,
						notificationParams.content,
						notificationParams.closeAfter || 0,
						notificationParams.persist
					);
					// notification.timeout = notificationParams.closeAfter || 0;

					notificationFactory.notifications.push(notification);
					notificationFactory.show(notification.id);
					return notification.id;
				}

				function updateAvailable() {
					var notification = new Notification(
						NotificationTypes.information,
						Localize.translateInstant('notifications.headings.updateAvailable'),
						Localize.translateInstant('notifications.content.updateAvailable'),
						undefined
					);
					notification.timeout = 0;

					if (!notification.noty) {
						notification.noty = new Noty({
							theme: notificationTheme,
							queue: notificationsQueue,
							layout: 'topCenter',
							closeWith: ['button'],
							animation: {
								open: 'animated fadeInDown',
								close: 'animated fadeOutUp'
							},
							timeout: 0,
							text: getContentTemplate(notification.heading, notification.content),
							type: notification.notificationType || NotificationTypes.information
						})
							.on('onTemplate', function() {
								this.barDom.innerHTML = getNotificationTemplate(this.options.text);
							})
							.on('onShow', function() {
								notification.visible = true;
							})
							.on('onClose', function() {
								notification.visible = false;
								$localStorage.updated = true;
								var tmp = $localStorage.updated;
								// $timeout(function () {
								$window.location.href = $window.location.href;
								// $window.location.reload(true); // this breaks the update refresh
								// }, 0);
							});
					}

					if (!notification.visible && notification.noty) {
						notification.noty.show();
					}
					return notification.id;
				}

				function unsupportedBrowser() {
					new Noty({
						theme: notificationTheme,
						layout: 'top',
						closeWith: ['button'],
						timeout: 0,
						text: [
							'<div class="unsupported_browser">',
							'<div class="constrainer">',
							'<div class="summary">',
							'<p>',
							Localize.translateInstant('notifications.unsupportedBrowser.summary'),
							'</p>',
							'</div>',
							'<div class="details slide-collapsed">',
							'<h2>',
							Localize.translateInstant('notifications.unsupportedBrowser.details.header'),
							'</h2>',
							'<p>',
							Localize.translateInstant('notifications.unsupportedBrowser.details.content'),
							'</p>',
							'<div class="browsers">',
							'<ul>',
							'<li class="chrome">',
							'<a href="http://www.google.com/chrome" target="_blank">Chrome</a>',
							'</li>',
							'<li class="firefox">',
							'<a href="http://www.mozilla.org/firefox" target="_blank">Firefox</a>',
							'</li>',
							'<li class="safari">',
							'<a href="http://www.apple.com/safari" target="_blank">Safari</a>',
							'</li>',
							'<li class="internet-explorer">',
							'<a href="http://www.microsoft.com/ie" target="_blank">Internet Explorer</a>',
							'</li>',
							'</ul>',
							'</div>',
							'</div>',
							'</div>',
							'</div>'
						].join('\n'),
						modal: true,
						type: NotificationTypes.alert,
						animation: {
							open: 'animated fadeInDown',
							close: 'animated fadeOutUp'
						}
					}).show();
				}

				$rootScope.$on('NotificationFactory.alert', function(event, params) {
					notificationFactory.alert(params);
				});

				$rootScope.$on('NotificationFactory.error', function(event, params) {
					notificationFactory.error(params);
				});

				$rootScope.$on('NotificationFactory.clear', function(event, params) {
					notificationFactory.clear(params && params.purge && params.purge === true);
				});

				return notificationFactory;
			}
		];
	}

	notificationOverlayDirective.$inject = ['$timeout', 'NotificationFactory'];

	function notificationOverlayDirective($timeout, NotificationFactory) {
		var directive = {
			restrict: 'A',
			scope: {
				busy: '=ilNotificationOverlay',
				notificationId: '='
			},
			link: function(scope, elem, attrs) {
				scope.messageText = '';
				var timeout = null,
					borders = attrs.borders && attrs.borders === 'true',
					bordered = attrs.bordered && attrs.bordered === 'true',
					transparent = attrs.transparent && attrs.transparent === 'true',
					small = attrs.small && attrs.small === 'true',
					large = attrs.large && attrs.large === 'true',
					top = attrs.topPercent && attrs.topPercent.length ? attrs.topPercent : undefined;

				var overlay = angular.element(
					[
						'<section class="notifications">\n',
						'<section class="overlay" ng-click="$event.stopPropagation()">\n',
						'<section class="graphic"></section>\n',
						'</section>\n',
						'</section>\n'
					].join('')
				);

				elem.css('position', 'relative');
				elem.prepend(overlay);

				var customBackground = overlay.find('.overlay');
				if (borders === true) {
					customBackground.addClass('borders');
				}
				if (bordered === true) {
					customBackground.addClass('bordered');
				}
				if (transparent === true) {
					customBackground.addClass('transparent');
				}

				if (small === true) {
					customBackground.addClass('small');
				} else if (large === true) {
					customBackground.addClass('large');
				} else if (!small && !large) {
					customBackground.addClass('medium');
				}

				var graphic = overlay.find('.graphic');
				graphic.bind('click', function(event) {
					event.stopPropagation();
					NotificationFactory.show(scope.notificationId);
				});

				scope.$watch(
					function() {
						return scope.busy;
					},
					function(value) {
						var background = overlay.find('.overlay');
						if (value && value === true && !background.hasClass('busy')) {
							background.addClass('busy');
						} else {
							background.removeClass('busy');
						}
					},
					true
				);

				scope.$watch(
					function() {
						return scope.notificationId;
					},
					function(value) {
						if (value && !overlay.hasClass('active') && timeout === null) {
							timeout = $timeout(function() {
								var n = NotificationFactory.get(value);
								if (n) {
									scope.messageText = n.content;
								}

								var background = overlay.find('.overlay');
								if (attrs['remove-padding']) {
									var padding = {
										left: elem.css('padding-left'),
										right: elem.css('padding-right'),
										top: elem.css('padding-top'),
										bottom: elem.css('padding-bottom')
									};
									background.css('left', padding.left === '0px' ? padding.left : '-' + padding.left);
									background.css('right', padding.right === '0px' ? padding.right : '-' + padding.right);
									background.css('top', padding.top === '0px' ? padding.top : '-' + padding.top);
									background.css('bottom', padding.bottom === '0px' ? padding.bottom : '-' + padding.bottom);
								}

								if (attrs['min-height']) {
									background.css('min-height', attrs['min-height']);
									background.css('height', attrs['min-height']);
								}

								if (transparent === true) {
									background.css('background-color', 'transparent');
								}

								if (top) {
									var graphic = background.find('.graphic');
									if (graphic) {
										graphic.css('top', top + '%');
									}
								}

								background.addClass('active');
								timeout = null;
							}, 400);
						} else if (!value) {
							scope.messageText = '';
							$timeout.cancel(timeout);
							timeout = null;
							var background = overlay.find('.overlay');
							background.removeClass('active');
							background.removeClass('selected');
						}
					}
				);
			}
		};

		return directive;
	}

	notificationCloseDirective.$inject = ['$timeout', 'NotificationFactory'];

	function notificationCloseDirective($timeout, NotificationFactory) {
		var directive = {
			restrict: 'A',
			scope: {
				notificationId: 'ilNotificationClose'
			},
			link: function(scope, elem, attrs) {
				var closeNotification = function() {
					this.notificationId && this.notificationId.length
						? NotificationFactory.close(this.notificationId)
						: NotificationFactory.closeAll();
				};

				element.bind('click', closeNotification);

				element.on('$destroy', function() {
					element.off('click', closeNotification);
				});
			}
		};

		return directive;
	}
})();
