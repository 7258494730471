/**
 * Directive to workaround auto-complete bug in AngularJS forms
 *
 * See: http://victorblog.com/2014/01/12/fixing-autocomplete-autofill-on-angularjs-form-submit/
 */
angular
	.module('ui.form.autocompletefix', [])

	.directive('ilFormAutoCompleteFix', function() {
		return {
			restrict: 'A',
			link: function(scope, elem, attr) {
				// Fixes Chrome bug: https://groups.google.com/forum/#!topic/angular/6NlucSskQjY
				elem.prop('method', 'POST');

				// Fix auto-complete issues where Angular doesn't know about auto-completed inputs

				// This approach triggers validators before calling the submit function.
				// Doesn't work for immediately displaying styles on VALID inputs though.

				if (attr.ngSubmit) {
					setTimeout(function() {
						elem.unbind('submit').bind('submit', function(e) {
							e.preventDefault();
							elem.find('input, textarea, select')
								.trigger('input')
								.trigger('change')
								.trigger('keydown');
							scope.$apply(attr.ngSubmit);
						});
					}, 0);
				}
			}
		};
	})

	.directive('ilFormFieldAutoCompleteFix', [
		'$timeout',
		function($timeout) {
			return {
				restrict: 'A',
				require: 'ngModel',
				link: function(scope, elem, attr, ctrl) {
					// Fix auto-complete issues where Angular doesn't know about auto-completed inputs

					// This approach is a little more kludgy than the above approach, but should work
					// for immediately displaying styles on VALID inputs.

					var origVal = elem.val();
					$timeout(function() {
						var newVal = elem.val();
						if (ctrl.$pristine && origVal !== newVal) {
							ctrl.$setViewValue(newVal);
						}
					}, 500);
				}
			};
		}
	]);
