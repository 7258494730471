(function() {
	angular.module('ui.data_filter.controller', ['ui.data_filter.constants']).controller('ilDataFilterController', controller);

	controller.$inject = ['$timeout', 'ilDataFilterModes'];

	function controller($timeout, ilDataFilterModes) {
		var ctrl = this;
		ctrl.defaultOptionIndex = null;
		ctrl.modes = ilDataFilterModes;
		ctrl.mode = null;
		ctrl.disabled = false;
		ctrl.selected = {};
		ctrl.options = [];
		ctrl.label = '';
		ctrl.showDrop = false;
		ctrl.selectedItems = [];
		ctrl.btnText = 'All';
		ctrl.selectAll = false;
		ctrl.autoHeight = false;
		ctrl.selectAllLabel = 'Select All';

		ctrl.directiveScope = undefined;
		ctrl.directiveElement = undefined;
		ctrl.directiveAttrs = undefined;
		ctrl.directiveNgModel = undefined;

		ctrl.add = add;
		ctrl.remove = remove;
		ctrl.select = select;
		ctrl.toggleShowDrop = toggleShowDrop;
		ctrl.toggleSelectAll = toggleSelectAll;
		ctrl.onSelectAllChange = onSelectAllChange;
		ctrl.init = init;
		ctrl.reset = reset;
		ctrl.prepareOptions = prepareOptions;
		ctrl.renderModel = renderModel;
		ctrl.onSelectedValueChange = onSelectedValueChange;
		ctrl.onOptionsChange = onOptionsChange;
		ctrl.onLabelChange = onLabelChange;
		ctrl.onDefaultChange = onDefaultChange;
		ctrl.onButtonTextChange = onButtonTextChange;

		function init(scope, element, attrs, ngModel) {
			ctrl.directiveScope = scope;
			ctrl.directiveElement = element;
			ctrl.directiveAttrs = attrs;
			ctrl.directiveNgModel = ngModel;

			ctrl.selected = ctrl.selected === null ? {} : ctrl.selected;
			// set defaults
			if (ctrl.defaultOptionIndex !== null && ctrl.mode === ctrl.modes.single) {
				$timeout(function() {
					ctrl.add(ctrl.options[ctrl.defaultOptionIndex]);
				}, 500);
			}
		}

		function reset() {
			if ((ctrl.mode = ctrl.modes.multiple)) {
				// set all selection to false;
				if (ctrl.selectedItems.length) {
					for (var i = 0; i < ctrl.selectedItems.length; ++i) {
						ctrl.selectedItems[i] = false;
					}
				}
			}
			ctrl.selected = {};
		}

		function prepareOptions(options) {
			ctrl.options = [];
			angular.forEach(options, function(option) {
				if (!angular.isDefined(option.displayText)) {
					option.displayText = option.text;
				}
				ctrl.options.push(option);
			});
		}

		function onSelectedValueChange(n, o) {
			if (n !== o) {
				ctrl.renderModel(ctrl.directiveScope, ctrl.directiveNgModel);
			}
		}

		function onOptionsChange(n, o) {
			if (n !== o) {
				ctrl.prepareOptions(n);
			}
		}

		function onLabelChange(n, o) {
			if (n !== o) {
				ctrl.label = n;
			}
		}

		function onButtonTextChange(n, o) {
			if (angular.isDefined(n)) {
				ctrl.btnText = n;
			}
		}

		function onDefaultChange(n, o) {
			if (angular.isDefined(n)) {
				if (angular.isDefined(n.value)) {
					ctrl.reset();
					ctrl.select(n);
				} else {
					ctrl.toggleSelectAll();
				}
			}
		}

		function renderModel(scope, ngModel) {
			$timeout(function() {
				ngModel.$setViewValue(ctrl.selected);
				if (ctrl.selected) {
					ngModel.$setDirty();
				}

				scope.change(); // run this after all values have been updated in the view
			});
		}

		function toggleSelectAll() {
			ctrl.selectAll = !ctrl.selectAll;
			ctrl.onSelectAllChange();
		}

		function onSelectAllChange() {
			ctrl.selected = ctrl.selected === null ? {} : ctrl.selected;
			for (var i = 0; i < ctrl.options.length; ++i) {
				var option = ctrl.options[i];
				if (this.selectAll) {
					ctrl.add(option);
				} else {
					ctrl.remove(option);
				}
			}
			ctrl.btnText = ctrl.selectAll ? 'All' : ctrl.selectAll && _getSelectedCount() > 0 ? _getSelectedCount() + ' selected' : 'None';
		}

		function select(option) {
			if (angular.isDefined(option) && angular.isDefined(option.text) && angular.isDefined(option.value)) {
				ctrl.selected = ctrl.selected === null ? {} : ctrl.mode === ctrl.modes.single ? {} : ctrl.selected;
				if (!ctrl.selected[option.value]) {
					ctrl.add(option);
				} else {
					ctrl.remove(option);
				}
				if (ctrl.mode === ctrl.modes.single) {
					ctrl.toggleShowDrop();
				}

				var selectedCt = _getSelectedCount();

				if (ctrl.mode === ctrl.modes.multiple) {
					ctrl.selectAll = false;
					ctrl.btnText = selectedCt === ctrl.options.length - 1 ? 'All' : selectedCt + ' selected';
				}
			}
		}

		function add(option) {
			ctrl.selected = ctrl.selected === null ? {} : ctrl.selected;
			var index = _getOptionIndex(option);
			ctrl.selectedItems[index] = true;
			ctrl.selected[option.value] = option.text;
			if (ctrl.mode === ctrl.modes.single) {
				ctrl.btnText = option.text;
			}
		}

		function remove(option) {
			ctrl.selected = ctrl.selected === null ? {} : ctrl.selected;
			var index = _getOptionIndex(option);
			ctrl.selectedItems[index] = false;
			delete ctrl.selected[option.value];
		}

		function toggleShowDrop(action) {
			switch (action) {
				case 'show':
					ctrl.showDrop = true;
					break;
				case 'hide':
					ctrl.showDrop = false;
					break;
				default:
					ctrl.showDrop = !ctrl.showDrop;
			}
		}

		function _getOptionIndex(option) {
			for (var i = 0; i < ctrl.options.length; ++i) {
				if (ctrl.options[i].value === option.value) {
					return i;
				}
			}
		}

		function _getSelectedCount() {
			var selectedCt = 0;
			if (angular.isDefined(ctrl.selectedItems) && ctrl.selectedItems.length) {
				for (var i = 0; i < ctrl.selectedItems.length; ++i) {
					if (ctrl.selectedItems[i]) {
						selectedCt++;
					}
				}
			}
			return selectedCt;
		}
	}
})();
