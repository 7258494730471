(function() {
	angular
		.module('ui.manager.multiselect', ['ui.directives.scrollMore', 'naturalSort', 'api.manager', 'ui.notifications', 'ui.loader'])
		.directive('ilManagerMultiselect', directive)
		.controller('ManagerMultiselectController', controller);

	directive.$inject = ['$timeout', '$document'];

	function directive($timeout, $document) {
		return {
			restrict: 'A',
			replace: true,
			scope: {
				selectedItems: '=?',
				singleSelect: '=',
				sourceIds: '=',
				type: '@',
				placeholderText: '@',
				array: '=',
				closeOnClean: '='
			},
			templateUrl: 'ui/manager.multiselect/manager.multiselect.tpl.html',
			controller: 'ManagerMultiselectController',
			controllerAs: 'model',
			require: 'ngModel',
			link: function(scope, element, attrs, ngModelctrl) {
				var searchInput;

				scope.disabled = false;
				attrs.$observe(
					'disabled',
					function(value) {
						scope.disabled = value;
					},
					true
				);

				$timeout(function() {
					ngModelctrl.$setPristine();
				});

				ngModelctrl.$isEmpty = function(value) {
					return !(value && value.length);
				};

				ngModelctrl.$render = function() {
					scope.selectedIds = ngModelctrl.$viewValue;
				};

				scope.$watchCollection(
					'selectedItems',
					function(value) {
						if (!angular.equals(scope.selectedIds, _.map(value, 'id'))) {
							scope.selectedIds = _.map(value, 'id');
						}

						if (!angular.equals(scope.selectedIds, ngModelctrl.$viewValue)) {
							ngModelctrl.rawValue = value;
							ngModelctrl.$setViewValue(scope.selectedIds);
						}
					},
					true
				);

				scope.focusFilter = function(toggle) {
					$timeout(function() {
						searchInput = element.find('input[type="search"]');
						searchInput.focus();
						if (toggle) {
							scope.model.open = !scope.model.open;
						}
					}, 0);
				};

				//simplest approach - if click is not within the element client rect, including drop down content, then close drop down
				function isClickInElement(event) {
					var elementRect = element[0].getBoundingClientRect();
					var menuRect = element.find('.dropdown_menu')[0].getBoundingClientRect();
					var clicked =
						event.clientX > elementRect.left &&
						event.clientX < elementRect.right &&
						event.clientY > elementRect.top &&
						event.clientY < menuRect.bottom;
					return clicked;
				}

				//close on click outside of menu
				function onClick(event) {
					if (!isClickInElement(event)) {
						scope.close();
					}
				}

				$document.on('click', onClick);

				element.on('$destroy', function() {
					$document.off('click', onClick);
				});
			}
		};
	}

	controller.$inject = [
		'$scope',
		'$rootScope',
		'$timeout',
		'$filter',
		'$document',
		'$q',
		'$sce',
		'NotificationFactory',
		'Organization',
		'Class',
		'User',
		'Student'
	];

	function controller(
		$scope,
		$rootScope,
		$timeout,
		$filter,
		$document,
		$q,
		$sce,
		NotificationFactory,
		Organization,
		Class,
		User,
		Student
	) {
		var naturalSort = function(array, orderBy) {
			return $filter('orderBy')(array, $rootScope.natural(orderBy));
		};

		$scope.selectedItems = [];

		var model = this;

		model.busy = false;
		model.tooltTipText = '';
		model.entityTypeIcon = '';
		model.focusedItemIndex = 0;
		model.translations = {};
		model.editing = true;
		model.open = false;
		model.disabled = false;
		model.selectedIdsPromise = null;

		$scope.close = function() {
			// $scope.$apply(function () {
			// 	model.open = false;
			// });
			$timeout(function() {
				model.open = false;
			}, 0);
		};

		model.items = [];
		model.totalCount = 0;
		model.search = '';

		model.isSelected = isSelected;
		model.selectItem = selectItem;
		model.unSelectItem = unSelectItem;
		model.getPlaceholder = getPlaceholder;
		model.showNoResults = showNoResults;
		model.removeInvalidSelectedItems = removeInvalidSelectedItems;

		function allItemsSelected() {
			if (!model.items || !model.items.length) {
				return false;
			}
			var selArray = _.reject(model.items, function(item) {
				return !isSelected(item);
			});
			return selArray && selArray.length === model.items.length;
		}

		function showNoResults() {
			return !model.items.length || allItemsSelected();
		}

		function getPlaceholder() {
			if (model.totalCount === 0) {
				return 'No Results';
			} else {
				return model.translations.search;
				// return $scope.singleSelect && $scope.singleSelect === true ? model.translations.select : model.translations.selectPlural;
			}
		}

		function addParentNameParam(params) {
			params = params || {};
			params.additionalFields = params.additionalFields || [];
			params.additionalFields.push('parentName');
			return params;
		}

		model.selectTypes = {
			array: {
				translations: {
					search: 'Search',
					select: $scope.placeholderText && $scope.placeholderText.length ? $scope.placeholderText : 'Select an item',
					selectPlural: $scope.placeholderText && $scope.placeholderText.length ? $scope.placeholderText : 'Select items'
				},
				query: function(params) {
					return $q.when($scope.array);
				},
				get: function(id) {
					return _.find($scope.array, function(item) {
						return item.id === id;
					});
				},
				sortField: 'displayText',
				displayText: function(item) {
					return item.displayText || item.name;
				}
			},
			organizations: {
				translations: {
					// enitityType: "Organizations",
					// loading: 'Loading Organizations',
					search: 'Find an Organization',
					select: 'Select an Organization',
					selectPlural: 'Select Organizations'
				},
				query: function(params) {
					return Organization.paged(addParentNameParam(params));
				},
				get: function(id) {
					return Organization.get(id, addParentNameParam());
				},
				icon: '<span data-icon="org" class="icon"></span>',
				sortField: 'name',
				displayText: function(item) {
					return item.name;
				}
			},
			groups: {
				translations: {
					// enitityType: "Groups",
					// loading: 'Loading Groups',
					search: 'Find a Group',
					select: 'Select a Group',
					selectPlural: 'Select Groups'
				},
				query: function(params) {
					return Class.paged(addParentNameParam(params));
				},
				get: function(id) {
					return Class.get(id, addParentNameParam());
				},
				icon: '<span data-icon="class" class="icon"></span>',
				sortField: 'name',
				displayText: function(item) {
					return item.name;
				}
			},
			users: {
				translations: {
					// enitityType: "Staff",
					// loading: 'Loading Staff',
					search: 'Find Staff',
					select: 'Select Staff',
					selectPlural: 'Select Staff'
				},
				query: function(params) {
					return User.paged(addParentNameParam(params));
				},
				get: function(id) {
					return User.get(id, addParentNameParam());
				},
				icon: '<span data-icon="user" class="icon"></span>',
				sortField: 'displayText',
				displayText: function(item) {
					return item.lastName + ', ' + item.firstName;
				}
			},
			students: {
				translations: {
					// enitityType: "Students",
					// loading: 'Loading Students',
					search: 'Find a Student',
					select: 'Select a Student',
					selectPlural: 'Select Students'
				},
				query: function(params) {
					return Student.paged(addParentNameParam(params));
				},
				get: function(id) {
					return Student.get(id, addParentNameParam());
				},
				icon: '<span data-icon="student" class="icon"></span>',
				sortField: 'displayText',
				displayText: function(item) {
					return item.lastName + ', ' + item.firstName;
				}
			},
			//'mediaServers': {
			//	translations: {
			//		enitityType: "Media Servers",
			//		loading: 'Loading Media Servers',
			//		search: "Find a Media Server",
			//		select: "Select a Media Server",
			//		selectPlural: "Select Media Servers"
			//	},
			//	query: function (offset, count, search) {
			//		var promises = [];
			//		if ($scope.sourceIds && $scope.sourceIds.length && $scope.sourceIds[0] && $scope.sourceIds[0].length) {
			//			angular.forEach($scope.sourceIds, function (id) {
			//				promises.push(Organization.mediaServers(id));
			//			});
			//		}
			//		return promises.length > 0 ? $q.all(promises) : $q.when();
			//	},
			//	icon: '<span data-icon="org" class="icon"></span>',
			//	sortField: 'MachineName',
			//	displayText: function (item) { return item.MachineName; }
			//},
			orgsFromOrgs: {
				aggregate: true,
				translations: {
					// enitityType: "Organizations",
					// loading: 'Loading Organizations',
					search: 'Find an Organization',
					select: 'Select an Organization',
					selectPlural: 'Select Organizations'
				},
				query: function(params) {
					var promises = [];
					if ($scope.sourceIds && $scope.sourceIds.length && $scope.sourceIds[0] && $scope.sourceIds[0].length) {
						angular.forEach($scope.sourceIds, function(id) {
							promises.push(Organization.children(id, addParentNameParam(params)));
						});
					}
					return promises.length > 0 ? $q.all(promises) : $q.when();
				},
				get: function(id) {
					return Organization.get(id, addParentNameParam());
				},
				icon: '<span data-icon="org" class="icon"></span>',
				sortField: 'name',
				displayText: function(item) {
					return item.name;
				}
			},
			groupsFromOrgs: {
				aggregate: true,
				translations: {
					// enitityType: "Groups",
					// loading: 'Loading Groups',
					search: 'Find a Group',
					select: 'Select a Group',
					selectPlural: 'Select Groups'
				},
				query: function(params) {
					var promises = [];
					if ($scope.sourceIds && $scope.sourceIds.length && $scope.sourceIds[0] && $scope.sourceIds[0].length) {
						angular.forEach($scope.sourceIds, function(id) {
							promises.push(Organization.classes(id, addParentNameParam(params)));
						});
					}
					return promises.length > 0 ? $q.all(promises) : $q.when();
				},
				get: function(id) {
					return Class.get(id, addParentNameParam());
				},
				icon: '<span data-icon="class" class="icon"></span>',
				sortField: 'name',
				displayText: function(item) {
					return item.name;
				}
			},
			usersFromOrgs: {
				aggregate: true,
				translations: {
					// enitityType: "Staff",
					// loading: 'Loading Staff',
					search: 'Find User',
					select: 'Select User',
					selectPlural: 'Select Users'
				},
				query: function(params) {
					var promises = [];
					if ($scope.sourceIds && $scope.sourceIds.length && $scope.sourceIds[0] && $scope.sourceIds[0].length) {
						angular.forEach($scope.sourceIds, function(id) {
							promises.push(Organization.users(id, addParentNameParam(params)));
						});
					}
					return promises.length > 0 ? $q.all(promises) : $q.when();
				},
				get: function(id) {
					return User.get(id, addParentNameParam());
				},
				icon: '<span data-icon="user" class="icon"></span>',
				sortField: 'displayText',
				displayText: function(item) {
					return item.lastName + ', ' + item.firstName;
				}
			},
			groupsFromUser: {
				aggregate: true,
				translations: {
					// enitityType: "Groups",
					// loading: 'Loading Groups',
					search: 'Find a Group',
					select: 'Select a Group',
					selectPlural: 'Select Groups'
				},
				query: function(params) {
					var promises = [];
					if ($scope.sourceIds && $scope.sourceIds.length && $scope.sourceIds[0] && $scope.sourceIds[0].length) {
						promises.push(User.classes($scope.sourceIds[0], addParentNameParam(params)));
					}
					return promises.length > 0 ? $q.all(promises) : $q.when();
				},
				get: function(id) {
					return Class.get(id, addParentNameParam());
				},
				icon: '<span data-icon="class" class="icon"></span>',
				sortField: 'name',
				displayText: function(item) {
					return item.name;
				}
			},
			studentsFromUser: {
				translations: {
					search: 'Find a Student',
					select: 'Select a Student',
					selectPlural: 'Select Students'
				},
				query: function(params) {
					if ($scope.sourceIds && $scope.sourceIds.length && $scope.sourceIds[0] && $scope.sourceIds[0].length) {
						return User.assignedStudents($scope.sourceIds[0], addParentNameParam(params));
					}
					return $q.when();
				},
				get: function(id) {
					return Student.get(id, addParentNameParam());
				},
				icon: '<span data-icon="class" class="icon"></span>',
				sortField: 'displayText',
				displayText: function(item) {
					return item.lastName + ', ' + item.firstName;
				}
			},
			studentsFromGroup: {
				translations: {
					search: 'Find a Student',
					select: 'Select a Student',
					selectPlural: 'Select Students'
				},
				query: function(params) {
					if ($scope.sourceIds && $scope.sourceIds.length && $scope.sourceIds[0] && $scope.sourceIds[0].length) {
						return Class.students($scope.sourceIds[0], addParentNameParam(params));
					}
					return $q.when();
				},
				get: function(id) {
					return Student.get(id, addParentNameParam());
				},
				icon: '<span data-icon="class" class="icon"></span>',
				sortField: 'displayText',
				displayText: function(item) {
					return item.lastName + ', ' + item.firstName;
				}
			},
			districts: {
				translations: {
					search: 'Find a District',
					select: 'Select a District',
					selectPlural: 'Select Districts'
				},
				query: function(params) {
					return Organization.districts(undefined, params);
				},
				get: function(id) {
					return Organization.get(id);
				},
				icon: '<span data-icon="org" class="icon"></span>',
				sortField: 'displayText',
				displayText: function(item) {
					return item.name;
				}
			}
		};

		$scope.openMenu = function() {
			if ($scope.disabled) {
				return;
			}
			model.focusedItemIndex = -1;
			model.open = true;
			$scope.focusFilter();
		};

		function updateTypeDetails() {
			if (!model.selectTypes[$scope.type]) {
				NotificationFactory.error({ message: 'ilManagerMultiselect: Invalid type: ' + $scope.type });
				return;
			}

			model.translations = model.selectTypes[$scope.type]
				? model.selectTypes[$scope.type].translations
				: {
						selectAll: 'Select all',
						selectNone: 'Clear Selected',
						reset: 'Undo all',
						search: 'Search',
						nothingSelected: 'None Selected'
				  };

			model.sortField = model.selectTypes[$scope.type].sortField;
			if (model.selectTypes[$scope.type] && model.selectTypes[$scope.type].icon) {
				model.entityTypeIcon = $sce.trustAsHtml(model.selectTypes[$scope.type].icon);
			}
		}

		var index = 0;
		model.getMoreItems = function(openMenu) {
			if (!$scope.type || model.busy || !model.selectTypes[$scope.type]) {
				// NotificationFactory.error({ message: 'ilManagerMultiselect: getMoreItems() > Invalid type: ' + $scope.type });
				return;
			}

			model.busy = true;
			// if (!$rootScope.$$phfocusFilterase) {
			// 	$scope.$apply();
			// }

			updateTypeDetails();

			model.selectTypes[$scope.type]
				.query({
					limit: 100,
					offset: index,
					search: model.search
				})
				.then(function(results) {
					if (model.selectTypes[$scope.type].aggregate && model.selectTypes[$scope.type].aggregate === true) {
						//multiple promises are being returned
						if (results && results.length > 0) {
							angular.forEach(results, function(result) {
								if (result && result.items && result.items.length > 0) {
									index += 100;
									model.totalCount = result.totalCount;
									angular.forEach(result.items, function(item) {
										item.displayText = model.selectTypes[$scope.type].displayText(item);
										model.items.push(item);
									});
								}
							});
							model.items = naturalSort(
								_.uniqWith(model.items, function(arrVal, othVal) {
									return arrVal.id === othVal.id;
								}),
								model.selectTypes[$scope.type].sortField
							);
						}
					} else {
						if (results && results.items && results.items.length > 0) {
							index += 100;
							model.totalCount = results.totalCount;

							if ($scope.type === 'array') {
								var expr = model.search && model.search.length ? new RegExp(model.search, 'igm') : undefined;
								model.items = expr
									? _.filter(results.items, function(item) {
											return (
												(item.displayText && item.displayText.match(expr)) || (item.name && item.name.match(expr))
											);
									  })
									: results.items;
							} else {
								angular.forEach(results.items, function(item) {
									item.displayText = model.selectTypes[$scope.type].displayText(item);
									model.items.push(item);
								});
								model.items = naturalSort(
									_.uniqWith(model.items, function(arrVal, othVal) {
										return arrVal.id === othVal.id;
									}),
									model.selectTypes[$scope.type].sortField
								);
							}
						}
					}
				})
				['catch'](function(error) {
					NotificationFactory.error(error);
				})
				['finally'](function() {
					model.busy = false;

					if (model.open === true) {
						$scope.focusFilter();
					}

					if (openMenu === true) {
						model.open = true;
					}
				});
		};

		model.getMoreItems(); //initial load

		var searchHandle;

		function getSearchItems() {
			if (searchHandle) {
				$timeout.cancel(searchHandle);
			}
			searchHandle = $timeout(function() {
				index = model.totalCount = 0;
				model.items = [];
				model.getMoreItems(true);
			}, 0);
		}

		model.debouncedSearch = _.debounce(getSearchItems, 300);

		function isSelected(item) {
			return _.find($scope.selectedItems, { id: item.id }) !== undefined;
		}

		function selectItem(item) {
			if ($scope.singleSelect && $scope.singleSelect === true) {
				$scope.close();
				$scope.selectedItems = [item];
			} else {
				$scope.selectedItems.push(item);
				$scope.selectedItems = naturalSort($scope.selectedItems, model.selectTypes[$scope.type].sortField);
			}
		}

		function unSelectItem(item) {
			if ($scope.disabled === true) {
				return;
			}

			if ($scope.singleSelect && $scope.singleSelect === true) {
				$scope.selectedItems = [];
			} else {
				var index = _.indexOf($scope.selectedItems, item);
				if (index !== -1) {
					$scope.selectedItems.splice(index, 1);
				}
			}
			if ($scope.closeOnClean) {
				$scope.close();
			}
		}

		$scope.$watch(
			'type',
			function(value, oldValue) {
				if (value && value !== oldValue) {
					$scope.selectedItems = [];
					index = model.totalCount = 0;
					model.items = [];
					model.search = '';
					model.getMoreItems();
				}
			},
			true
		);

		if ($scope.type === 'array') {
			$scope.$watch(
				'array',
				function(value, oldValue) {
					if (value && value !== oldValue) {
						$scope.selectedItems = [];
						index = model.totalCount = 0;
						model.items = [];
						model.search = '';
						model.getMoreItems();
					}
				},
				true
			);
		}

		$scope.$on('manager.multiselect.clear.search', function(event) {
			model.search = '';
			model.getMoreItems();
		});

		//select the items by provided ids
		$scope.$watchCollection(
			'selectedIds',
			function(value) {
				if (value && !_.isEmpty(value)) {
					if (
						!$scope.selectedItems ||
						!$scope.selectedItems.length ||
						!angular.equals(value, _.map($scope.selectedItems, 'id'))
					) {
						// If there isn't an outstanding request
						if (!model.selectedIdsPromise) {
							switch ($scope.type) {
								case 'orgsFromOrgs':
								case 'organizations':
									model.selectedIdsPromise = Organization.orgsById(value);
									break;

								case 'groupsFromOrgs':
								case 'groupsFromUser':
								case 'groups':
									model.selectedIdsPromise = Class.classesById(value);
									break;

								case 'usersFromOrgs':
								case 'users':
									model.selectedIdsPromise = User.usersById(value);
									break;

								// case 'studentsFromOrgs':
								case 'studentsFromUser':
								case 'students':
									model.selectedIdsPromise = Student.studentsById(value);
									break;
							}

							if (model.selectedIdsPromise !== null) {
								model.selectedIdsPromise
									.then(function(results) {
										if (results && results.items && results.items.length) {
											$scope.selectedItems = [];
											angular.forEach(results.items, function(item) {
												item.displayText = model.selectTypes[$scope.type].displayText(item);
												$scope.selectedItems.push(item);
											});
											$scope.selectedItems = naturalSort(
												$scope.selectedItems,
												model.selectTypes[$scope.type].sortField
											);
										}
									})
									.finally(function() {
										model.selectedIdsPromise = null;
									});
							}
						}
					}
				} else {
					// If there isn't an outstanding request
					if (!model.selectedIdsPromise) {
						$scope.selectedItems = [];
					}
				}
			},
			true
		); // we don't want to watch this every cycle, just the initial value

		//reload the list when source ids have changed
		$scope.$watch(
			'sourceIds',
			function(value, oldValue) {
				if (!angular.equals(value, oldValue) && $scope.type) {
					index = model.totalCount = 0;
					model.items = [];
					$scope.selectedItems = !value || value.length === 0 ? [] : model.removeInvalidSelectedItems(value, $scope.type);
					model.getMoreItems();
				}
			},
			true
		);

		function removeInvalidSelectedItems(sourceIds, type) {
			if (type === 'groupsFromOrgs') {
				var filteredItems = _.filter($scope.selectedItems, function(item) {
					// if the groups orgId is no longer one of the source ids, remove it
					if (item.organizationId && sourceIds.indexOf(item.organizationId) === -1) {
						return false;
					}
					return true;
				});
				return filteredItems;
			} else {
				return $scope.selectedItems;
			}
		}

		var debounce;

		function onKeyDown(event) {
			switch (event.which) {
				case 13: //enter
					//do nothing if the drop down is not showing
					if (!model.open) {
						break;
					}

					$timeout.cancel(debounce);
					debounce = $timeout(function() {
						//select item
						model.focusedItemIndex !== -1 ? model.selectItem(model.items[model.focusedItemIndex]) : angular.noop();
					}, 10);

					break;

				//case 8://backspace
				//	//keep the page from navigating back
				//	var rx = /INPUT|SELECT|TEXTAREA/i;
				//	if (!rx.test(event.target.tagName) || event.target.disabled || event.target.readOnly) {
				//		event.preventDefault();
				//	}
				//
				//	$timeout.cancel(debounce);
				//	debounce = $timeout(function () {
				//		//delete search char - handled by input
				//		if (model.search.length > 0) {
				//			model.search = model.search.slice(0, -1);
				//		}
				//			//unselect the last tag if no search char to delete
				//		else if (model.search.length === 0 && $scope.selectedItems.length) {
				//			model.unSelectItem($scope.selectedItems[$scope.selectedItems.length - 1]);
				//		}
				//
				//	}, 10);
				//
				//	return false;

				case 27: //escape
					//model.search = '';
					model.open = false;
					break;

				case 38: //up arrow
					//do nothing if the drop down is not showing
					if (!model.open) {
						break;
					}

					$timeout.cancel(debounce);
					debounce = $timeout(function() {
						//up 1 item
						if (model.focusedItemIndex > 0) {
							model.focusedItemIndex--;
						}
					}, 10);

					return false;

				case 40: //down arrow
					//do nothing if the drop down is not showing
					if (!model.open) {
						break;
					}

					$timeout.cancel(debounce);
					debounce = $timeout(function() {
						if (model.focusedItemIndex < model.totalCount) {
							model.focusedItemIndex++;
						}
					}, 10);

					return false;

				default:
					break;
			}
		}

		$document.on('keydown', onKeyDown);
	}
})();
