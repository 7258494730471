(function() {
	angular.module('ui.table_lite.controller', ['utils.date']).controller('tableLiteDirectiveController', tableLiteDirectiveController);

	tableLiteDirectiveController.$inject = ['$filter', '$timeout', 'tableLiteDataType', 'DateUtils'];
	function tableLiteDirectiveController($filter, $timeout, tableLiteDataType, DateUtils) {
		var ctrl = this,
			contexts = {
				item: 'item',
				all: 'all'
			},
			tableDefaults = {
				showColumnSearch: false,
				showGlobalSearch: false,
				sort: {},
				filter: {}
			},
			tableCellDefaults = {
				showDetails: false
			};

		ctrl.limitInc = 50;
		ctrl.loading = false;
		ctrl.limit = ctrl.limitInc;

		ctrl.sortDirection = {};
		ctrl.showLoadControls = true;
		ctrl.showDetailsCallback = undefined;

		ctrl.init = init;
		ctrl.directiveInit = directiveInit;
		ctrl.sortBy = sortBy;
		ctrl.loadMore = loadMore;
		ctrl.loadAll = loadAll;
		ctrl.toggleShowDetails = toggleShowDetails;
		ctrl.decorateTable = decorateTable;

		function directiveInit(directiveScope) {
			if (angular.isDefined(directiveScope.config)) {
				_setTableThinking(true);

				ctrl.showDetailsCallback = directiveScope.showDetailsCallback;

				ctrl.table = _.assign({}, tableDefaults, directiveScope.config);

				if (angular.isDefined(ctrl.table.data)) {
					if (ctrl.table.data.length - 1 >= ctrl.limitInc) {
						ctrl.limit = ctrl.limitInc;
						ctrl.showLoadControls = true;
					} else {
						ctrl.limit = ctrl.table.data.length;
						ctrl.showLoadControls = false;
					}
				}

				var data = _.uniq(ctrl.table.data),
					modifiedData = [];
				angular.forEach(data, function(dataItem) {
					modifiedData.push(_.assign({}, tableCellDefaults, dataItem));
				});
				ctrl.table.data = modifiedData;

				angular.forEach(ctrl.table.columns, function(col) {
					col.sortProperty = angular.isDefined(col.sortProperty) ? col.sortProperty : col.name;
				});

				ctrl.decorateTable();

				$timeout(function() {
					_setTableThinking(false);
				}, 300);
			} else {
				directiveScope.table = undefined;
				directiveScope.error = 'config not defined';
			}
		}

		function init() {
			_setTableThinking(true);
			ctrl.showLoadControls = true;
			ctrl.table = angular.isDefined(ctrl.table) ? ctrl.table : {};
			if (angular.isDefined(ctrl.table.columns)) {
				angular.forEach(ctrl.table.columns, function(column) {
					if (column.sortable) {
						ctrl.sortDirection[column.sortProperty] = false;
					}
				});
			}
			_setTableThinking(false);
		}

		ctrl.init();

		function loadMore() {
			_setTableThinking(true);
			if (ctrl.limit + ctrl.limitInc >= ctrl.table.data.length) {
				ctrl.limit = ctrl.table.data.length;
				ctrl.showLoadControls = false;
			} else {
				ctrl.limit += ctrl.limitInc;
				ctrl.showLoadControls = true;
			}
			_setTableThinking(false);
		}

		function loadAll() {
			ctrl.limit = ctrl.table.data.length;
			ctrl.showLoadControls = false;
		}

		function toggleShowDetails(context, item) {
			if (context === contexts.item) {
				item.showDetails = !item.showDetails;
				if (angular.isDefined(ctrl.showDetailsCallback) && typeof ctrl.showDetailsCallback === 'function') {
					ctrl.showDetailsCallback();
				}
			} else if (context === contexts.all) {
				angular.forEach(ctrl.table.data, function(dataItem) {
					dataItem.showDetails = !dataItem.showDetails;
				});
			}
		}

		function sortBy(property) {
			_setTableThinking(true);
			var col = _getTableColumnBySortProperty(property);
			if (angular.isDefined(property) && col.sortable) {
				if (!_sortPropertyExists(property)) {
					ctrl.table.sort.push(property);
				} else {
					if (_sortPropertyInvExists(property)) {
						ctrl.table.sort[ctrl.table.sort.indexOf('-' + property)] = property;
						ctrl.sortDirection[property] = false;
					} else {
						ctrl.table.sort[ctrl.table.sort.indexOf(property)] = '-' + property;
						ctrl.sortDirection[property] = true;
					}
				}
			}
			_setTableThinking(false);
		}

		function _sortPropertyExists(property) {
			return ctrl.table.sort.indexOf(property) !== -1 || ctrl.table.sort.indexOf('-' + property) !== -1;
		}

		function _sortPropertyInvExists(property) {
			return ctrl.table.sort.indexOf('-' + property) !== -1;
		}

		function _getTableColumnBySortProperty(property) {
			var col = false;
			angular.forEach(ctrl.table.columns, function(column) {
				if (column.sortProperty === property) {
					col = column;
				}
			});
			return col;
		}

		function decorateTable() {
			var dateColumns = [];
			angular.forEach(ctrl.table.columns, function(column) {
				if (column.data.type === tableLiteDataType.date) {
					dateColumns.push(column);
				}
			});

			if (dateColumns.length) {
				angular.forEach(ctrl.table.data, function(dataItem) {
					angular.forEach(dateColumns, function(column) {
						var dateValue = new Date(dataItem[column.name]);
						dateValue = DateUtils.normalize(dateValue);
						dataItem[column.name] = dateValue;
					});
				});
			}
		}

		function _setTableThinking(state) {
			ctrl.loading = state;
		}
	}
})();
