(function() {
	angular.module('ui.manager.multiple.select').directive('managerMultipleSelect', directive);

	directive.$inject = ['$document', '$timeout'];

	function directive($document, $timeout) {
		return {
			restrict: 'EA',
			scope: {
				selectedItems: '=?',
				singleSelect: '=',
				sourceIds: '=?',
				type: '@',
				filterSyncedItems: '<?',
				callback: '&?'
			},
			controller: 'ManagerMultipleSelectCtrl',
			controllerAs: '$ctrl',
			templateUrl: 'ui/manager.multiple.select/template.html',
			require: 'ngModel',
			link: function(scope, element, attrs, ngModelCtrl) {
				scope.open = false;
				scope.disabled = false;
				attrs.$observe(
					'disabled',
					function(value) {
						scope.disabled = !!value;
					},
					true
				);

				attrs.$observe(
					'required',
					function(value) {
						scope.required = !!value;
					},
					true
				);

				$timeout(function() {
					ngModelCtrl.$setPristine();
				});

				ngModelCtrl.$render = function() {
					scope.selectedIds = ngModelCtrl.$viewValue;
				};

				scope.$watch('disabled', function(value) {
					if (!value) {
						element.on('click', scope.focus);
						$document.on('click', onClick);
					}
				});

				ngModelCtrl.$validators.required = (modelValue, viewValue) => {
					if (!scope.required || scope.required === false) {
						return true;
					}
					const value = modelValue || viewValue;
					return value && value.length > 0;
				};

				element.on('$destroy', function() {
					element.off('click', scope.focus);
					$document.off('click', onClick);
				});

				scope.$watchCollection(
					'selectedItems',
					function(value) {
						if (!angular.equals(scope.selectedIds, _.map(value, 'id'))) {
							scope.selectedIds = _.map(value, 'id');
						}

						if (!angular.equals(scope.selectedIds, ngModelCtrl.$viewValue)) {
							ngModelCtrl.$setViewValue(scope.selectedIds);
						}

						// Disable Check
						var syncedItems = _.filter(value, function(item) {
							return item && item.syncId && item.syncId.length;
						});
						var unSyncedItems = _.filter(value, function(item) {
							return !item.syncId;
						});

						if (
							scope.singleSelect !== undefined &&
							scope.singleSelect === true &&
							syncedItems &&
							syncedItems.length &&
							unSyncedItems &&
							unSyncedItems.length === 0
						) {
							scope.disabled = true;
						}
					},
					true
				);

				scope.focus = function() {
					if (!scope.disabled) {
						scope.open = true;
						$timeout(function() {
							if (!scope.disabled) {
								angular.element(element.find('input.search-box')).focus();
							}
						}, 100);
					}
				};

				scope.blur = function() {
					if (!scope.disabled) {
						scope.open = false;
						$timeout(function() {
							if (!scope.disabled) {
								angular.element(element.find('input.search-box')).blur();
							}
						});
					}
				};

				function onClick(event) {
					if (!_isClickInElement(event)) {
						scope.blur();
					}
				}

				function _isClickInElement(event) {
					var elementRect = element[0].getBoundingClientRect();
					var menuRect = element.find('.manager-multiple-select')[0].getBoundingClientRect();
					var clicked =
						event.clientX > elementRect.left &&
						event.clientX < elementRect.right &&
						event.clientY > elementRect.top &&
						event.clientY < menuRect.bottom;
					return clicked;
				}
			}
		};
	}
})();
