(function(angular) {
	angular.module('ui.directives.synced.record', []).directive('syncedRecord', syncedRecordDirective);

	function syncedRecordDirective() {
		return {
			restrict: 'A',
			link: linkFn
		};

		function linkFn(scope, element, attrs) {
			scope.$watch(
				function() {
					return attrs.syncedRecord;
				},
				function(entity) {
					if (entity && entity.syncId && entity.syncId.length && entity.syncId.indexOf('migration') === -1) {
						element.addClass('synced');
					} else {
						element.removeClass('synced');
					}
				},
				true
			);
		}
	}
})(window.angular);
