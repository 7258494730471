angular.module('ui.searchresult', []).directive('ilSearchResult', [
	function() {
		return {
			restrict: 'A',
			template: '<ul class="searchresult"><li ng-repeat="searchResult in searchResults" ng-bind-html="searchResult"></li></ul>',
			replace: true,
			transclude: true,
			scope: {
				searchContent: '=',
				searchTerm: '='
			},
			link: function(scope, element, attrs) {
				scope.hitCount = 0;
				scope.searchResults = [];

				scope.$watch('searchTerm', function(newTerm, oldTerm) {
					if (newTerm !== oldTerm) {
						scope.parseSearchContent();
					}
				});

				scope.escapeString = function(inputString) {
					return inputString.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
				};

				scope.parseSearchContent = function() {
					if (!scope.searchContent || scope.searchContent.length === 0 || !scope.searchTerm || scope.searchTerm.length === 0) {
						scope.hitCount = 0;
						scope.searchResults = [];
						return;
					}

					var escapedSearchTerm = scope.escapeString(scope.searchTerm);
					var matchExpression = new RegExp('/(\\S+\\s+){0,5}\\S*' + escapedSearchTerm + '\\S*(\\s+\\S+){0,5}', 'gi');

					//get hit count
					var matches = scope.searchContent.match(matchExpression);
					scope.hitCount = matches && matches.length > 0 ? matches.length : 0;
					if (scope.hitCount === 0 || !matches) {
						scope.searchResults = [];
						return;
					}

					//build list of hits
					angular.forEach(matches, function(aMatch) {
						aMatch = aMatch.replace(
							new RegExp('(' + escapedSearchTerm + ')', 'gi'),
							'<span class="highlighted_text">$1</span>'
						);
						scope.searchResults.push($sce.trustAsHtml('<span>' + aMatch + '</span>'));
					});
				};
			}
		};
	}
]);
