angular.module('ui.expandable', []).directive('ilExpandable', [
	function() {
		return {
			restrict: 'EA',
			template: [
				'<div class="expandable">',
				'<div class="expandable_header_container" ng-class="{ empty: empty === true }">',
				'<div class="expandable_header {{headerclassname}}" ng-class="{ empty: empty === true }">',
				"<span class=\"expandable_indicator\" ng-class=\"(open === true) ? 'open' : 'closed'\" ng-style=\"{ opacity: empty ? '0' : '1' }\"></span>",
				'<span class="expandable_header_text">{{header}}</span>',
				'<span class="expandable_header_meta">{{meta}}</span>',
				'</div>',
				'</div>',
				'<div class="expandable_content {{contentclassname}}" ng-class="{ expanded: open === true }" ng-transclude>',
				'</div></div>'
			].join(''),
			replace: true,
			transclude: true,
			scope: {
				header: '@',
				meta: '@',
				headerclassname: '@',
				contentclassname: '@',
				onOpen: '&',
				onClose: '&',
				empty: '=',
				initState: '='
			},
			link: function(scope, element, attrs) {
				var expandableContent = element.find('.expandable_content');
				scope.open = false;

				element.find('.expandable_header_container').bind('click', function() {
					if (scope.empty) {
						return;
					}
					scope.open = !scope.open;

					if (scope.onOpen && scope.open) {
						scope.onOpen();
					}

					if (scope.onClose && !scope.open) {
						scope.onClose();
					}

					if (!scope.$$phase) {
						scope.$apply();
					}
				});

				scope.$watch(
					function() {
						return scope.initState;
					},
					function(value) {
						scope.open = (value && value === 'open') || false;
					},
					true
				);

				scope.$watch(
					'open',
					function(value, oldValue) {
						if (value && !angular.equals(value, oldValue)) {
						}
					},
					true
				);
			}
		};
	}
]);
