(function() {
	angular
		.module('ui.manager.dropdown.constant', [])
		.constant('IlManagerDropdownModes', {
			multiselect: 'multiselect',
			singleselect: 'singleselect'
		})
		.constant('IlManagerDropdownTypes', {
			group: 'group',
			organization: 'organization',
			staff: 'staff'
		});
})();
