(function() {
	angular.module('ui.manager.dropdown.controller', []).controller('ilManagerDropdownController', controller);

	controller.$inject = [
		'$q',
		'$scope',
		'$timeout',
		'NotificationFactory',
		'Organization',
		'OrganizationType',
		'User',
		'ApplicationUserService',
		'UserRole',
		'Class',
		'Types',
		'IlManagerDropdownModes'
	];

	function controller(
		$q,
		$scope,
		$timeout,
		NotificationFactory,
		Organization,
		OrganizationType,
		User,
		ApplicationUserService,
		UserRole,
		Class,
		Types,
		IlManagerDropdownModes
	) {
		var vm = this,
			dataType = {
				unknown: 'unknown',
				organization: 'organization',
				staff: 'staff',
				group: 'group'
			},
			dropActions = {
				show: 'show',
				hide: 'hide'
			},
			offsetIncrement = 25,
			reset = function() {
				vm.showLoadMoreCollection = [];
				vm.foundItems = [];
				vm.searchTerm = '';
				vm.queryParams = {
					limit: 25,
					offset: 0,
					sortby: 'name'
				};
			};

		reset();

		vm.includeParent = false;
		vm.searching = false;
		vm.chosenItems = [];
		vm.showDrop = false;
		vm.showLoadMore = false;
		vm.showLoadMoreCollection = [];
		vm.loadingMore = false;
		vm.dataType = undefined;
		vm.subDataType = undefined;
		vm.required = false;
		vm.label = undefined;
		vm.element = undefined;
		vm.parent = undefined;
		vm.newGroupName = '';
		vm.foundOrgs = [];

		vm.triggerSearch = triggerSearch;
		vm.addItem = addItem;
		vm.addNewGroup = addNewGroup;
		vm.removeItem = removeItem;
		vm.handleResponse = handleResponse;
		vm.toggleDropVisibility = toggleDropVisibility;
		vm.loadMore = loadMore;
		vm.showPlus = showPlus;

		$scope.$watch(
			function() {
				return vm.parent.createGroupOrg;
			},
			function(n, o) {
				if (o !== n) {
					vm.parent.createGroupOrg = n;
				}
			},
			true
		);

		function loadMore() {
			vm.loadingMore = true;
			vm.queryParams.offset += offsetIncrement;
			vm.triggerSearch(false, function() {
				vm.loadingMore = false;
			});
		}

		function showPlus() {
			return vm.mode === IlManagerDropdownModes.multiselect
				? true
				: !!(vm.mode === IlManagerDropdownModes.singleselect && vm.chosenItems.length <= 0);
		}

		function toggleDropVisibility(action) {
			if (!vm.parent.list.length) {
				return false;
			}
			if (action === dropActions.show) {
				vm.showDrop = true;
				reset();
				focus();
				vm.triggerSearch(true, function() {
					vm.searching = false;
				});
			} else {
				vm.showDrop = false;
			}
		}

		function focus() {
			$timeout(function() {
				var searchBox = vm.element.find('input.il_manager_dropdown_search_term');
				searchBox.trigger('focus').bind('keydown keypress', keypressHandler);
			});
		}

		function keypressHandler(event) {
			if (event.which === 13) {
				event.preventDefault();
				event.stopImmediatePropagation();
				vm.triggerSearch(true, function() {
					vm.searching = false;
				});
			}
		}

		function triggerSearch(reset, callback) {
			vm.showLoadMoreCollection = [];
			if (angular.isDefined(vm.parent) && angular.isDefined(vm.parent.list) && vm.parent.list.length > 0) {
				vm.queryParams = reset
					? {
							limit: 25,
							offset: 0,
							sortby: 'name'
					  }
					: vm.queryParams;

				var queue,
					params = {
						limit: vm.queryParams.limit,
						offset: vm.queryParams.offset,
						search: vm.searchTerm,
						sortBy: vm.queryParams.sortby
					};

				vm.searching = reset !== false;
				vm.foundItems = reset ? [] : vm.foundItems;

				if (vm.parent.list !== undefined && vm.parent.list.length > 0) {
					queue = setupQueue(vm.parent.list, vm.dataType, params);
					$q.all(queue)
						.then(vm.handleResponse)
						.then(function() {
							if (callback !== undefined && typeof callback === 'function') {
								callback();
							}
						});
				}
			}
		}

		function setupQueue(parents, type, params) {
			var queue = [],
				inc = 0;
			if (type === dataType.organization) {
				angular.forEach(parents, function(parent) {
					if (
						vm.showLoadMoreCollection.length === 0 ||
						(angular.isDefined(vm.showLoadMoreCollection[inc]) && vm.showLoadMoreCollection[inc])
					) {
						if (angular.isDefined(vm.subDataType) && vm.subDataType === OrganizationType.school) {
							queue.push(vm.data.organizations.schools(parent.id, params));
						} else {
							queue.push(vm.data.organizations.children(parent.id, params));
						}
					}
					inc++;
				});
			} else if (type === dataType.group) {
				angular.forEach(vm.parent.list, function(parent) {
					if (
						vm.showLoadMoreCollection.length === 0 ||
						(angular.isDefined(vm.showLoadMoreCollection[inc]) && vm.showLoadMoreCollection[inc])
					) {
						queue.push(vm.data.organizations.groups(parent.id, params));
					}
					inc++;
				});
			} else if (type === dataType.staff) {
				angular.forEach(vm.parent.list, function(parent) {
					if (
						vm.showLoadMoreCollection.length === 0 ||
						(angular.isDefined(vm.showLoadMoreCollection[inc]) && vm.showLoadMoreCollection[inc])
					) {
						queue.push(vm.data.organizations.staff(parent.id, params));
					}
					inc++;
				});
			}
			return queue;
		}

		function handleResponse(response) {
			// make sure we exclude records already selected
			if (angular.isArray(response)) {
				var inc = 0,
					parentRefOrg,
					preparedItem,
					responseData;
				angular.forEach(response, function(resp) {
					responseData =
						vm.dataType === dataType.organization && angular.isDefined(vm.subDataType)
							? resp
							: vm.dataType === dataType.organization
							? resp.items
							: vm.dataType === dataType.group
							? resp.items
							: vm.dataType === dataType.staff
							? resp
							: [];
					if (responseData.length > 0) {
						angular.forEach(responseData, function(item) {
							if (!itemsHasItem(item, vm.chosenItems, 'id') && !itemsHasItem(item, vm.foundItems, 'id')) {
								parentRefOrg = { id: vm.parent.list[inc].id };
								preparedItem = prepareItemData(item, parentRefOrg);
								vm.foundItems.push(preparedItem);
							}
						});
						inc++;
					}
					vm.showLoadMoreCollection.push(resp.totalCount > vm.queryParams.offset + offsetIncrement);
				});

				if (vm.includeParent) {
					angular.forEach(vm.parent.list, function(parentOrg) {
						vm.foundItems.push(parentOrg);
					});
				}

				vm.foundItems = _.sortBy(vm.foundItems, function(o) {
					return o[vm.queryParams.sortby].toLowerCase();
				});
				if (vm.foundItems.length === 1) {
					addItem(vm.foundItems[0]);
				}
				vm.showLoadMore = vm.showLoadMoreCollection.indexOf(true) !== -1;
				vm.searching = false;
			} else {
				angular.forEach(response.items, function(item) {
					if (!itemsHasItem(item, vm.chosenItems, 'id') && !itemsHasItem(item, vm.foundItems, 'id')) {
						vm.foundItems.push(item);
					}
				});

				if (vm.includeParent) {
					angular.forEach(vm.parent.list, function(parentOrg) {
						vm.foundItems.push(parentOrg);
					});
				}

				if (vm.foundItems.length === 1) {
					addItem(vm.foundItems[0]);
				}
				vm.showLoadMore = response.totalCount > vm.queryParams.offset + offsetIncrement;
				vm.searching = false;
			}
			vm.foundItems = _.uniq(vm.foundItems, 'id');
		}

		function prepareItemData(item, parentRefOrg) {
			var parentId,
				parentIdProperty =
					vm.dataType === dataType.group ? 'organizationId' : vm.dataType === dataType.organization ? 'parentId' : 'unknown';

			if (vm.dataType !== dataType.staff) {
				if (!itemsHasItem(item, vm.foundOrgs, 'id')) {
					if (item[parentIdProperty] !== '00000000-0000-0000-0000-000000000000') {
						vm.data.organizations.get(item[parentIdProperty]).then(function(org) {
							vm.foundOrgs.push(org);
							item.ildParentName = org.name;
							item.ildParentId = org.id;
							item.ildFoundById = parentRefOrg.id;
						});
					}
				} else {
					angular.forEach(vm.foundOrgs, function(org) {
						if (org.id === item[parentIdProperty]) {
							item.ildParentName = org.name;
							item.ildParentId = org.id;
						}
					});
				}
			} else {
				item.ildParentName = '';
				item.ildParentId = '';
				item.ildFoundById = parentRefOrg.id;
				item.name = item.firstName + ' ' + item.lastName;
				// ToDo: need to figure out how to handle staff
				// 	since we query against users
				// 	which can be of many roles and not teacher
			}
			return item;
		}

		function itemsHasItem(item, items, property) {
			var response = false;
			angular.forEach(items, function(itemsItem) {
				if (item[property] === itemsItem[property]) {
					response = true;
				}
			});
			return response;
		}

		function addItem(item) {
			if (!itemsHasItem(item, vm.chosenItems, 'id')) {
				vm.chosenItems.push(item); // add to chosenItems
				if (!itemsHasItem(item, vm.foundItems, 'id')) {
					vm.foundItems.splice(vm.foundItems.indexOf(item), 1); // remove from foundItems
				}
				vm.showDrop = false;
			}
		}

		function removeItem(item) {
			vm.chosenItems.splice(vm.chosenItems.indexOf(item), 1); // remove from chosenItems
		}

		function addNewGroup(groupName, organization) {
			var currentUser = ApplicationUserService.getUser(),
				isTeacher = ApplicationUserService.getUserRole() === UserRole.teacher;

			var newlyAddedGroup,
				newGroup = {
					entityType: Types.group,
					name: groupName,
					organizationId: organization.id,
					userIds: isTeacher ? [currentUser.id] : []
				};
			vm.data.group
				.add(newGroup)
				.then(function(response) {
					NotificationFactory.notify(
						{
							heading: 'Student Group ' + newGroup.name + ' created',
							content: 'Student Group ' + newGroup.name + ' created',
							closeAfter: 5
						},
						true
					);

					newlyAddedGroup = {
						id: response.id,
						groupType: response.groupType,
						name: response.name,
						organizationId: response.organizationId
					};
					vm.addItem(newlyAddedGroup);
				})
				['catch'](function(error) {
					//we just consume the error as needed
					NotificationFactory.error(error);
					NotificationFactory.notify(
						{
							heading: 'Something went wrong',
							content: error.data.message,
							closeAfter: 5
						},
						true
					);
				});
		}

		// helper

		vm.data = {
			organizations: {
				get: function(organizationId) {
					return Organization.get(organizationId);
				},
				children: function(organizationId, passedParams) {
					var params = passedParams;
					params.includeDescendants = true;
					return Organization.query(params);
				},
				schools: function(organizationId, passedParams) {
					var params = passedParams;
					params.includeDescendants = true;
					params.organizationType = vm.subDataType;
					return Organization.organizationList(organizationId, params);
				},
				groups: function(organizationId, passedParams) {
					var params = passedParams;
					params.includeDescendants = true;
					return Organization.classes(organizationId, params);
				},
				staff: function(organizationId, passedParams) {
					var params = passedParams;
					params.includeDescendants = false;
					return Organization.userList(organizationId, params);
				}
			},
			staff: {
				get: function(staffId) {
					return User.get(staffId);
				}
			},
			group: {
				get: function(groupId) {
					return Class.get(groupId);
				},
				add: function(newGroup) {
					return Class.save(newGroup);
				}
			}
		};
	}
})();
