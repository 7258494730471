(function(angular) {
	angular.module('ui.directives.responsive.table', []).directive('ilResponsiveTable', responsiveTableDirective);

	function responsiveTableDirective() {
		return {
			restrict: 'A',
			compile: function(element, attrs) {
				attrs.$addClass('responsive');
				var headers = element[0].querySelectorAll('li.table_header > span');
				if (headers.length) {
					var rows = element[0].querySelectorAll('li.table_body');
					Array.prototype.forEach.call(rows, function(row) {
						var headerIndex = 0;
						Array.prototype.forEach.call(row.querySelectorAll('span.table_cell'), function(value, index) {
							var th = value.parentElement.querySelector('table_header') || headers.item(headerIndex);
							//var title = th.textContent;
							var title;
							if (th && th.className && th.className === 'table_cell checkbox') {
								title = 'Select';
							} else if (th && th.className && th.className === 'table_cell actions') {
								title = 'Actions';
							} else if (th && th.innerText && th.innerText.length) {
								title = th.innerText;
							}

							if (title) {
								value.setAttribute('data-title', title);
							}
							//if (title && !value.getAttributeNode('data-title')) {
							//	value.setAttribute('data-title', title);
							//}

							var colspan = value.getAttributeNode('colspan');
							headerIndex += colspan ? parseInt(colspan.value, 10) : 1;
						});
					});
				}
			}
		};
	}
})(window.angular);
