angular
	.module('ui.unsupported', ['ngAnimate', 'utils.localization', 'utils.unsupported.detector'])
	.directive('ilUnsupported', [
		function() {
			var ilUnsupportedDirective = {
				restrict: 'EA',
				replace: true,
				scope: {},
				link: function(scope, elem) {
					elem.addClass('unsupported_browser');
					scope.container = elem;
					if (scope.model.isSupported) {
						elem.remove();
					}
				},
				templateUrl: 'ui/unsupported/unsupported.html',
				controller: [
					'$scope',
					'$log',
					'UnsupportedDetector',
					function($scope, $log, UnsupportedDetector) {
						$scope.model = {
							isSupported: UnsupportedDetector.isSupported(),
							collapsed: false
						};

						$scope.toggleCollapsed = function() {
							$scope.model.collapsed = !$scope.model.collapsed;
							if ($scope.model.collapsed) {
								$log.debug(UnsupportedDetector.getInfo());
							}
						};
					}
				]
			};

			return ilUnsupportedDirective;
		}
	])
	.directive('ilUnsupportedTest', [
		function() {
			var ilUnsupportedDirective = {
				restrict: 'EA',
				replace: true,
				scope: {},
				link: function(scope, elem) {
					elem.addClass('unsupported_browser');
					scope.container = elem;
					//if ( !scope.forceUnsupported && scope.model.isSupported ) {
					//	elem.remove();
					//}
				},
				templateUrl: 'ui/unsupported/unsupported.html',
				controller: [
					'$scope',
					'$log',
					'UnsupportedDetector',
					function($scope, $log, UnsupportedDetector) {
						$scope.model = {
							isSupported: $scope.forceUnsupported || UnsupportedDetector.isSupported(),
							collapsed: false
						};

						$scope.toggleCollapsed = function() {
							$scope.model.collapsed = !$scope.model.collapsed;
							if ($scope.model.collapsed) {
								$log.debug(UnsupportedDetector.getInfo());
							}
						};
					}
				]
			};

			return ilUnsupportedDirective;
		}
	])
	.animation('.slide-collapsed', function() {
		var duration = 300;
		return {
			removeClass: function(elem, className, done) {
				if (className === 'ng-hide') {
					elem.stop();
					elem.slideDown(duration, done);
				} else {
					done();
				}
			},
			addClass: function(elem, className, done) {
				if (className === 'ng-hide') {
					elem.stop();
					elem.slideUp(duration, done);
				} else {
					done();
				}
			}
		};
	});
