(function() {
	angular.module('ui.timeinput', ['utils.localization']).directive('uiTimeInput', timeInputDirective);

	timeInputDirective.$inject = ['$timeout'];

	function timeInputDirective($timeout) {
		return {
			restrict: 'EA',
			replace: true,
			require: 'ngModel',
			templateUrl: 'ui/timeinput/time.input.html',
			scope: {
				timeMode: '='
			},
			link: timepickerLinkFn
		};

		function timepickerLinkFn(scope, element, attr, ngModelctrl) {
			var hourInput,
				minuteInput,
				modeInput,
				hourInputEl,
				minuteInputEl,
				modeInputEl,
				hourInputCtrl,
				minuteInputCtrl,
				modeInputCtrl,
				currentFocusEl,
				keyCount = 2;

			// expose for testing...
			scope.hourInputParser = hourInputParser;
			scope.minuteInputParser = minuteInputParser;
			scope.modeInputParser = modeInputParser;
			scope.modelWatcher = modelWatcher;

			scope.toggleMode = toggleMode;

			//default time mode to 12h
			var timeMode = (scope.timeMode = scope.timeMode !== 24 ? 12 : 24);

			// ngModel
			scope.modelValue = undefined;

			function toggleMode() {
				scope.mode = scope.mode === 'AM' ? (scope.mode = 'PM') : 'AM';
				modeInput.select();
				//safari
				modeInput.setSelectionRange(0, 2);
			}

			$timeout(function() {
				ngModelctrl.$setPristine();
			});

			// ngModelctrl.$isEmpty = function (value) {
			// 	return !(value !== undefined && value.length);
			// };

			ngModelctrl.$render = function() {
				scope.modelValue = ngModelctrl.$viewValue;
			};

			scope.$watch(
				'modelValue',
				function(value) {
					if (!angular.equals(value, ngModelctrl.$viewValue)) {
						ngModelctrl.$setViewValue(value);
					}
				},
				true
			);

			function hourInputParser(value) {
				var hour = parseInt(value);

				if (isNaN(hour)) {
					hourInputCtrl.$viewValue = timeMode === 24 ? '00' : '12';
					hourInputCtrl.$render();
					hourInput.select();
					return hourInputCtrl.$viewValue;
				}

				// Some logic If we're in military time and the user wants to input 00
				if (timeMode === 24 && hour === 0) {
					// Check for '10' input
					if (parseInt(hourInputCtrl.$modelValue) === 0) {
						hourInputCtrl.$viewValue = '00';
						hourInputCtrl.$commitViewValue();
						hourInputCtrl.$render();
						minuteInput.focus();
						minuteInput.select();
						return hourInputCtrl.$viewValue;
					}
				}

				//if user meant to type 10, 11 or 12 for standard or 10 - 23 for military
				var squashTime = false;
				if (timeMode === 24) {
					if (parseInt(hourInputCtrl.$modelValue) === 2 && hour < 4) {
						squashTime = true;
					} else if (parseInt(hourInputCtrl.$modelValue) < 2 && hour < 10) {
						squashTime = true;
					}
				} else {
					if (parseInt(hourInputCtrl.$modelValue) < 2 && hour < 3) {
						squashTime = true;
					}
				}
				if (squashTime) {
					hourInputCtrl.$viewValue = parseInt(hourInputCtrl.$modelValue) + '' + hour;
					hourInputCtrl.$commitViewValue();
					hourInputCtrl.$render();
					minuteInput.focus();
					minuteInput.select();
					return hourInputCtrl.$viewValue;
				}

				if (hour < 10 && value.length === 1) {
					hourInputCtrl.$viewValue = '0' + value;
					hourInputCtrl.$commitViewValue();
					hourInputCtrl.$render();
				}

				if (hour > (timeMode === 24 ? 2 : 1)) {
					minuteInput.focus();
					minuteInput.select();
				} else {
					hourInput.select();
				}

				return hourInputCtrl.$viewValue;
			}

			function minuteInputParser(value) {
				var minute = parseInt(value);

				if (isNaN(minute)) {
					minuteInputCtrl.$viewValue = '00';
					minuteInputCtrl.$render();
					minuteInput.select();
					return minuteInputCtrl.$viewValue;
				}

				if (parseInt(minuteInputCtrl.$modelValue) < 6 && minute < 10) {
					minuteInputCtrl.$viewValue = parseInt(minuteInputCtrl.$modelValue) + '' + minute;
					minuteInputCtrl.$commitViewValue();
					minuteInputCtrl.$render();
					if (timeMode === 12 && minuteInputCtrl.$viewValue[0] !== '0') {
						modeInput.focus();
						modeInput.select();
					} else {
						minuteInput.focus();
						minuteInput.select();
					}
					return minuteInputCtrl.$viewValue;
				}

				var noJump = parseInt(minuteInputCtrl.$viewValue) < 6;

				if (minute < 10 && value.length === 1) {
					minuteInputCtrl.$viewValue = '0' + value;
					minuteInputCtrl.$commitViewValue();
					minuteInputCtrl.$render();
				}

				if (!noJump && parseInt(value) > 5 && timeMode === 12) {
					modeInput.focus();
					modeInput.select();
				} else {
					minuteInput.focus();
					minuteInput.select();
				}

				return minuteInputCtrl.$viewValue;
			}

			function modeInputParser(value) {
				//if user has typed 'a', or has typed 'm' with 'AM' showing
				if (value.toLowerCase().indexOf('a') >= 0 || (value.toLowerCase() === 'm' && modeInputCtrl.$modelValue === 'AM')) {
					modeInputCtrl.$viewValue = 'AM';
				} else {
					modeInputCtrl.$viewValue = 'PM';
				}

				// if(value.toLowerCase() === 'a') {
				// 	modeInputCtrl.$viewValue = 'AM';
				// }
				// else if(value.toLowerCase() === 'p') {
				// 	modeInputCtrl.$viewValue = 'PM';
				// }
				// else if(value.toLowerCase() !== 'm') {
				// 	scope.toggleMode();
				// 	return;
				// }

				modeInputCtrl.$commitViewValue();
				modeInputCtrl.$render();

				modeInput.select();

				return modeInputCtrl.$viewValue;
			}

			function resetKeyCount() {
				keyCount = 2;
			}

			function inputBlurHandler() {
				element.removeClass('time-focus');

				if (currentFocusEl === this) {
					// jshint ignore:line
					currentFocusEl = null;
				}
			}

			function inputFocusHandler() {
				element.addClass('time-focus');
				currentFocusEl = this; // jshint ignore:line
			}

			function modelWatcher(dt) {
				if (!dt) {
					scope.hour = '--';
					scope.minute = '--';
					scope.mode = '--';
					return;
				}

				if (angular.isString(dt)) {
					dt = new Date(dt);
				}

				if (angular.isDate(dt)) {
					var tempHour = dt.getHours();
					scope.minute = dt.getMinutes();

					if (timeMode === 12) {
						if (tempHour > 11) {
							scope.hour = tempHour === 12 ? tempHour : tempHour - 12;
							scope.mode = 'PM';
						} else {
							scope.hour = tempHour === 0 ? 12 : tempHour;
							scope.mode = 'AM';
						}
					} else {
						scope.hour = tempHour === 0 ? 0 : tempHour; // Default to 00 for military time
					}
					if (scope.hour < 10) {
						scope.hour = '0' + scope.hour;
					}
					if (scope.minute < 10) {
						scope.minute = '0' + scope.minute;
					}
				}
			}

			angular.forEach(element.find('input'), function(input) {
				if (input.name === 'hour') {
					hourInput = input;
					hourInputEl = angular.element(input);
					hourInputCtrl = hourInputEl.controller('ngModel');
				} else if (input.name === 'minute') {
					minuteInput = input;
					minuteInputEl = angular.element(input);
					minuteInputCtrl = minuteInputEl.controller('ngModel');
				} else if (input.name === 'mode') {
					modeInput = input;
					modeInputEl = angular.element(input);
					modeInputCtrl = modeInputEl.controller('ngModel');
				}
			});

			scope.$watch('modelValue', modelWatcher);

			scope.$watch('timeMode', function(value) {
				timeMode = value;
				modelWatcher(scope.modelValue);
			});

			scope.$watch(
				function() {
					var hour = parseInt(scope.hour);
					var minute = parseInt(scope.minute);

					if (isNaN(hour) || isNaN(minute)) {
						return null;
					}

					if (timeMode === 12) {
						if (scope.mode === 'AM') {
							return (hour === 12 ? 0 : hour) + ':' + minute;
						} else {
							return (hour === 12 ? hour : hour + 12) + ':' + minute;
						}
					} else {
						return hour + ':' + minute;
					}
				},
				function inputWatcher(value) {
					if (!value) {
						return;
					}

					var dateParts = value.split(':');

					if (!scope.modelValue) {
						scope.modelValue = new Date();
					}

					scope.modelValue.setHours(dateParts[0]);
					scope.modelValue.setMinutes(dateParts[1]);
					scope.modelValue.setSeconds(0);
				}
			);

			scope.focus = function elementFocus() {
				if (document.activeElement !== minuteInput && document.activeElement !== modeInput) {
					hourInput.focus();
					hourInput.select();
				}
			};

			hourInputCtrl.$parsers.unshift(hourInputParser);
			minuteInputCtrl.$parsers.unshift(minuteInputParser);
			modeInputCtrl.$parsers.unshift(modeInputParser);

			hourInputEl.on('focus', resetKeyCount);
			minuteInputEl.on('focus', resetKeyCount);
			hourInputEl.on('focus', inputFocusHandler);
			minuteInputEl.on('focus', inputFocusHandler);
			modeInputEl.on('focus', inputFocusHandler);

			hourInputEl.on('blur', inputBlurHandler);
			minuteInputEl.on('blur', inputBlurHandler);
			modeInputEl.on('blur', inputBlurHandler);

			hourInputEl.on('keydown', function(evt) {
				keyCount--;

				var curValue;
				switch (evt.keyCode) {
					case 37: //left key
						evt.preventDefault();
						evt.stopPropagation();
						break;

					// case 38://up key
					// 	curValue = parseInt(scope.hour);
					// 	if(curValue < (timeMode === 24 ? 24: 12)) {
					// 		$timeout(function(){
					// 			scope.hour = ++curValue;
					// 		}, 0);
					// 	}
					// 	evt.preventDefault();
					// 	evt.stopPropagation();
					// 	break;
					// case 40://down key
					// 	curValue = parseInt(scope.hour);
					// 	if(curValue > 0) {
					// 		$timeout(function(){
					// 			scope.hour = --curValue;
					// 		}, 0);
					// 	}
					// 	evt.preventDefault();
					// 	evt.stopPropagation();
					// 	break;

					case 38: //up key
					case 40: //down key
						evt.preventDefault();
						evt.stopPropagation();
						break;

					case 39: //right key
						evt.preventDefault();
						evt.stopPropagation();
						minuteInput.focus();
						minuteInput.select();
						break;
				}
			});

			minuteInputEl.on('keydown', function(evt) {
				keyCount--;

				switch (evt.keyCode) {
					case 37: //left key
						evt.preventDefault();
						evt.stopPropagation();
						hourInput.focus();
						hourInput.select();
						break;

					// case 38://up key
					// 	curValue = parseInt(scope.hour);
					// 	if(curValue < 59) {
					// 		$timeout(function(){
					// 			scope.minute = ++curValue;
					// 		}, 0);
					// 	}
					// 	evt.preventDefault();
					// 	evt.stopPropagation();
					// 	break;
					//
					// case 40://down key
					// 	if(curValue > 0) {
					// 		$timeout(function(){
					// 			scope.minute = --curValue;
					// 		}, 0);
					// 	}
					// 	evt.preventDefault();
					// 	evt.stopPropagation();
					// 	break;

					case 38: //up key
					case 40: //down key
						evt.preventDefault();
						evt.stopPropagation();
						break;

					case 39: //right key
						evt.preventDefault();
						evt.stopPropagation();
						modeInput.focus();
						modeInput.select();
						break;
				}
			});

			modeInputEl.on('keydown', function(evt) {
				keyCount--;

				switch (evt.keyCode) {
					case 37: //left key
						evt.preventDefault();
						evt.stopPropagation();
						minuteInput.focus();
						minuteInput.select();
						break;

					case 38: //up key
						$timeout(function() {
							scope.toggleMode();
						}, 0);
						evt.preventDefault();
						evt.stopPropagation();
						break;

					case 40: //down key
						$timeout(function() {
							scope.toggleMode();
						}, 0);
						evt.preventDefault();
						evt.stopPropagation();
						break;

					// case 38://up key
					// case 40://down key
					// 	evt.preventDefault();
					// 	evt.stopPropagation();
					// 	break;
					case 39: //right key
						evt.preventDefault();
						evt.stopPropagation();
						break;
				}
			});

			hourInputEl.on('click', function() {
				hourInput.select();
				//safari
				hourInput.setSelectionRange(0, 2);
			});

			minuteInputEl.on('click', function() {
				minuteInput.select();
				//safari
				minuteInput.setSelectionRange(0, 2);
			});

			modeInputEl.on('click', function() {
				modeInput.select();
				//safari
				modeInput.setSelectionRange(0, 2);
			});

			scope.$on('$destroy', function() {
				hourInputEl.off('click focus keydown blur');
				minuteInputEl.off('click focus keydown blur');
				modeInputEl.off('click focus keydown blur');
			});
		}
	}
})();
