(function() {
	angular.module('ui.tool.tipped.icon', []).component('toolTippedIcon', {
		bindings: {
			nameRef: '@',
			message: '@',
			iconClass: '@'
		},
		templateUrl: 'ui/tool.tipped.icon/template.html'
	});
})();
