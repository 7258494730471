(function(window, angular, undefined) {
	angular.module('ui.modal').directive('ilModalDyn', modalDirective);

	modalDirective.$inject = ['$rootScope', '$document', '$timeout', '$window'];

	function modalDirective($rootScope, $document, $timeout, $window) {
		var directive = {
			restrict: 'AE',
			replace: true,
			transclude: true,
			controller: 'ModalController',
			scope: {
				dialogStyle: '=',
				modalClass: '@ilModalClass',
				onShow: '&ilModalOnShow',
				onHide: '&ilModalOnHide',
				overlayClose: '=?'
			},
			templateUrl: 'ui/modal/modal.dynamic.html',
			link: linkFn
		};

		return directive;

		function linkFn(scope, element, attrs, ctrl) {
			var lastPosition,
				lastHeight,
				mutationObserver,
				wrapper = element.find('.il_modal_wrapper');

			activate();

			/**
			 * Modal functions
			 */

			function activate() {
				var body = angular.element($document).find('body');
				if (element.parent() !== body) {
					body.prepend(element);
				}

				// Set up mutation observer
				mutationObserver = window.MutationObserver ? new MutationObserver(onMutation) : null;

				// Hook up event handlers
				angular.element($document).on('keydown', onKeyDown);

				element.find('.il_modal_overlay,.il_modal_wrapper,.modal_close_button').on('click', onOverlayClick);
				element.find('.modal_close_button').on('click', onCloseClick);

				element.on('$destroy', onElementDestroy);

				//scope.$on('$destroy', onScopeDestroy);
				scope.$on('ilModalRemove', onModalHide);
				scope.$on('ilModalAutoScroll', onModalAutoScroll);

				scope.overlayClose = scope.overlayClose === undefined ? true : scope.overlayClose;
			}

			function enableMutationObserver(enabled) {
				if (mutationObserver) {
					if (enabled) {
						mutationObserver.observe(element[0], {
							childList: true,
							attributes: true,
							characterData: true,
							subtree: true
						});
					} else {
						mutationObserver.disconnect();
					}
				}
			}

			function getModalHeight() {
				var height = 0,
					hidden = element.hasClass('ng-hide');

				if (hidden) {
					element.removeClass('ng-hide').css('visibility', 'hidden');
				}

				height = wrapper.find('.il_modal_dialog').height();

				if (hidden) {
					element.addClass('ng-hide').css('visibility', 'visible');
				}

				return height;
			}

			function positionModalOnShow(height) {
				height = height || getModalHeight();

				var css = ctrl.getModalStartPosition(
					angular.element($document).height(),
					angular.element($window).height(),
					height,
					angular.element($window).scrollTop()
				);

				lastHeight = height;
				lastPosition = css;
				wrapper.css(css);
			}

			function positionModalOnScroll() {
				// Hide the modal so we can get a measurement of the window and doc heights
				// without the modal affecting those heights
				element.hide();
				var docHeight = angular.element($document).height(),
					windowHeight = angular.element($window).height();
				element.show();

				var css = ctrl.getModalScrollPosition(
					docHeight,
					windowHeight,
					getModalHeight(),
					wrapper.offset() || { top: 0 },
					angular.element($window).scrollTop()
				);

				//only compare position not placement type
				if (css !== null && ((css.bottom && css.bottom !== lastPosition.bottom) || (css.top && css.top !== lastPosition.top))) {
					wrapper.css(css);
					lastPosition = css;
				}

				//if (css !== null && !angular.equals(lastPosition, css)) {
				//	wrapper.css(css);
				//	lastPosition = css;
				//}
			}

			function onScopeDestroy() {
				$timeout(function() {
					scope.$destroy();
					element.remove();
				}, 0);
			}

			/**
			 * Event handlers
			 */

			function onElementDestroy() {
				angular.element($document).off('keydown', onKeyDown);
				element.find('.il_modal_overlay,.il_modal_wrapper').off('click', onOverlayClick);
				element.find('.modal_close_button').off('click', onCloseClick);
				//scope.$destroy();
			}

			function onKeyDown(keyEvent) {
				if ((keyEvent.keyCode || keyEvent.which) === 27) {
					scope.$apply(function() {
						//onModalHide();
						$rootScope.$broadcast('ilModalRemove');
					});
				}
			}

			function onModalHide(event, args) {
				// parent scope callbacks should be handled within the respective scope, the modal has an on hide callback
				angular.element('body').removeClass('no-scroll');
				angular.element($window).off('scroll', positionModalOnScroll);
				angular.element($window).off('resize', positionModalOnShow);

				if (angular.isFunction(scope.onHide)) {
					scope.onHide.call();
				}

				enableMutationObserver(false);
				onScopeDestroy();
			}

			function onModalShow(event, args) {
				angular.element('body').addClass('no-scroll');
				positionModalOnShow();
				angular.element($window).on('scroll', positionModalOnScroll);
				angular.element($window).on('resize', positionModalOnShow);

				if (angular.isFunction(scope.onShow)) {
					scope.onShow.call();
				}

				enableMutationObserver(true);
			}

			function onModalAutoScroll(event, modalId, idToScrollTo) {
				$timeout(function() {
					if (typeof idToScrollTo !== 'undefined' && idToScrollTo != null) {
						var scrollTarget = $('#' + idToScrollTo);
						var divTop = scrollTarget.offset().top;

						if (getModalHeight() > angular.element($window).height()) {
							//prevent background scrolling behind the modal
							$('html, body').animate({ scrollTop: divTop }, 5);
							//$("html, body").prop('scrollTop', divTop);
						}
					}
				}, 0);
			}

			function onCloseClick() {
				scope.$apply(function() {
					//onModalHide();
					$rootScope.$broadcast('ilModalRemove');
				});
			}

			function onOverlayClick(clickEvent) {
				if (scope.overlayClose === false) {
					return;
				}

				if (!element.hasClass('no_hide_on_outside_click')) {
					if (
						clickEvent.target &&
						clickEvent.target.className &&
						(clickEvent.target.className === 'il_modal_overlay' || clickEvent.target.className === 'il_modal_wrapper')
					) {
						scope.$apply(function() {
							//onModalHide();
							$rootScope.$broadcast('ilModalRemove');
						});
					}
				}
			}

			function onMutation() {
				var height = getModalHeight();
				if (height !== lastHeight) {
					positionModalOnShow(height);

					// To handle animations, we'll check this again in
					// a few milliseconds until we don't find any changes.
					$timeout(onMutation, 50);
				}

				if (mutationObserver) {
					mutationObserver.takeRecords();
				}
			}

			onModalShow();
		}
	}
})(window, window.angular);
