angular.module('ui.expandable.panel', []).directive('ilExpandablePanel', [
	'$sce',
	function($sce) {
		return {
			restrict: 'A',
			replace: true,
			transclude: true,
			template: [
				'<section class="expandable_panel"',
				'il-busy-overlay busy="model.busy && model.busy === true">',
				'<section class="collapse_header headerclassname"',
				"title=\"Click to {{ model.expanded === true ? 'Collapse' : 'Expand'}}\"",
				'ng-click="toggle()">',
				'<h3 class="form_title" ng-bind-html="headerContent"></h3>',
				'<section class="collapse_arrow" ng-bind-html="indicatorContent"></section>',
				'</section>',
				'<section ng-if="model.expanded" ng-transclude></section>',
				'</section>'
			].join(''),
			scope: {
				headerContent: '@',
				indicatorContent: '@',
				headerclassname: '@',
				contentclassname: '@',
				busy: '=',
				onExpand: '&',
				onCollapse: '&',
				empty: '=',
				initState: '='
			},
			link: function(scope, element, attrs) {
				scope.model = {};
				scope.model.expanded = false;

				if (scope.headerContent) {
					scope.headerContent = $sce.trustAsHtml(scope.headerContent);
				}

				if (!scope.indicatorContent) {
					scope.indicatorContent = $sce.trustAsHtml(
						[
							'<svg height="30" width="40">',
							'<circle cx="20" cy="20" r="30" stroke="#4a328e" stroke-width="3" fill="none" />',
							'<polyline ng-if="collapseEnabled && !model.expanded" points="5,10 20,25 35,10" stroke="#4a328e" stroke-width="3" fill="none" />',
							'<polyline ng-if="collapseEnabled && model.expanded" points="5,10 20,25 35,10" stroke="#4a328e" stroke-width="3" fill="none" transform="rotate(180, 20, 17)" />',
							'</svg>'
						].join('')
					);
				}

				scope.toggle = function() {
					scope.model.collapsed = !scope.model.collapsed;
					$(parents[x]).animate({ 'max-height': scope.model.collapsed === true ? '48px' : '10000px' }, 1200);
				};
			}
		};
	}
]);
