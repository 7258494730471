(function() {
	angular.module('ui.manager.select.controller', []).controller('ilManagerSelectController', controller);

	controller.$inject = [];

	function controller() {
		var ctrl = this;
		ctrl.disabled = false;
		ctrl.selected = {};
		ctrl.options = [];
		ctrl.label = '';
		ctrl.showDrop = false;
		ctrl.select = select;
		ctrl.toggleShowDrop = toggleShowDrop;

		function select(option) {
			ctrl.selected = option.value;
			ctrl.toggleShowDrop('hide');
		}

		function toggleShowDrop(action) {
			switch (action) {
				case 'show':
					ctrl.showDrop = true;
					break;
				case 'hide':
					ctrl.showDrop = false;
					break;
				default:
					ctrl.showDrop = !ctrl.showDrop;
			}
		}
	}
})();
