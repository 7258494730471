/*

used on an html select: when its ngModel value is changed a blur is invoked which removes focus

*/

(function() {
	angular.module('ui.directives.autofocus', []).directive('autoFocus', directive);

	directive.$inject = ['$timeout'];

	function directive($timeout) {
		return {
			restrict: 'A',
			require: 'ngModel',
			link: function(scope, element, attrs, ngModel) {
				$timeout(function() {
					element[0].focus();
				}, 0);
			}
		};
	}
})();
