/**
 * Requires jQuery and the jQuery UI Position plugin (http://api.jqueryui.com/position/)
 */

(function() {
	angular
		.module('ui.popup', [])

		// .directive( 'ilNotyPopup', [
		// 	'$rootScope',
		// 	function ($rootScope) {
		//
		// 		return {
		// 			restrict: 'A',
		// 			scope: {
		// 				content: '@ilNotyPopup'
		// 			},
		// 			link: function( scope, element ) {
		//
		// 				// We need to include the touchstart event here, so it gets consumed
		// 				// and won't propagate to the touchstart handler on the document which
		// 				// hides the popup.
		// 				element.on( 'click touchstart', function( clickEvent ) {
		// 					$(element).noty({
		// 						//theme: 'ilNotyTheme',
		// 						//layout: 'ilNotyLayout',
		// 						timeout: 0,
		// 						text: scope.content || '',
		// 						type: 'alert'
		// 					});
		// 					clickEvent.stopPropagation();
		// 					return false;
		// 				});
		//
		// 				element.on( '$destroy', function() {
		// 					element.off( 'click touchstart' );
		// 				});
		//
		// 			}
		// 		};
		// 	}
		// ])

		.directive('ilMiniPopupHoverRef', [
			'$rootScope',
			function($rootScope) {
				return {
					restrict: 'A',
					scope: {
						popupId: '@ilMiniPopupHoverRef'
					},
					link: function(scope, element) {
						// keep click functionality for devices
						element.on('click touchstart', function(clickEvent) {
							$rootScope.$broadcast('toggleMiniPopup', {
								element: clickEvent.currentTarget,
								popupId: scope.popupId
							});
							clickEvent.stopPropagation();
							return false;
						});

						element.on('$destroy', function() {
							element.off('click touchstart');
						});
						// keep click functionality for devices

						element.bind('mouseenter', function(event) {
							$rootScope.$broadcast('toggleMiniPopup', {
								element: event.currentTarget,
								popupId: scope.popupId
							});
							return false;
						});

						element.bind('mouseleave', function() {
							$rootScope.$broadcast('hideMiniPopup');
						});

						element.on('$destroy', function() {
							element.off('mouseenter mouseleave');
						});
					}
				};
			}
		])

		.directive('ilMiniPopupRef', [
			'$rootScope',
			function($rootScope) {
				return {
					restrict: 'A',
					scope: {
						popupId: '@ilMiniPopupRef'
					},
					link: function(scope, element) {
						//disable tabbing
						//element.attr('tabindex', -1);

						// We need to include the touchstart event here, so it gets consumed
						// and won't propagate to the touchstart handler on the document which
						// hides the popup.
						element.on('click touchstart', function(clickEvent) {
							$rootScope.$broadcast('toggleMiniPopup', {
								element: clickEvent.currentTarget,
								popupId: scope.popupId
							});
							clickEvent.stopPropagation();
							return false;
						});

						element.on('$destroy', function() {
							element.off('click touchstart');
						});
					}
				};
			}
		])

		.factory('ilMiniPopupGlobalEventHandlers', [
			'$rootScope',
			function($rootScope) {
				var globalEventService = {
					onClickOutside: function() {
						$rootScope.$broadcast('hideMiniPopup');
					},
					onKeyDown: function(keyEvent) {
						if ((keyEvent.keyCode || keyEvent.which) === 27) {
							$rootScope.$broadcast('hideMiniPopup');
						}
					}
				};
				return globalEventService;
			}
		])

		.directive('ilMiniPopup', [
			'$rootScope',
			'$document',
			'$log',
			'ilMiniPopupGlobalEventHandlers',
			function($rootScope, $document, $log, ilMiniPopupGlobalEventHandlers) {
				return {
					restrict: 'EA',

					transclude: true,

					replace: true,

					template:
						'<div class="mini_popup {{cssClass}}" ng-class="{ \'mini_popup_no_tail\': noTail, \'tooltip\': tooltip }">' +
						'<a href class="mini_popup_close_button icon_close_button_small" ng-click="hide()"></a>' +
						'<div class="mini_popup_content" ng-transclude></div>' +
						'</div>',

					scope: {
						popupId: '@ilMiniPopup',
						cssClass: '@'
					},

					link: function(scope, element, attr) {
						var id = scope.popupId || attr.id;
						if (!id) {
							id = 'mini_popup_' + new Date().getTime();
							$log.info('No ID for mini-popup: ' + element.html() + '\nUsing ID: ' + id);
						}
						element.attr('id', id);

						if (element.attr('class')) {
							// Get non-Angular classes to add to template
							var classes = element
								.attr('class')
								.split(' ')
								.filter(function(item) {
									return !/^ng-/i.test(item);
								});

							if (classes.length) {
								element.find('div.mini_popup').addClass(classes.join(' '));
							}
						}

						scope.fadeTime = 250;
						scope.id = id;

						// Register popup and set up event handlers on document if necessary
						$rootScope.popups = $rootScope.popups || {};
						if (_.isEmpty($rootScope.popups)) {
							angular.element($document).on('click touchstart', ilMiniPopupGlobalEventHandlers.onClickOutside);
							angular.element($document).on('keydown', ilMiniPopupGlobalEventHandlers.onKeyDown);
						}

						// Make sure there isn't a duplicate popup. If so, we'll remove it so we don't get weird behavior.
						if ($rootScope.popups[scope.id] != null) {
							$log.info('Duplicate mini-popup: ' + scope.id + '. Removing from document.');
							element.remove();
							return;
						}
						$rootScope.popups[scope.id] = {};

						var body = angular.element($document).find('body');
						if (element.parent() !== body) {
							body.prepend(element);
						}

						scope.popup = element;
						scope.popup.css('display', 'none');

						scope.onCloseCallback = null;

						scope.noTail = attr.ilMiniPopupNoTail != null;
						scope.tooltip = attr.tooltip != null;
						if (attr.wide != null) {
							scope.popup.css('max-width', 'none');
						}

						scope.visible = false;

						scope.position = {};
						if (attr.ilMiniPopupPositionMy) {
							scope.position.my = attr.ilMiniPopupPositionMy;
						}
						if (attr.ilMiniPopupPositionAt) {
							scope.position.at = attr.ilMiniPopupPositionAt;
						}
						if (attr.ilMiniPopupPositionCollision) {
							scope.position.collision = attr.ilMiniPopupPositionCollision;
						}

						var showMiniPopup = function(anchorElem, content) {
							//add popup content if supplied in args
							if (content && content.length > 0) {
								var contentContainer = scope.popup.find('.mini_popup_content');
								if (contentContainer[0]) {
									contentContainer[0].innerHTML = content;
								}
							}

							scope.popup
								.stop()
								.css('display', 'block')
								.position({
									my: scope.position.my || 'center bottom',
									at: scope.position.at || 'center top-24',
									collision: scope.position.collision || 'flipfit flipfit',
									of: anchorElem,
									using: function(position, feedback) {
										scope.popup.removeClass('top bottom');
										scope.popup.css(position).addClass(feedback.vertical);

										// targetCenter is the center x position of triggering element
										var targetCenter = feedback.target.left + feedback.target.width / 2;

										// feedback.element.left is [supposed to be] the popup's x position relative to the <body>
										var flipTail = false;
										var left = targetCenter - feedback.element.left + (feedback.vertical === 'top' ? -29 : 0);
										if (left < 0) {
											left = 22;
										} else if (feedback.vertical === 'top' && left > feedback.element.width / 2 - 29) {
											flipTail = true;
											left += 29;
										} else if (feedback.vertical === 'bottom' && left < feedback.element.width / 2) {
											flipTail = true;
											left -= 29;
										}
										if (left > feedback.element.width - 29 - 22) {
											left = feedback.element.width - 29 - 22;
										}

										var div = angular
											.element('<div>')
											.addClass('arrow')
											.addClass(feedback.vertical)
											.css('left', left + 'px');
										if (flipTail) {
											div.addClass('flipped');
										}
										div.appendTo(this);
									}
								})
								.css('display', 'none')
								.fadeIn(scope.fadeTime);

							scope.visible = true;
						};

						var showMiniPopupNoTail = function(anchorElem, content) {
							//add popup content if supplied in args
							if (content && content.length > 0) {
								var contentContainer = scope.popup.find('.mini_popup_content');
								if (contentContainer[0]) {
									contentContainer[0].innerHTML = content;
								}
							}

							scope.popup
								.stop()
								.removeClass('open')
								.css('display', 'block')
								.position({
									my: scope.position.my || 'center bottom',
									at: scope.position.at || 'center top-7',
									collision: scope.position.collision || 'flipfit flipfit',
									of: anchorElem,
									using: function(position, feedback) {
										scope.popup.removeClass('top bottom');
										scope.popup.css(position).addClass(feedback.vertical);
									}
								})
								.css('display', 'none')
								.fadeIn(scope.fadeTime);

							scope.visible = true;
						};

						scope.hide = function() {
							if (scope.visible) {
								scope.visible = false;
								scope.popup.stop().fadeOut(scope.fadeTime, function() {
									scope.popup.find('.arrow').remove();
									if (scope.onCloseCallback !== null) {
										scope.onCloseCallback();
									}
								});
							}
						};

						scope.$on('toggleMiniPopup', function(event, args) {
							if (args.popupId === scope.id && !scope.visible) {
								scope.onCloseCallback =
									args.onClose !== undefined && typeof args.onClose === 'function' ? args.onClose : null;
								if (scope.noTail) {
									showMiniPopupNoTail(args.element);
								} else {
									showMiniPopup(args.element, args.content);
								}
							} else if (scope.visible) {
								scope.onCloseCallback =
									args.onClose !== undefined && typeof args.onClose === 'function' ? args.onClose : null;
								scope.hide();
							}
						});

						scope.$on('hideMiniPopup', function() {
							scope.hide();
						});

						scope.popup.on('click', function(event) {
							// Consume click event on the popover so it doesn't bubble up and call hide
							event.stopPropagation();
						});

						element.on('$destroy', function() {
							scope.popup.off('click');
						});

						scope.$on('$destroy', function() {
							delete $rootScope.popups[scope.id];
							element.remove();

							// If this is the last registered popup, remove event handlers on document
							if (_.isEmpty($rootScope.popups)) {
								angular.element($document).off('click touchstart', ilMiniPopupGlobalEventHandlers.onClickOutside);
								angular.element($document).off('keydown', ilMiniPopupGlobalEventHandlers.onKeyDown);
							}
						});
					}
				};
			}
		]);
})();
