(function() {
	angular
		.module('ui.assist.mode.bar', ['ngStorage', 'api.authentication', 'api.application.user'])
		.controller(controller)
		.directive('ilAssistModeBar', directive);

	controller.$inject = ['$scope', '$localStorage', 'ApplicationUserService', 'AuthenticationService'];

	function controller($scope, $localStorage, ApplicationUserService, AuthenticationService) {
		function cleanUserName(username) {
			var tmp = username.split(' ');
			return tmp && tmp[0] && tmp[0].length > 0 ? tmp[0] : username;
		}

		var model = this;
		model.active = false;

		model.endAssistMode = endAssistMode;

		function initialize() {
			model.actual = ApplicationUserService.getImpersonator();
			model.current = ApplicationUserService.getUser();
			if (model.current) {
				model.roleString =
					model.current.role === 'Administrator'
						? model.current.userRole + ' ' + model.current.role
						: model.current.userRole === 'User'
						? 'Instructor'
						: model.current.userRole;
			}
		}

		function endAssistMode() {
			AuthenticationService.impersonate(undefined);
		}

		$scope.$watch(
			function() {
				return ApplicationUserService.impersonating();
			},
			function(value) {
				model.active = value;
				if (model.active) {
					initialize();
				}
			},
			true
		);
	}

	function directive() {
		return {
			restrict: 'A',
			templateUrl: 'ui/assist_mode_bar/assist.mode.bar.html',
			controller: controller,
			controllerAs: 'model',
			link: function(scope, elem, attrs) {}
		};
	}
})();
