angular
	.module('ui.form.dropdownDefaultSelected', [])

	.directive('ilDropDownDefaultSelected', [
		function() {
			var ilDropDownDefaultSelectedDirective = {
				restrict: 'A',

				require: 'ngModel',

				scope: {
					defaultValue: '@ilDropDownDefaultSelected'
				},

				link: function(scope, elem, attr, ctrl) {
					scope.$watch(
						function() {
							return ctrl.$modelValue;
						},
						function(newValue) {
							if (newValue === scope.defaultValue) {
								elem.addClass('default_selected');
							} else {
								elem.removeClass('default_selected');
							}
						}
					);
				}
			};

			return ilDropDownDefaultSelectedDirective;
		}
	]);
