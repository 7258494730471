(function() {
	angular.module('ui.table_lite_filter.directive', []).directive('tableLiteFilter', tableLiteFilterDirective);

	tableLiteFilterDirective.$inject = ['$compile', '$templateRequest'];
	function tableLiteFilterDirective($compile, $templateRequest) {
		return {
			restrict: 'A',
			controller: 'tableLiteFilterDirectiveController',
			controllerAs: 'ctrl',
			scope: {
				col: '=',
				table: '='
			},
			link: function(scope, element) {
				var ctrl = scope.ctrl;

				function init() {
					ctrl.col = scope.col;
					ctrl.table = scope.table;
				}
				init();

				$templateRequest('ui/table_lite/filters/table_lite_filter_' + scope.col.data.type + '.tpl.html').then(function(html) {
					var template = angular.element(html);
					element.append(template);
					$compile(template)(scope);
				});
			}
		};
	}
})();
