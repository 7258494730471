(function(angular) {
	angular.module('ui.directives.emptyresult', []).directive('emptyResult', emptyResultDirective);

	function emptyResultDirective() {
		return {
			restrict: 'A',
			scope: {
				result: '=emptyResult'
			},
			link: linkFn
		};

		function linkFn(scope, element, attrs) {
			scope.$watch(
				function() {
					return scope.result;
				},
				function(value) {
					if (value && value > 0) {
						element.css('opacity', '1.0');
					} else {
						element.css('opacity', '0.5');
					}
				},
				true
			);
		}
	}
})(window.angular);
