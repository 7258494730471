angular
	.module('ui.tabhandler', [])
	.directive('tabEnabled', [
		function() {
			return {
				restrict: 'A',
				link: function(scope, element, attrs) {
					element.attr('tabindex', 0);
					element.bind('keydown keypress', function(event) {
						if (event.which === 13) {
							element.click();
						}
						//if (event.which === 40) {
						//	element.click();
						//}
					});

					element.on('$destroy', function() {
						element.off('keydown keypress');
					});
				}
			};
		}
	])
	.directive('tabDisabled', [
		function() {
			return {
				restrict: 'A',
				link: function(scope, element, attrs) {
					element.attr('tabindex', -1);
				}
			};
		}
	]);
