//matches element height to that of its parent

angular.module('ui.directives.matchHeight', []).directive('matchHeight', [
	'$timeout',
	'$window',
	function($timeout, $window) {
		return {
			restrict: 'A',
			link: function($scope, element, attrs) {
				var resize = function() {
					var offset = attrs.offset ? attrs.offset : 0;
					var parentHeight = $window.innerHeight - element.parent()[0].getBoundingClientRect().top - offset;
					var elementHeight = $window.innerHeight - element[0].getBoundingClientRect().top - offset;

					if (parentHeight > elementHeight) {
						$(element).animate({ height: parentHeight + 'px' }, 100);
					}

					//element.css('height', parentHeight + 'px');
					//element.css('opacity', 0);
					//$(element).animate({ 'height': parentHeight + 'px' }, 100);
					//$(element).animate({ 'opacity': 1 }, 800);
				};

				angular.element($window).bind('resize', resize);

				element.on('$destroy', function() {
					angular.element($window).off('resize', resize);
				});

				$timeout(function() {
					resize();
				}, 0);
			}
		};
	}
]);
