angular.module('ui.directives.matchDocumentHeight', []).directive('ilMatchDocumentHeight', [
	'$window',
	'$document',
	'$rootScope',
	function($window, $document, $rootScope) {
		return function(scope, elem, attr) {
			var win = angular.element($window);
			var doc = angular.element($document);
			var initialHeight = elem.height();

			// Set up our resize function
			function fixHeight() {
				var documentHeight = doc.height();

				if (attr.subtractElements) {
					var subtractElements = attr.subtractElements.split(',');
					angular.forEach(subtractElements, function(selector) {
						var subtractElement = angular.element(selector);
						if (!!subtractElement) {
							documentHeight -= subtractElement.height();
						}
					});
				}

				if (attr.offsetHeight) {
					var offsetHeight = parseInt(attr.offsetHeight || 0, 10);
					documentHeight -= isNaN(offsetHeight) ? 0 : offsetHeight;
				}

				if (initialHeight < documentHeight) {
					elem.height(documentHeight);
				}
			}

			// Add event handler for window resize
			win.on('resize', fixHeight);
			// Add event handler for window resize on demand
			$rootScope.$on('ilMatchDocumentHeight.Resize', fixHeight);

			// Remove resize handler when destroyed
			elem.on('$destroy', function() {
				win.off('resize', fixHeight);
			});

			// Fix initial height
			fixHeight();
		};
	}
]);
